import { GET_SIGNED_URL } from '../graphql/s3-queries';
import { apolloClient } from './vue-apollo';

export async function getSignedUrl(s3Url) {
    if (!s3Url) return '';
    const results = await apolloClient.query({
        query: GET_SIGNED_URL,
        variables: {
            s3Url: s3Url,
        }
    });
    return results.data.getSignedS3Url;
}