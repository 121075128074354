
import {
  ADD_IMPORTED_SCAN,
  GET_IMPORTED_SCAN_UPLOAD_URL,
  DELETE_SCAN,
  GET_IMPORTED_SCANS_FOR_PROJECT,
  UPDATE_SCAN_HEADER,
} from "../graphql/scan-queries";
import { GET_SIGNED_URL } from "../graphql/s3-queries";
import axios from "axios";
import { LUXCLOUD_PROCESS_IMPORTED_SCAN } from "../graphql/lux-cloud-queries";
import { mapGetters } from "vuex";
import { fileUploadMixin } from "../mixins/file-upload-mixin";

export default {
  name: "admin-imported-scans",
  mixins: [fileUploadMixin],
  data() {
    return {
      search: "",
      headers: [
        { text: "Name", value: "friendlyName", groupable: false },
        { text: "Id", value: "id", sortable: false, groupable: false },
        {
          text: "Metadata",
          value: "metadata",
          sortable: false,
          groupable: false,
        },
        {
          text: "Status",
          value: "artifacts",
          sortable: false,
          groupable: false,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          groupable: false,
        },
        {
          text: "Comments",
          value: "data-table-expand",
          sortable: false,
          groupable: false,
        },
      ],
      loadingQueriesCount: 0,
      uploadDialog: false,
      deleteDialog: false,
      edittingLocked: false,
      editedIndex: -1,
      editedItem: {
        friendlyName: "",
        utm: "",
        comment: "",
        file: null,
        trajectory: true,
      },
      defaultItem: {
        friendlyName: "",
        utm: "",
        comment: "",
        file: null,
        trajectory: true,
      },
      logFile: "",
      isFormValid: false,
      rules: {
        required: (v) => !!v || "Value is required",
      },
    };
  },
  apollo: {
    importedScans: {
      query: GET_IMPORTED_SCANS_FOR_PROJECT,
      loadingKey: "loadingQueriesCount",
      variables() {
        return {
          projectId: Number(this.projectId),
        };
      },
      update(data) {
        data.importedScansForProject.forEach((x) => {
          x.utm = x.metadata ? JSON.parse(x.metadata)?.utm : null;
        });
        return data.importedScansForProject;
      },
    },
  },
  computed: {
    ...mapGetters("viewer", ["projectId"]),
    loading() {
      return this.loadingQueriesCount > 0;
    },
    formTitle() {
      return this.editedIndex === -1 ? "Import Scan" : "Edit Imported Scan";
    },
    isEditting() {
      return this.editedIndex !== -1;
    },
  },
  methods: {
    getStatus(item) {
      if (item.artifacts && item.artifacts.length > 0) return "Completed";
      return "Pending";
    },
    async openInNewWindow(url) {
      const signedUrl = await this.signUrl(url);
      window.open(signedUrl, "_blank");
    },
    async signUrl(s3Url) {
      const results = await this.$apollo.query({
        query: GET_SIGNED_URL,
        variables: {
          s3Url: s3Url,
        },
      });
      return results.data.getSignedS3Url;
    },
    editItem(item) {
      this.editedIndex = this.importedScans.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.uploadDialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.importedScans.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.deleteDialog = true;
    },
    deleteItemConfirm() {
      this.$apollo
        .mutate({
          mutation: DELETE_SCAN,
          variables: {
            id: this.editedItem.id,
          },
        })
        .then(() => {
          this.importedScans.splice(this.editedIndex, 1);
        })
        .catch((error) => {
          console.log(error);
          this.$snackbar.showMessage(error.message, "error");
        })
        .finally(() => {
          this.closeDelete();
        });
    },
    closeUpload() {
      this.edittingLocked = false;
      this.file = {};
      this.uploadingFile = false;
      this.uploadDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.deleteDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      const utm = String(this.editedItem.utm)
      const check = utm.charAt(utm.length - 1)
      if (check !== 'N' && check !== 'S') {
        return null
      }
      if (this.editedIndex > -1) {
        let importedScan = this.editedItem;
        this.$apollo
          .mutate({
            mutation: UPDATE_SCAN_HEADER,
            variables: {
              id: importedScan.id,
              friendlyName: importedScan.friendlyName,
              comment: importedScan.comment,
              tags: importedScan.tags,
              metadata: importedScan.utm ? JSON.stringify({ utm: importedScan.utm }) : "",
            },
          })
          .then((data) => {
            if (this.importedScans[this.editedIndex]?.utm !== importedScan.utm) {
              if (importedScan.artifacts.length === 0)
                this.$snackbar.showMessage(
                  `This scan does net yet have a registered artifact, so cannot be reprocessed reprocessing`,
                  "warning"
                );
              else {
                this.processImportedScan(
                  importedScan.id,
                  importedScan.artifacts[0].s3Url,
                  importedScan.trajectory
                );
              }
            }
            importedScan = { ...importedScan, ...data.data.updateScanHeader };
            Object.assign(this.importedScans[this.editedIndex], importedScan);
          })
          .catch((error) => {
            this.$snackbar.showMessage(error.message, "error");
          })
          .finally(() => {
            this.closeUpload();
          });
      } else {
        this.uploadingFile = true;
        let importedScan = this.editedItem;

        this.$apollo
          .mutate({
            mutation: ADD_IMPORTED_SCAN,
            variables: {
              friendlyName: importedScan.friendlyName,
              comment: importedScan.comment,
              projectId: Number(this.projectId),
              metadata: importedScan.utm ? JSON.stringify({ utm: importedScan.utm }) : "",
              capturedTimestamp: this.file.lastModifiedDate,
            },
          })
          .then((data) => {
            this.edittingLocked = true;
            this.addWindowCloseBlock();
            importedScan = { ...importedScan, ...data.data.createImportedScan };
            this.importedScans.push(importedScan);
            // get the pre-signed upload url
            this.$apollo
              .query({
                query: GET_IMPORTED_SCAN_UPLOAD_URL,
                variables: {
                  importedScanId: importedScan.id,
                  filename: this.file.name,
                },
              })
              .then((res) => {
                const url = res.data.getImportScanUploadSignedURL.uploadUrl;
                const s3Uri = res.data.getImportScanUploadSignedURL.s3Uri;
                const options = {
                  headers: {
                    "Content-Type": this.file.type,
                    "Access-Control-Allow-Origin": "true",
                  },
                  onUploadProgress: function (progressEvent) {
                    this.fileUploadPercentage = Math.round(
                      (progressEvent.loaded / progressEvent.total) * 100
                    );
                  }.bind(this),
                };
                // upload the file
                axios
                  .put(url, this.file, options)
                  .then(() => {
                    // submit base station processing step to batch
                    this.processImportedScan(
                      importedScan.id,
                      s3Uri,
                      importedScan.trajectory
                    );
                  })
                  .catch((error) => {
                    this.$snackbar.showMessage(error.message, "error");
                  })
                  .finally(() => {
                    this.removeWindowCloseBlock();
                    this.closeUpload();
                  });
              });
          })
          .catch((error) => {
            this.$snackbar.showMessage(error.message, "error");
          })
          .finally(() => {
            console.log("done a");
          });
      }
    },
    processImportedScan(id, s3Url, determineTrajectory) {
      this.$apollo
        .query({
          query: LUXCLOUD_PROCESS_IMPORTED_SCAN,
          variables: {
            importedScanId: id,
            s3Url: s3Url,
            determineTrajectory: determineTrajectory,
          },
        })
        .then(() => {
          this.$snackbar.showMessage(
            `Successfully submitted imported scan for pointcloud generation`,
            "green"
          );
        })
        .catch((error) => {
          this.$snackbar.showMessage(error.message, "error");
        });
    },
  },
};
