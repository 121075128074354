import gql from "graphql-tag";

// note - we use sessionId and deviceId so the field resolver is not invoked (for performance reasons)
export const GET_SCAN_SUMMARY_FOR_PROJECT = gql`
query scans ($projectId: Float!) {
	scansSummary: scansForProject(projectId:$projectId) 
    {
    assignmentType
    id
    friendlyName
    sessionId   
    deviceId
    tags
    length
    metadata
    comment
    defaultVersion
    capturedTimestamp
    friendlyLocationName
    simplePath { type, coordinates }
    endCapturedTimestamp
    projectId
    artifacts: mostRecentArtifacts { 
                id
                artifactType 
                name
                url
                s3Url
                status 
        } 
    lastModified
  }
}`;

export const GET_SCAN_ARTIFACT_LAS_DOWNLOADS = gql`
  query GetMostRecentArtifactForDownload(
    $getMostRecentArtifactForDownloadId: String!
  ) {
    getMostRecentArtifactForDownload(id: $getMostRecentArtifactForDownloadId) {
      id
      name
      s3Url
    }
  }
`;

export const GET_SCAN_BY_ID = gql`
query scan ($id: String!) {
    scan (id:$id)
    {
      id
      friendlyName
      session { id }
      device { id }
      tags
      length
      metadata
      comment
      defaultVersion
      capturedTimestamp
      friendlyLocationName
      artifacts: mostRecentArtifacts { 
            id
            artifactType   
            name     
            parameters 
            url
            status 
      } 
      images {
          id
          X
          Y
          Z
          lat
          lon
          centerImage {
            s3Url
            jpegRotation
          }
          leftImage {
            s3Url
            jpegRotation
          }
          rightImage {
            s3Url
            jpegRotation
          }
      }
      lastModified
    }
}`;

export const UPDATE_SCAN_HEADER = gql`
mutation updateScan($id: String!, $comment: String, $defaultVersion: String, $tags: [String!], $metadata: String) {
    updateScanHeader( 
        scan:{
            id: $id,
            comment: $comment,
            defaultVersion: $defaultVersion,
            tags: $tags,
            metadata: $metadata,
        } 
    )
    {
        id
        defaultVersion
        comment
        tags
        lastModified
    }
}
`;

export const GET_IMPORTED_SCAN_UPLOAD_URL = gql`
query getImportScanUploadSignedURL ($importedScanId: String!, $filename: String!) {
    getImportScanUploadSignedURL (
        importedScanId: $importedScanId
        filename:$filename
    ) 
    {
        s3Uri
        uploadUrl
    }
}
`;

export const ADD_IMPORTED_SCAN= gql`
mutation createImportedScan($friendlyName: String!, $comment: String!, $projectId: Float!, $metadata: String!, $capturedTimestamp: DateTime!) {
    createImportedScan(importedScan: {
        friendlyName: $friendlyName,
        comment: $comment,
        projectId: $projectId,
        metadata: $metadata,
        capturedTimestamp: $capturedTimestamp,
    }
  ) {
      id
      friendlyName
      comment
      metadata
      project { id name }
      capturedTimestamp
    }
  }
`;

export const GET_IMPORTED_SCANS_FOR_PROJECT= gql`
query SessionsForProject($projectId: Float!) {
  importedScansForProject(projectId: $projectId) {
    id
    friendlyName
    metadata
    comment
    artifacts: mostRecentArtifacts { 
      s3Url
    } 
  }
}
`;

export const DELETE_SCAN = gql`
mutation deleteScan ($id: String!) {
    deleteScan(id: $id) 
}
`;