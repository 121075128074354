import gql from "graphql-tag";

export const GET_ORGS = gql`
query {
    organizations {
        id 
        name
        devices { id }
    }
}
`;

export const ADD_ORG = gql`
    mutation createOrganization ($name: String!, $deviceIds: [String!]!) {
        createOrganization(organization: 
        {
        name: $name
        deviceIds: $deviceIds
      }
    ) {
        id 
        name
        devices { id }
    }
}
`;

export const UPDATE_ORG = gql`
    mutation updateOrganization ($id: ID!, $name: String!, $deviceIds: [String!]!) {
        updateOrganization(organization: 
        {
        id: $id
        name: $name
        deviceIds: $deviceIds
      }
    ) {
        id 
        name
        devices { id }
    }
}
`;

export const DELETE_ORG = gql`
mutation deleteOrganization ($id: Float!) {
    deleteOrganization(id: $id) 
}
`;