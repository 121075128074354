// this is a recommendation of babeljs (https://babeljs.io/docs/en/babel-polyfill)
// because @babel/polifill is deprecated
import 'core-js/stable'; 
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import IdleVue from 'idle-vue';
import App from './app.vue';
import './services/filters.ts';
import { apolloProvider } from './services/vue-apollo';
import router from './router';
import './styles/style.css';
import VueApollo from 'vue-apollo'
import store from './store/store';
import vuetify from './plugins/vuetify';
import snackbarPlugin from './plugins/snackbar';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'
import VJsoneditor from 'v-jsoneditor/src/index'

Vue.config.productionTip = false
//Vue.config.devtools = true
//Vue.config.performance = process.env.NODE_ENV !== 'production'

// Install the vue plugins
const eventsHub = new Vue();

// add-ins
Vue.use(IdleVue,
  {
    eventEmitter: eventsHub,
    idleTime: 1000 * 60 * 15, // 15 minutes in milliseconds
    store: store,
    startAtIdle: false,
  });
Vue.use(VueApollo);
Vue.use(VJsoneditor); // https://www.npmjs.com/package/v-jsoneditor
Vue.use(snackbarPlugin, { store });

// components
Vue.component('datetime-selector', Datetime);

// not using the interceptor at this time
/* Vue.use(VueResource);

Vue.http.interceptors.push({

    request: function (request){
        console.log('a');
      console.log(request);
      return request;
    },
  
    response: function (response) {
        console.log('b');
        console.log(response);
      return response;
    }
  
  }) */

// TODO: can this be cleaned up? This just forces a confirmation before closing/reloading the page
//window.addEventListener('beforeunload', (event) => {
//    event.returnValue = `Are you sure you want to leave?`;
//  });

new Vue({
  router,
  vuetify,
  store: store,
  apolloProvider: apolloProvider,
  render: (h) => h(App),
}).$mount('#app')


