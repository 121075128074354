import { ScanSummary } from "./../models/models";
import {
  GET_SCAN_SUMMARY_FOR_PROJECT,
  GET_SCAN_BY_ID,
} from "../graphql/scan-queries";
import { apolloClient } from "../services/vue-apollo";
import { GET_PROJECT } from "@/graphql/project-queries";
import { GET_PROJECT_LAYERS_FOR_PROJECT } from "@/graphql/project-layer-queries";
import moment from "moment";
import { bootstrap } from "../utils/vuex-bootstrap";
import {
  LUXCLOUD_PDAL,
  LUXCLOUD_PUBLISH_SCANS,
} from "@/graphql/lux-cloud-queries";
import { GET_ANNOTATIONS, UPDATE_ANNOTATION, DELETE_ANNOTATION } from "@/graphql/annotation-queries";

const viewerStoreModule = bootstrap({
  namespaced: true,
  state: {
    projectId: 0,
    project: null,

    // the summary lists used by screens
    summaryScanList: [] as ScanSummary[],
    projectLayerList: [],

    // tracks the collection of fully loaded scans
    loadedScanList: [],

    // state tracking lists
    selectedProjectLayerIdList: [],
    visibleScanIdList: [],
    filteredScanIdList: [],
    selectedScanIdList: [],
    selectedAnnotationsList: [],

    // focus related
    focusedScanId: null,
    focusedPoint: null,
    focusedImageSet: null,
    focusedVersion: null,

    // state related (for image-view)
    imageViewVisible: false,

    // event related (for point-cloud-view)
    loadedScan: null,
    loadedManyScans: null,
    //autofocus
    autoFocus: true,
    // filter related
    filterSelected: false,

    //annotation related
    focusToAnnotation: false,
    focusedAnnotation: null,
    filterAnnotations: false,
    annotations: [],

    boundingBoxes: {},
    removeClip: null, 
  },
  mutations: {
    setProjectId(state, projectId) {
      state.projectId = parseInt(projectId);
    },
    setAnnotations(state, annotations) {
      state.annotations = annotations.sort((b, a) => a.id.localeCompare(b.id));
    },
    setBoundingBoxes(state, boundingBoxes) {
      state.boundingBoxes = boundingBoxes
    },
    setRemoveClip(state, removeClip) {
      state.removeClip = removeClip
    },
    setImageViewVisible(state, value) {
      state.imageViewVisible = value;
    },

    setSummaryScanList(state, summaryScanList) {
      state.summaryScanList = summaryScanList;
    },

    setProject(state, project) {
      state.project = project;
    },

    setProjectLayers(state, projectLayerList) {
      state.projectLayerList = projectLayerList;
    },

    addLoadedScan(state, scan) {
      state.loadedScan = scan.id;
      state.loadedScanList.push(scan);
    },
    mergeLoadedScan(state, scanList) {
      state.loadedScanList = state.loadedScanList.concat(scanList);
    },

    setFilteredScanIdList(state, filteredScanIdList) {
      state.filteredScanIdList = filteredScanIdList;
    },

    setFilterSelected(state, value) {
      state.filterSelected = value;
    },
    setFocusToAnnotation(state, value) {
      state.focusToAnnotation = value;
    },
    setFocusedAnnotation(state, value) {
      state.focusedAnnotation = value;
    },
    setFilterAnnotations(state, value) {
      state.filterAnnotations = value;
    },
    setAutoFocus(state, value) {
      state.autoFocus = value;
    },
    addSelectedScanIdList(state, scanId) {
      if (!state.selectedScanIdList.some((x) => x === scanId)) {
        state.selectedScanIdList.push(scanId);
      }
    },
    removeSelectedScanIdList(state, scanId) {
      if (state.selectedScanIdList.some((x) => x === scanId)) {
        state.selectedScanIdList = state.selectedScanIdList.filter(
          (x) => x !== scanId
        );
      }
    },
    addSelectedAnnotationsList(state, annotation) {
      if (!state.selectedAnnotationsList.some((x) => x === annotation)) {
        state.selectedAnnotationsList.push(annotation);
      }
    },
    removeSelectedAnnotationsList(state, annotation) {
      if (state.selectedAnnotationsList.some((x) => x === annotation)) {
        state.selectedAnnotationsList = state.selectedAnnotationsList.filter(
          (x) => x !== annotation
        );
      }
    },
    addManySelectedScanIdList(state, scanIdList) {
      state.selectedScanIdList = scanIdList;
    },

    addProjectLayerIdList(state, projectLayerId) {
      if (!state.selectedProjectLayerIdList.some((x) => x === projectLayerId)) {
        state.selectedProjectLayerIdList.push(projectLayerId);
      }
    },

    removeProjectLayerIdList(state, projectLayerId) {
      if (state.selectedProjectLayerIdList.some((x) => x === projectLayerId)) {
        state.selectedProjectLayerIdList = state.selectedProjectLayerIdList.filter(
          (x) => x !== projectLayerId
        );
      }
    },

    addVisibleScanIdList(state, scanId) {
      if (!state.visibleScanIdList.some((x) => x === scanId)) {
        state.visibleScanIdList.push(scanId);
      }
    },

    removeVisibleScanIdList(state, scanId) {
      if (state.visibleScanIdList.some((x) => x === scanId)) {
        state.visibleScanIdList = state.visibleScanIdList.filter(
          (x) => x !== scanId
        );
      }
    },
    setFocusedVersion(state, value) {
      state.focusedVersion = value;
    },
    setFocusedScanId(state, value) {
      state.focusedScanId = value;
    },
    setFocusedPoint(state, value) {
      state.focusedPoint = value;
    },
    setFocusedImageSet(state, value) {
      state.focusedImageSet = value;
    },

    resetFocus(state) {
      state.focusedImageSet = null;
      state.focusedPoint = null;
      state.focusedScanId = null;
      state.imageViewVisible = false;
    },

    resetsSelectedScanIdList(state) {
      state.selectedScanIdList = [];
    },
    resetSelectedAnnotationsList(state) {
      state.selectedAnnotationsList = [];
    },
    resetLoadedScanList(state) {
      state.loadedScanList = [];
    },
    resetSelectedProjectLayerIdList(state) {
      state.selectedprojectLayerIdList = [];
    },
    resetVisibleScanIdList(state) {
      state.visibleScanIdList = [];
    },
    resetFilteredScanIdList(state) {
      state.filteredScanIdList = [];
    },
  },
  actions: {
    // TODO: consider moving functions from the store as they do not change state
    publishSelected(
      { commit, state },
      publishScans: {
        bucketId: number;
        targetFolder: string;
        includeImages: boolean;
        transform: boolean;
      }
    ) {
      let scans;
      if (state.focusedScanId) {
        scans = {
          scan: state.focusedScanId,
          session: state.summaryScanList.find(
            (x) => x.id === state.focusedScanId
          )?.sessionId,
        };
      } else {
        scans = state.selectedScanIdList.map((x) => {
          return {
            scan: x,
            session: state.summaryScanList.find((y) => y.id == x).sessionId,
          };
        });
      }

      const variables = {
        publishScan: {
          projectId: state.projectId,
          bucketId: publishScans.bucketId,
          targetFolder: publishScans.targetFolder,
          includeImages: publishScans.includeImages,
          transform: publishScans.transform,
          scans: scans,
        },
      };
      apolloClient
        .mutate({
          mutation: LUXCLOUD_PUBLISH_SCANS,
          variables: variables,
        })
        .then(() => {
          // TODO: confirm this works, otherwise use code below
          commit(
            "showMessage",
            {
              content: `Successfully submitted selected scans for publishing`,
              color: "green",
            },
            { root: true }
          );
        })
        .catch((error) => {
          commit(
            "showMessage",
            { content: error.message, color: "error" },
            { root: true }
          );
        });
    },
    processPDALSelected(
      { commit, state },
      pdalSubmission: { versionName: string; pipeline; version }
    ) {
      let artifactIds = [];
      if (state.focusedScanId) {
        const scan = state.summaryScanList.find(
          (x) => x.id === state.focusedScanId
        );
        artifactIds.push(
          scan.artifacts.find(
            (x) =>
              x.name === pdalSubmission.version &&
              x.artifactType === "pointcloud"
          )?.id
        );
      } else {
        for (const scanId of state.selectedScanIdList) {
          const scan = state.summaryScanList.find((x) => x.id === scanId);
          artifactIds.push(
            scan.artifacts.find(
              (x) =>
                x.name === "unclassified" && x.artifactType === "pointcloud"
            )?.id
          );
        }
      }
      // change them all to ints
      artifactIds = artifactIds.map((x) => parseInt(x));
      const pipelinejson = pdalSubmission.pipeline;
      const variables = {
        submission: {
          artifactIds: artifactIds,
          name: pdalSubmission.versionName,
          pipeline: JSON.stringify(pipelinejson),
        },
      };
      apolloClient
        .mutate({
          mutation: LUXCLOUD_PDAL,
          variables: variables,
        })
        .then(() => {
          commit(
            "showMessage",
            {
              content: `Successfully submitted selected scans for processing`,
              color: "green",
            },
            { root: true }
          );
        })
        .catch((error) => {
          commit(
            "showMessage",
            { content: error.message, color: "error" },
            { root: true }
          );
        });
    },
    setFilteredScanIdList({ commit }, filteredScanIdList) {
      commit("setFilteredScanIdList", filteredScanIdList);
    },
    setFilterSelected({ commit }, value) {
      commit("setFilterSelected", value);
    },
    setFocusToAnnotation({ commit }, value) {
      commit("setFocusToAnnotation", value);
    },
    setFocusedAnnotation({ commit }, value) {
      commit("setFocusedAnnotation", value);
    },
    setFilterAnnotations({ commit }, value) {
      commit("setFilterAnnotations", value);
    },
    setAutoFocus({ commit }, value) {
      commit("setAutoFocus", value);
    },
    // scan selection actions
    selectScan({ commit, dispatch }, scanId) {
      commit("setRemoveClip", scanId);
      commit("addSelectedScanIdList", scanId);
      commit("addVisibleScanIdList", scanId);
      dispatch("loadScan", scanId);
    },
    unselectScan({ commit, state }, scanId) {
      commit("setRemoveClip", null);
      commit("removeVisibleScanIdList", scanId);
      commit("removeSelectedScanIdList", scanId);
      if (state.focusedScanId === scanId) commit("resetFocus");
    },
    selectAnnotation({ commit }, annotation) {
      commit("addSelectedAnnotationsList", annotation);
    },
    unselectAnnotation({ commit }, annotation) {
      commit("removeSelectedAnnotationsList", annotation);
    },
    selectManyScans({ commit, dispatch, state }, selectedScanIdList) {
      commit("addManySelectedScanIdList", selectedScanIdList);
      selectedScanIdList.forEach((scanId) => {
        if (!state.visibleScanIdList.includes(scanId)) {
          commit("addVisibleScanIdList", scanId);
        }
      });
      dispatch("loadManyScans", selectedScanIdList);
    },
    unselectAllScans({ commit }) {
      commit("resetsSelectedScanIdList");
      commit("resetFocus");
    },

    // scan visibility actions (requires selected)
    showScan({ commit }, scanId) {
      commit("addVisibleScanIdList", scanId);
    },
    hideScan({ commit, state }, scanId) {
      commit("removeVisibleScanIdList", scanId);
      if (state.focusedScanId === scanId) commit("resetFocus");
    },

    // related to showing/hiding the image viewer
    showImageView({ commit }) {
      commit("setImageViewVisible", true);
    },
    hideImageView({ commit }) {
      commit("setImageViewVisible", false);
    },
    makeDefaultVersion({ commit }, selectedVersion) {
      commit("setDefaultVersion", selectedVersion);
    },
    // scan focus actions (requires selected and visible)
    focusScanId({ commit, state }, scanId) {
      commit("setRemoveClip", null);
      commit("setRemoveClip", scanId);
      commit("setFocusedScanId", scanId);
      const scan = state.loadedScanList.find((x) => x.id === scanId);
      if (scan && scan.images.length > 0) {
        commit("setFocusedImageSet", scan.images[0]);
      } else {
        commit("setFocusedImageSet", null);
      }
    },
    focusVersion({ commit }, selectedVersion) {
      commit("setFocusedVersion", selectedVersion);
    },
    focusPoint({ commit }, point) {
      commit("setFocusedPoint", point);
    },
    focusImageSet({ commit }, image) {
      commit("setFocusedImageSet", image);
    },
    setBoundingBoxes({ commit }, boundingBoxes) {
      commit("setBoundingBoxes", boundingBoxes);
    },
    setAnnotations({ commit }) {
      apolloClient
        .query({
          query: GET_ANNOTATIONS,
        })
        .then((data) => {
          const annotations = data.data.annotations;
          commit("setAnnotations", annotations);
        });
    },
    // related to setting a project
    setProjectId({ commit, dispatch, state }, projectId) {
      commit("setProjectId", projectId);

      commit("startLoading", null, { root: true });
      apolloClient
        .query({
          query: GET_SCAN_SUMMARY_FOR_PROJECT,
          variables: { projectId: state.projectId },
        })
        .then((res) => {
          const scansSummary = res.data.scansSummary;
          commit("setSummaryScanList", scansSummary);
          dispatch("resetState");
        })
        .finally(() => {
          commit("stopLoading", null, { root: true });
        });

      if (state.projectId !== 0) {
        // projectId of 0 represents unassigned scans, so no project by definition
        commit("startLoading", null, { root: true });
        //console.log('calling GET_PROJECT')
        apolloClient
          .query({
            query: GET_PROJECT,
            variables: { projectId: state.projectId },
          })
          .then((res) => {
            const project = res.data.project;
            commit("setProject", project);
          })
          .finally(() => {
            commit("stopLoading", null, { root: true });
          });

        commit("startLoading", null, { root: true });
        apolloClient
          .query({
            query: GET_PROJECT_LAYERS_FOR_PROJECT,
            variables: { projectId: state.projectId },
          })
          .then((res) => {
            commit(
              "setProjectLayers",
              res.data.projectLayers
                .filter((x) => !x.ignore)
                .sort((a, b) => a.name.localeCompare(b.name))
            );
            // preselect layers which are showByDefaul
            for (const layer of res.data.projectLayers) {
              if (layer.showByDefault)
                commit("addProjectLayerIdList", layer.id);
            }
          })
          .finally(() => {
            commit("stopLoading", null, { root: true });
          });
      }
    },
    resetSelectedAnnotationsList({ commit }) {
      commit("resetSelectedAnnotationsList");
    },
    resetState({ commit }) {
      //console.log("resetting state");
      commit("resetFocus");
      commit("resetSelectedProjectLayerIdList");
      commit("resetVisibleScanIdList");
      commit("resetLoadedScanList");
      commit("resetFilteredScanIdList");
      commit("resetsSelectedScanIdList");
      commit("resetSelectedAnnotationsList");
    },

    // related to loading scan details
    loadScan({ commit, dispatch, state }, scanId) {
      const toLoad = state.selectedScanIdList.filter(
        (selected) =>
          !state.loadedScanList.find((loaded) => loaded.id === selected)
      );
      if (toLoad.length > 0) {
        commit("startLoading", null, { root: true });
        apolloClient
          .query({
            query: GET_SCAN_BY_ID,
            variables: { id: scanId },
          })
          .then((res) => {
            const scan = res.data.scan;
            commit("addLoadedScan", scan);
            // only focus the scan if it is the first one selected
            if (!state.focusedScanId) {
              if (state.autoFocus) {
                dispatch("focusScanId", scan.id);
              }
            }
          })
          .finally(() => {
            commit("stopLoading", null, { root: true });
          });
      }
    },
    async loadManyScans({ commit, state }, selectedScanIdList) {
      commit("startLoading", null, { root: true });
      const toLoad = selectedScanIdList.filter(
        (selected) =>
          !state.loadedScanList.find((loaded) => loaded.id === selected)
      );
      const promises = [];
      for (const scanId of toLoad) {
        promises.push(
          apolloClient
            .query({
              query: GET_SCAN_BY_ID,
              variables: { id: scanId },
            })
            .then((res) => res.data.scan)
        );
      }
      const loadedScans = await Promise.all(promises);
      commit("mergeLoadedScan", loadedScans);
      commit("stopLoading", null, { root: true });
    },

    // related to project layers
    selectProjectLayer({ commit }, projectLayerId) {
      commit("addProjectLayerIdList", projectLayerId);
    },
    unselectProjectLayer({ commit }, projectLayerId) {
      commit("removeProjectLayerIdList", projectLayerId);
    },
  },
  getters: {
    // access getters
    annotations: (state) => state.annotations,
    boundingBoxes: (state) => state.boundingBoxes,
    removeClip: (state) => state.removeClip,
    projectId: (state) => state.projectId,
    autoFocus: (state) => state.autoFocus,
    filterSelected: (state) => state.filterSelected,
    focusToAnnotation: (state) => state.focusToAnnotation,
    focusedAnnotation: (state) => state.focusedAnnotation,
    filterAnnotations: (state) => state.filterAnnotations,
    project: (state) => state.project,
    summaryScanList: (state) => state.summaryScanList,
    projectLayerList: (state) => state.projectLayerList,
    selectedProjectLayerIdList: (state) => state.selectedProjectLayerIdList,
    selectedScanIdList: (state) => state.selectedScanIdList,
    selectedAnnotationsList: (state) => state.selectedAnnotationsList,
    loadedScanList: (state) => state.loadedScanList,
    filteredScanIdList: (state) => state.filteredScanIdList,
    visibleScanIdList: (state) => state.visibleScanIdList,
    focusedVersion: (state) => state.focusedVersion,
    focusedScanId: (state) => state.focusedScanId,
    focusedImageSet: (state) => state.focusedImageSet,
    focusedPoint: (state) => state.focusedPoint,
    loadedScan: (state) => state.loadedScan,
    imageViewVisible: (state) => state.imageViewVisible,

    defaultScanTags: () => {
      return ["QA - OK", "QA - Issue/Poor"];
    },

    defaultPipelineTags: () => {
      return ["3D Bounding Box"];
    },
    // computed getters
    filteredAnnotationsList: (state) => {
      let annotationsList = [];
      for (const scan of state.summaryScanList) {
        for (const annotation of state.annotations) {
          if (annotation.scan.id === scan.id) {
            annotationsList.push(scan.id);
          }
        }
      }
      annotationsList = [...new Set(annotationsList)];
      return annotationsList;
    },
    annotationsByProjectList: (state) => {
      let annotationsList = [];
      for (const scan of state.summaryScanList) {
        for (const annotation of state.annotations) {
          if (annotation.scan.id === scan.id) {
            annotationsList.push(annotation);
          }
        }
      }
      annotationsList = [...new Set(annotationsList)];
      return annotationsList;
    },
    someScanSelected: (state) => state.selectedScanIdList.length > 0,
    totalScans: (state) => state.summaryScanList.length,
    filteredSummaryScanList: (state) => {
      if (state.filteredScanIdList.length === 0) return state.summaryScanList;
      return state.summaryScanList.filter((scan) =>
        state.filteredScanIdList.find((filter) => filter === scan.id)
      );
    },
    focusedScan: (state) =>
      state.loadedScanList.find((x) => x.id === state.focusedScanId),
    isFocused: (state) => state.focusedScanId !== null,

    // method getters
    availableDevices(state): string[] {
      const unique = [
        ...new Set<string>(
          state.summaryScanList
            .filter((x) => x.deviceId !== null)
            .map((x) => x.deviceId)
        ),
      ];
      return ["(ALL)"].concat(unique);
    },
    availableTypes(state): string[] {
      const unique = [
        ...new Set<string>(
          state.summaryScanList
            .filter((x) => x.assignmentType !== null)
            .map((x) => x.assignmentType)
        ),
      ];
      return ["(ALL)"].concat(unique);
    },
    availableTags(state): string[] {
      const unique = [
        ...new Set<string>(state.summaryScanList.map((x) => x.tags).flat()),
      ];
      return ["(Empty)"].concat(unique);
    },
    isFiltered(state) {
      if (
        state.filteredScanIdList.length === 0 ||
        state.filteredScanIdList.length === state.summaryScanList.length
      )
        return false;
      return true;
    },
    availableDates(state): string[] {
      if (state.summaryScanList === null || state.summaryScanList.length === 0)
        return [];

      //const maxdate = new Date(state.summaryScanList.filter((x) => x.capturedTimestamp !== null).map(x => x.capturedTimestamp).reduce((a,b) => (a > b) ? a : b))
      //maxdate.setDate(maxdate.getDate() + 1);

      const results = state.summaryScanList
        .filter((x) => x.capturedTimestamp !== null)
        .map((x) => Date.parse(x.capturedTimestamp));

      //results.push(maxdate)

      return results
        .sort((a, b) => a - b)
        .map((x: Date) => moment(x).format("YYYY-MM-DD"))
        .filter(function(elem, index, self) {
          return index === self.indexOf(elem);
        });
    },
    isScanSelected: (state) => (scanId) => {
      return state.selectedScanIdList.some((x: string) => x === scanId);
    },
    isAnnotationSelected: (state) => (annotation) => {
      return state.selectedAnnotationsList.some((x) => x.id === annotation);
    },
    isScanLoaded: (state) => (scanId) => {
      return state.loadedScanList.some((x) => x.id === scanId);
    },
    isScanVisible: (state) => (scanId) => {
      return state.visibleScanIdList.some((x: string) => x === scanId);
    },
    isProjectLayerSelected: (state) => (projectLayerId) => {
      return state.selectedProjectLayerIdList.some(
        (x: string) => x === projectLayerId
      );
    },
    isProjectLayer: (state) => (projectLayerId) => {
      return state.projectLayerList.some((x) => x.id === projectLayerId);
    },
  },
});

export default viewerStoreModule;
