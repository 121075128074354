
import { GET_PIPELINE_BY_PROJECT, ADD_PIPELINE, DELETE_PIPELINE, UPDATE_PIPELINE } from '../graphql/pipeline-queries';
import { mapGetters } from 'vuex';


export default {
    name: 'admin-pdal-pipelines',
    data() { 
        return {
            newPipeline: {}, 
            newName: null, 
            newTags: null,
            newDescription: null,
            newGlobal: false, 
            editedPipeline: {}, 
            editedName: null, 
            editedTags: null,
            editedDescription: null,
            editedId: null,
            editedGlobal: false, 
            search: '',
            volumeTools: null,
            headers: [
                { text: 'Name', value: 'name', groupable: false },
                { text: 'Description', value: 'description', groupable: false },
                { text: 'Dependencies', value: 'dependencies[0]', groupable: false },
                { text: 'Last Modified', value: 'lastModified', sortable: false, groupable: false, },
                { text: 'Actions', value: 'actions', sortable: false, groupable: false,  },
            ],
            rules: {
                required: v => !!v || 'Value is required',
            },
            options: {
                mode: "code",
            },
            newDialog: false,
            dialog: false,
            global: false, 
        }
    },
    apollo: {
        pipelines: {
            query: GET_PIPELINE_BY_PROJECT,
            loadingKey: "loadingQueriesCount",
            variables() {
                return {
                    projectId: Number(this.projectId),
                };
            },
        },
    },
    computed: {
        ...mapGetters(['user']),
        ...mapGetters("viewer", ["projectId", "defaultPipelineTags", "project"]),
        tagOptions() {
        // project could be null if unassigned scans is selected
            return this.project ? this.defaultPipelineTags.concat(this.project.tagOptions) : this.defaultPipelineTags;
        },
        hasBoundingBox() {
            if (this.newTags === null || this.newTags?.length === 0) {
                return false
            } else {
                return true
            }
        },
        hasBoundingBoxEdit() {
            if (this.editedTags.length === 0) {
                return false
            } else {
                return true
            }
        },
        globalPermission() {
            if (this.user.organization.name === "Lux Modus") {
                return true
            } else {
                return false
            }
        },
    },
    methods: {
        closeDelete() {
            this.dialog = false
        },
        close() {
            this.newDialog = false
            this.newName = null
            this.newDescription = null
            this.newPipeline = {}
            this.newTags = null
            this.newGlobal = false
        },
        openEdit(item) {
            this.dialog = true
            this.editedId = item.id
            this.editedPipeline = JSON.parse(item.pipeline)
            this.editedName = item.name
            this.editedTags = item.dependencies
            this.editedDescription = item.description
            this.editedGlobal = item.global
        },
        createPipeline() {
            let projectID = null
            if (this.newName === null || this.newDescription === null || this.newPipeline === null) {
                return null;
            }
            if (this.newGlobal === true) {
                projectID = null
            } else {
                projectID = String(this.projectId)
            }
            this.$apollo.mutate({
                mutation: ADD_PIPELINE,
                variables: {
                    projectId: projectID,
                    name: this.newName,
                    description: this.newDescription,
                    pipeline: JSON.stringify(this.newPipeline),
                    dependencies: this.newTags,
                    global: this.newGlobal
                }
            }).then((data) => { 
                const pipeline = { ...data.data.createPipeline };
                this.pipelines.unshift(pipeline);
                this.close()
            })
        },
        deletePipeline(pipeline) {
            this.$apollo
                .mutate({
                    mutation: DELETE_PIPELINE,
                    variables: {
                        id: Number(pipeline.id),
                    },
                }).then(() => {
                    const index = this.pipelines.indexOf(pipeline);
                    this.pipelines.splice(index, 1);
                })
        },
        editPipeline() {
            this.$apollo
                .mutate({
                    mutation: UPDATE_PIPELINE,
                    variables: {
                        id: this.editedId,
                        projectId: String(this.projectId),
                        name: this.editedName,
                        description: this.editedDescription,
                        pipeline: JSON.stringify(this.editedPipeline),
                        dependencies: this.editedTags,
                        global: this.editedGlobal
                    },
                    refetchQueries: [{ query: GET_PIPELINE_BY_PROJECT, variables: { projectId: Number(this.projectId) } }]
                }).then(() => {
                    this.closeDelete()
                })
        },
    },
}
