import gql from "graphql-tag";

export const GET_BASESTATIONS_FOR_PROJECT = gql`
query BasestationsForProject($projectId: Float!) {
  basestations: basestationsForProject(projectId: $projectId) {
    id
    s3Url
    point {
      coordinates
    }
    trajectoryProcessingRuns {
      sessionId
      basestationIds
      status
      logS3Url
      ambStatusS3Url
      altitudeSeparationsS3Url
      distanceSeparationsS3Url
      numSatsS3Url
      trajectoryAuditS3Url
      submitted
      started 
      completed
    }
    startTimestamp
    endTimestamp
  }
}
`;

export const GET_BASESTATION_UPLOAD_URL = gql`
query preSignedURL ($filename: String!) {
  getBasestationUploadSignedURL(filename:$filename)
    {
        s3Uri
        uploadUrl
    }
}
`;

export const DELETE_BASESTATION = gql`
mutation deleteBasestation ($id: Float!) {
    deleteBasestation(id: $id) 
}
`;

export const GET_MATCHED_SESSIONS_FOR_BASESTATION = gql`
query TimeMatchedSessionsForBasestation($basestationId: Float!) {
  trajectories: timeMatchedSessionsForBasestation(basestationId: $basestationId) {
    sessionId
  }
}
`;

export const GET_MATCHED_BASESTATIONS_FOR_SESSION = gql`
query matchedBasestationsForSessionIdAudit($sessionId: String!) {
  matchedBasestations: matchedBasestationsForSessionId(sessionId: $sessionId) {
    id
  }
}
`;

export const GET_POTENTIAL_BASESTATIONS_FOR_SESSION = gql`
query PotentialBasestationsForSessionIdAudit($sessionId: String!) {
  potentialBasestations: potentialBasestationsForSessionIdAudit(sessionId: $sessionId) {
    session {
      sessionId
      capturedTimestamp
      endCapturedTimestamp
      scanCount
      startPoint {
        coordinates
      }
      endPoint {
        coordinates
      }
    }
    basestation {
      id
      startTimestamp
      endTimestamp
      point {
        coordinates
      }
    }
    distance
  }
}
`;