
import axios from 'axios';
import { GET_ACTIVITY_LOGS } from '../graphql/activity-log-queries';
import { GET_USERS } from '../graphql/org-user-queries';
import { ActivityLogger } from '../services/activity-logger';
import { getSignedUrl } from '../services/utilities';
import { generalUtilMixin } from '../mixins/general-util-mixin';

export default {
    name: 'activityLogList',
    mixins: [ generalUtilMixin ],
    props: {
        activityTypeFilter: String,
        statusFilter: String,
        userIdFilter: {
            type: Number,
            default: 0,
        },
        isAcknowledgedFilter: String,
        columnVisibility: {
            type: Array,
            default: function() {
                return ['id', 'activityType', 'user.name', 'status', 'message', 'logFileUrl', 'submitted', 'started', 'completed', 'actions', 'isAcknowledged', 'data-table-expand'];
            },
        },
        additionalColumns: null,
        itemActions: null,
        hideOnNoValues: {
            type: Boolean,
            default: false,
        },
        noValuesAlertType: {
            type: String,
            default: 'info',
        },
        noValuesAlertMessage: {
            type: String,
            default: 'No items were found',
        },
        allowSort: {
            type: Boolean,
            default: false,
        },
        autoRefresh: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            defaultColumns: [
                { text: 'Id', value: 'id', groupable: false, },
                { text: 'Activity Type', value: 'activityType', groupable: false, sortable: true },
                { text: 'User', value: 'user.name', groupable: false, sortable: true },
                { text: 'Status', value: 'status', groupable: false, sortable: true },
                { text: 'Message', value: 'message', groupable: false, sortable: false },
                { text: 'Log', value: 'logFileUrl', sortable: false, groupable: false, },
                { text: 'Submitted', value: 'submitted', groupable: false, sortable: true },
                { text: 'Started', value: 'started', groupable: false, sortable: true },
                { text: 'Completed', value: 'completed', groupable: false, sortable: true },
                { text: 'Acknowledged', value: 'isAcknowledged', groupable: false, sortable: false },
                { text: 'Actions', value: 'actions', groupable: false, sortable: false },
                { text: '', value: 'data-table-expand', groupable: false, sortable: false },
            ],
            expanded: [],
            singleExpand: false,
            loadingQueriesCount: 0 as number,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: [],
                sortDesc: [],
            },
            activityLogItems: [],
            totalActivityLogs: 0,
            dialogLogFile: false,
            dialogLogFileTitle: '',
            logFile: '',
        };
    },
    apollo: {
        orgUsers: {
            query: GET_USERS,
            loadingKey: 'loadingQueriesCount',
        },
        activityLog: {
            query: GET_ACTIVITY_LOGS,
            loadingKey: 'loadingQueriesCount',
            variables() {
                return {
                    pageIndex: this.options.page -1, // change to 0-indexed
                    pageSize: this.options.itemsPerPage,
                    sort: this.sort,
                    activityTypeFilter: this.activityTypeFilter || '',
                    statusFilter: this.statusFilter || '',
                    userIdFilter: Number(this.userIdFilter || 0),
                    isAcknowledgedFilter: this.isAcknowledgedFilter || '',
                };
            },
            result ({ data /*, loading, networkStatus */}) {
                if (data && data.activityLog) {
                    this.activityLogItems = data.activityLog.items;
                    this.totalActivityLogs = data.activityLog.total;
                }
            },
        },
    },
    computed: {
        hasExpandColumn() {
            return this.columnVisibility.includes('data-table-expand');
        },
        headers() {
            const scopedAllowSort = this.allowSort;
            // putting additionalColumns before defaultColumns so that "find()" allows overrides
            const allColumns = (this.additionalColumns || []).concat(this.defaultColumns);

            const columnsToShow = (this.itemActions && this.itemActions.length > 0)
                ? this.columnVisibility
                : this.columnVisibility.filter(c => c !== 'actions');
            return columnsToShow.map(function(colValue) {
                const colDef = allColumns.find(c => c.value === colValue);
                return {
                    ...colDef,
                    sortable: colDef.sortable && scopedAllowSort,
                };
            });
        },
        sort() {
            const results = [];
            for (const [i, sort] of this.options.sortBy.entries()) {
                results.push(`${(sort && this.options.sortDesc[i]) ? '-' : ''}${sort}`);
            }
            return results.join(' ');
        },
        loading() {
            return (this.loadingQueriesCount > 0)
        },
    },
    methods: {
        jsonifyAndMap(payload) {
            const parsedPayload = JSON.parse(payload);
            return Object.keys(parsedPayload).map((key) => { return { key, value: parsedPayload[key] }; });
        },
        acknowledge(activityLog, isAcknowledged) {
            const logger = new ActivityLogger();
            logger.acknowledgeActivityLog(Number(activityLog.id), isAcknowledged);
        },
        acknowledgeMultiple() {
            const logger = new ActivityLogger();
            logger.acknowledgeMultipleActivityLogs(this.activityTypeFilter, this.statusFilter, this.userIdFilter, true);
        },
        async showLogFile(logFileUrl, title = '') {
            const signedUrl = await getSignedUrl(logFileUrl)
            if(signedUrl) {
                this.logFile = (await axios.get(signedUrl)).data;
                this.dialogLogFileTitle = title;
                this.dialogLogFile = true;
            }
        },
    },
    watch: {
      autoRefresh() {
        if (this.autoRefresh) {
          this.$apollo.queries.activityLog.startPolling(10000);
        } else {
          this.$apollo.queries.activityLog.stopPolling();
          this.$apollo.queries.activityLog.refresh(); // one final refresh
        }
      },
    },
};
