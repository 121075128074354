import Vuex from 'vuex';
import Vue from 'vue';
import { apolloClient } from '../services/vue-apollo';
import { GET_USER_CONTEXT } from '../graphql/org-user-queries';
import fileUploadStoreModule from './file-upload-store';
import viewerStoreModule from './viewer-store';
import { ActivityLogger } from '../services/activity-logger';
import router from '../router/index'
import { MAPSTYLES } from '@/contants';

const mutations = {
    SET_LOGGED_IN: 'SET_LOGGED_IN',
    SET_LOGGED_OUT: 'SEE_LOGGED_OUT'
}

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        fileUpload: fileUploadStoreModule,
        viewer: viewerStoreModule,
    },
    state: {
        loading: 0,
        loggedIn: false,
        username: null,
        awsUserId: null,
        user: null,
        selectedMapStyle: "satellite streets", // TODO: move to viewer? 
        snackbar: {
            content: '',
            color: '',
            timeout: '',
        },
    },
    mutations: {
        showMessage(state, payload) {
            state.snackbar.content = payload.content
            state.snackbar.color = payload.color
            state.snackbar.timeout = ('timeout' in payload) ?  payload.timeout : 8000
        },
        [mutations.SET_LOGGED_IN](state) {
            state.loggedIn = true;
        },
        [mutations.SET_LOGGED_OUT](state) {
            state.loggedIn = false;
        },
        startLoading(state) {
            state.loading += 1;
        },
        stopLoading(state) {
            state.loading -= 1;
        },
        setUsername(state, username) {
            state.username = username;
        },
        setUser(state, user) {
            state.user = user;
        },
        setAwsUserId(state, awsUserId) {
            state.awsUserId = awsUserId;
        },
        setMapStyle(state, mapStyle) {
            state.selectedMapStyle = mapStyle;
        },
    },
    actions: {
        setMapStyle({ commit }, style) {
            commit('setMapStyle', style );
        },
        setLoggedIn({ commit, dispatch }, cognitoUser) {
            const activityLogger = new ActivityLogger();
            activityLogger.logEvent('UserLogin');

            commit('setUsername', cognitoUser.username);
            commit('setAwsUserId', cognitoUser.sub);
            dispatch('refreshUserContext');
        },
        setLoggedOut({ commit }) {
            commit(mutations.SET_LOGGED_OUT);
            commit('setUsername', null);
            commit('setAwsUserId', null);
            commit('setUser', null);
        },
        refreshUserContext({ commit }) {
            commit('startLoading');
            apolloClient.query({
                query: GET_USER_CONTEXT,
                variables: { awsUserId: store.state.awsUserId },
                fetchPolicy: 'network-only'
            }).then((res) => {
                if (res.data.user && res.data.user.enabled) {
                    commit('setUser', res.data.user);
                    commit(mutations.SET_LOGGED_IN);
                } else {
                    console.log('user not enabled');
                    store.dispatch('setLoggedOut');
                    router.push({
                        name: 'Unauthorized'
                    })
                }
            }).catch((err) => {
                console.log('error loading user context');
                console.log(err);
                store.dispatch('setLoggedOut');
            }).finally(() => {
                //console.log('refreshed user context');
                commit('stopLoading');
            });
        },
    },
    getters: {
        user: state => state.user,
        isLoading: state => state.loading > 0,
        loading: state => state.loading,
        loggedIn: state => state.loggedIn,
        mapStyleNames(): string[] {
            return MAPSTYLES.map((x) => x[0]);
        },
        selectedMapStyle: state => state.selectedMapStyle,
        selectedMapStyleURL(state): string {
            return MAPSTYLES.find((x) => x[0] === state.selectedMapStyle)[1];
        },
        canProcess(state) {
            if (state.user?.userType === 'Admin' || state.user?.userType === 'Power User')
                return true;
            return false;
        },
        isPowerUser(state) {
            if (state.user?.userType === 'Power User')
                return true;
            return false;
        }
    },
})

export default store