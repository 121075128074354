import gql from "graphql-tag";

export const GET_PROJECTLAYERS = gql`
query { 
  projectLayers {
    id
    name
    comments
    source
    layers
    project { 
        id, 
        name,
        organization { id, name }
        }
    ignore
    showByDefault
    created
    lastModified
  }
}
`;

export const GET_PROJECT_LAYERS_FOR_PROJECT = gql`
query ProjectLayersForProject($projectId: Float!){ 
    projectLayers: projectLayersForProject(projectId: $projectId) {
    id
    name
    comments
    source
    layers
    project { 
        id, 
        name,
        organization { id, name }
        }
    ignore
    showByDefault
    created
    lastModified
  }
}
`;

export const ADD_PROJECTLAYER = gql`
mutation createProjectLayer ($name: String!, $ignore: Boolean!, $showByDefault: Boolean!, $comments: String!, $source: String!, $layers: String!, $projectId: Float!) {
      createProjectLayer(projectLayer: {
        name: $name
        comments: $comments
        ignore:$ignore
        showByDefault:$showByDefault
        projectId:$projectId
        source:$source
        layers:$layers
      }
    ) {
        id name ignore showByDefault comments source layers
        project { 
        id, 
        name,
        organization { id, name }
        }
        created
        lastModified
    }
  }
`;

export const UPDATE_PROJECTLAYER = gql`
    mutation updateProjectLayer ($id: ID!, $name: String!, $ignore: Boolean!, $showByDefault: Boolean!, $comments: String!, $source: String!, $layers: String!, $projectId: Float!) {
      updateProjectLayer(projectLayer: {
        id: $id
        name: $name
        comments: $comments
        ignore:$ignore
        showByDefault:$showByDefault
        projectId:$projectId
        source:$source
        layers:$layers
      }
    ) {
        id name ignore showByDefault comments source layers
        project { 
        id, 
        name,
        organization { id, name }
        }
        created
        lastModified
    }
}
`;

export const DELETE_PROJECTLAYER = gql`
mutation deleteProjectLayer ($id: Float!) {
    deleteProjectLayer(id: $id) 
}
`;


