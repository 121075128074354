
export default {
  name: "TrajectoryProcessingRunsSummary",
  props: {
    trajectoryProcessingRuns: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    getStatusCount(runs, status) {
      return runs.filter((x) => x.status === status).length;
    },
  },
};
