export const MAPBOXTOKEN = "pk.eyJ1IjoiY291cHN0ZXI3NCIsImEiOiJja2xwdjRwaWYwc2Q2Mm9sYmprbzhueng2In0.p-FbkbBhJWBKW-evWZfmgw";
export const MAPSTYLES = [
    ["satellite streets", "mapbox://styles/mapbox/satellite-streets-v11"],
    ["topographic", "mapbox://styles/mapbox/outdoors-v11"],
    ["streets", "mapbox://styles/mapbox/streets-v11"],
    ["satellite", "mapbox://styles/mapbox/satellite-v9"],
];

export const PDAL_PIPELINES = [
    {
        name: 'SO Filter', pipeline: {
            "pipeline": [
                {
                    "type": "filters.outlier",
                    "method": "statistical",
                    "mean_k": 12,
                    "multiplier": 2.2
                },
                {
                    "type": "filters.range",
                    "limits": "Classification![7:7]"
                },
            ]
        }, comments: ''
    },
    {
        name: 'SO Filter and Decimate', pipeline: {
            "pipeline": [
                {
                    "type": "filters.outlier",
                    "method": "statistical",
                    "mean_k": 12,
                    "multiplier": 2.2
                },
                {
                    "type": "filters.range",
                    "limits": "Classification![7:7]"
                },
                {
                    "type": "filters.decimation",
                    "step": 2
                }
            ]
        }, comments: ''
    },
    {
        name: 'Ground Classification', pipeline: {
            "pipeline": [
                {
                    "type": "filters.elm"
                },
                {
                    "type": "filters.outlier"
                },
                {
                    "type": "filters.smrf",
                    "ignore": "Classification[7:7]",
                    "slope": 0.2,
                    "window": 16,
                    "threshold": 0.45,
                    "scalar": 1.2
                },
                {
                    "type": "filters.range",
                    "limits": "Classification[2:2]"
                }
            ]
        }, comments: ''
    },
];

export const EXAMPLE_LAYERS = [
    {
        name: 'Ditchline', value:
            [{
                "type": "line",
                "paint": {
                    "line-color": "black",
                    "line-width": 3
                }
            }
            ]
    },
    {
        name: 'KPs', value:
            [
                {
                    "type": "circle",
                    "minzoom": 10,
                    "paint": {
                        "circle-radius": {
                            "base": 1.75,
                            "stops": [
                                [
                                    12,
                                    2
                                ],
                                [
                                    22,
                                    50
                                ]
                            ]
                        },
                        "circle-color": "#FFF"
                    }
                },
                {
                    "type": "symbol",
                    "minzoom": 10,
                    "paint": {
                        "text-color": "white"
                    },
                    "layout": {
                        "text-field": "{Name}",
                        "text-font": [
                            "Open Sans Semibold",
                            "Arial Unicode MS Bold"
                        ],
                        "text-offset": [
                            3,
                            -0.75
                        ],
                        "text-anchor": "right"
                    }
                }
            ]
    },
    {
        name: 'ROW', value:
            [
                {
                    "type": "fill",
                    "layout": {},
                    "paint": {
                        "fill-color": "#FFDB58",
                        "fill-opacity": 0.2
                    }
                },
                {
                    "type": "line",
                    "layout": {},
                    "paint": {
                        "line-color": "#FFDB58",
                        "line-width": 1
                    }
                }
            ]
    },
    {
        name: 'TWS', value:
            [
                {
                    "type": "fill",
                    "layout": {},
                    "paint": {
                        "fill-color": "pink",
                        "fill-opacity": 0.4
                    }
                },
                {
                    "type": "line",
                    "layout": {},
                    "paint": {
                        "line-color": "grey",
                        "line-width": 1
                    }
                }
            ]
    },
];