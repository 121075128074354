export function bootstrap (module) {
    if (module.getters === undefined) {
      module.getters = {}
    }
  
    if (module.mutations === undefined) {
      module.mutations = {}
    }
  
    Object.keys(module.state).forEach(key => {
      if (module.getters[key] === undefined) {
        module.getters[key] = state => state[key]
      }
      if (module.mutations[key] === undefined) {
        module.mutations[key] = (state, value) => {
          state[key] = value
        }
      }
    })
  
    return module
  }
  