
import {
  MglMap,
  MglGeojsonLayer,
  MglNavigationControl,
  MglGeolocateControl,
} from "vue-mapbox";
import mapboxgl from "mapbox-gl";
import moment from "moment";
import { Scan, ScanImageSet } from "../models/models";
import { mapGetters } from "vuex";
import { MAPBOXTOKEN } from "../contants";
// eslint-disable-next-line
const length = require("@turf/length").default;

const scan2Feature = (scan: Scan) => {
  return {
    type: "Feature",
    properties: {
      device: scan.deviceId,
      session: scan.sessionId,
      id: scan.friendlyName ? scan.friendlyName : scan.id,
      capturedTimestamp: scan.capturedTimestamp,
      tags: scan.tags,
      length: scan.length,
    },
    geometry: scan.simplePath,
  };
};

const maxToLoadOnMultiSelect = 50;
const ScansSource = "lux-scan-source";
const UnselectedLayer = "lux-unselected";
const SelectedLayer = "lux-selected";

export default {
  name: "mapView",
  components: {
    MglMap: MglMap,
    MglGeojsonLayer: MglGeojsonLayer,
    MglNavigationControl: MglNavigationControl,
    MglGeolocateControl: MglGeolocateControl,
  },
  data: () => {
    return {
      mapboxToken: MAPBOXTOKEN,
      mapZoom: 4,
      boundsPadding: 200,
      start: null, //variable to store the bounding box start coordinates
      current: null, //variable to store the bounding box current coordinates
      box: null, //variable for the draw box element
      popup: null, //pop up?
      mapInteractive: true,
      mapCentre: { lat: 53.9333, lng: -116.5765 },
      scanCache: [], // required for removeall
      measuring: false,
      measuringDistance: 0,
      measuringSource: {
        type: "FeatureCollection",
        features: [],
      },
      measuringLineString: {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [],
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "mapStyleNames",
      "selectedMapStyle",
      "selectedMapStyleURL",
    ]),
    ...mapGetters("viewer", [
      "summaryScanList",
      "selectedScanIdList",
      "filteredScanIdList",
      "projectLayerList",
      "selectedProjectLayerIdList",
      "visibleScanIdList",
      "focusedScanId",
      "focusedImageSet",
      "imageViewVisible",
      "isProjectLayerSelected",
      "projectId",
    ]),
    canvas() {
      return this.$refs.mapbox.map.getCanvasContainer();
    },
    scanExists() {
      return Object.keys(this.scan).length > 0;
    },
    scans(): Scan[] {
      let scans = this.summaryScanList;
      if (this.filteredScanIdList?.length > 0)
        scans = scans.filter((x) => this.filteredScanIdList.find((y) => y === x.id));
      return scans;
    },
  },
  methods: {
    selectStyle(style) {
      this.$store.dispatch("setMapStyle", style);
    },
    initMap() {
      //console.log("init map called");
      const map = this.$refs.mapbox.map;

      // adding measurement datasources (these are always in place)
      map.addSource("measuringSource", {
        type: "geojson",
        data: this.measuringSource,
      });

      map.addLayer({
        id: "measure-points",
        type: "circle",
        source: "measuringSource",
        paint: {
          "circle-radius": 5,
          "circle-color": "#000000",
        },
        filter: ["in", "$type", "Point"],
      });

      map.addLayer({
        id: "measure-lines",
        type: "line",
        source: "measuringSource",
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": "#000000",
          "line-width": 2.5,
        },
        filter: ["in", "$type", "LineString"],
      });

      this.renderLayers();
      this.renderAllScans();
    },
    measuringTool() {
      this.measuring = !this.measuring;
    },
    renderAllScans() {
      //console.log('renderAllScans');
      const map = this.$refs.mapbox.map;

      map.popup = new mapboxgl.Popup({
        closeButton: false,
      });

      // zoom to where the most appropriate paths will be
      this.setZoomFocus();

      // build the scan's feature collection
      const fc = {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      };
      for (const scan of this.scans) {
        fc.data.features.push(scan2Feature(scan));
      }

      // remove layers and readd
      // TODO: consider a filter approach vs rebuilding the datasource.. but for now this works.
      if (map?.getLayer(UnselectedLayer)) {
        map.removeLayer(UnselectedLayer);
      }

      if (map?.getLayer(SelectedLayer)) {
        map.removeLayer(SelectedLayer);
      }

      if (map.getSource(ScansSource)) {
        map.removeSource(ScansSource);
      }

      map.addSource(ScansSource, fc);
      //console.log('** adding unselected layer');
      map.addLayer({
        id: UnselectedLayer,
        type: "line",
        source: ScansSource,
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": "red",
          "line-width": 5,
          "line-opacity": 0.8,
        },
      });

      //console.log('** adding selected layer');
      map.addLayer({
        id: SelectedLayer,
        type: "line",
        source: ScansSource,
        paint: {
          "line-color": "Blue",
          "line-width": 5,
        },
        filter: ["in", "FIPS", ""],
      });

      this.highlightSelectedScans();

      this.setForSelection();

      this.setLayerOrders();
    },
    setLayerOrders() {
      const map = this.$refs.mapbox.map;
      if (map?.getLayer(UnselectedLayer)) map.moveLayer(UnselectedLayer);
      if (map?.getLayer(SelectedLayer)) map.moveLayer(SelectedLayer);
    },
    async removeAllLayers() {
      //console.log('removeAllLayers')
      // without having the list of layers, remove all lux-layers which exist
      const map = this.$refs.mapbox.map;
      // get layers we put there
      const layers = map.getStyle().layers;
      const luxLayers = layers.filter((x) => x.id.startsWith("lux"));
      const sources = [];
      for (const layer of luxLayers) {
        if (!sources.includes(layer.source)) sources.push(layer.source);
        //console.log(`removeAllLayers...removing layer ${layer.id}`)
        map.removeLayer(layer.id);
      }
      for (const source of sources) {
        //console.log(`removeAllLayers...checking if source ${source}`)
        if (map?.getSource(source)) {
          //console.log(`removeAllLayers...removing source ${source}`)
          map.removeSource(source);
        }
      }
    },
    async renderLayers() {
      // reconciles the selected layers and shows/hides as appropriate
      //console.log('renderLayers')
      const map = this.$refs.mapbox.map;
      for (const layer of this.projectLayerList) {
        if (
          this.isProjectLayerSelected(layer.id) &&
          !map.getSource(`lux-layersource-${layer.id}`)
        ) {
          //console.log(`renderLayers...adding source ${layer.id}`)
          // add source
          map.addSource(`lux-layersource-${layer.id}`, {
            type: "geojson",
            data: JSON.parse(layer.source),
          });

          // add all layers
          let i = 0;
          for (const maplayer of JSON.parse(layer.layers)) {
            i++;
            maplayer.id = `lux-layer-${layer.id}-${i}`;
            maplayer.source = `lux-layersource-${layer.id}`;
            //console.log(`renderLayers...adding layer ${maplayer.id}`)
            map.addLayer(maplayer);
          }
        } else if (
          !this.isProjectLayerSelected(layer.id) &&
          map.getSource(`lux-layersource-${layer.id}`)
        ) {
          // remove the layers
          let i = 0;
          for (const maplayer of JSON.parse(layer.layers)) {
            i++;
            maplayer.id = `lux-layer-${layer.id}-${i}`;
            //console.log(`renderLayers...removing layer ${maplayer.id}`)
            map.removeLayer(maplayer.id);
          }
          // remove the source
          //console.log(`renderLayers...removing source ${layer.id}`)
          map.removeSource(`lux-layersource-${layer.id}`);
        }
      }
      this.setLayerOrders();
    },
    selectionMousePos(e) {
      // Return the xy coordinates of the mouse position
      const rect = this.canvas.getBoundingClientRect();
      return new mapboxgl.Point(
        e.clientX - rect.left - this.canvas.clientLeft,
        e.clientY - rect.top - this.canvas.clientTop
      );
    },
    selectionMouseDown(e) {
      const map = this.$refs.mapbox.map;
      // Continue the rest of the function if the shiftkey is pressed.
      if (!(e.shiftKey && e.button === 0)) {
        // am I selecting a scan?
        if (this.$refs.mapbox.map.scanId === null) return;

        if (!this.selectedScanIdList.find((x) => x === this.$refs.mapbox.map.scanId))
          this.$store.dispatch("viewer/selectScan", this.$refs.mapbox.map.scanId);
        else this.$store.dispatch("viewer/unselectScan", this.$refs.mapbox.map.scanId);
        return;
      }

      // Disable default drag zooming when the shift key is held down.
      map.dragPan.disable();

      // Call functions for the following events
      document.addEventListener("mousemove", this.selectionOnMouseMove);
      document.addEventListener("mouseup", this.selectionOnMouseUp);
      document.addEventListener("keydown", this.selectionOnKeyDown);

      // Capture the first xy coordinates
      this.start = this.selectionMousePos(e);
    },
    selectionOnMouseMove(e) {
      // Capture the ongoing xy coordinates
      this.current = this.selectionMousePos(e);

      // Append the box element if it doesnt exist
      if (!this.box) {
        this.box = document.createElement("div");
        this.box.classList.add("boxdraw");
        this.canvas.appendChild(this.box);
      }

      const minX = Math.min(this.start.x, this.current.x),
        maxX = Math.max(this.start.x, this.current.x),
        minY = Math.min(this.start.y, this.current.y),
        maxY = Math.max(this.start.y, this.current.y);

      // Adjust width and xy position of the box element ongoing
      const pos = "translate(" + minX + "px," + minY + "px)";

      this.box.style.transform = pos;
      this.box.style.WebkitTransform = pos;
      this.box.style.width = maxX - minX + "px";
      this.box.style.height = maxY - minY + "px";
    },
    selectionOnMouseUp(e) {
      // Capture xy coordinates
      this.finish([this.start, this.selectionMousePos(e)]);
    },
    selectionOnKeyDown(e) {
      // If the ESC key is pressed
      if (e.keyCode === 27) this.finish();
    },
    finish(bbox) {
      const map = this.$refs.mapbox.map;
      // Remove these events now that finish has been called.
      document.removeEventListener("mousemove", this.selectionOnMouseMove);
      document.removeEventListener("keydown", this.selectionOnKeyDown);
      document.removeEventListener("mouseup", this.selectionOnMouseUp);

      if (this.box) {
        this.box.parentNode.removeChild(this.box);
        this.box = null;
      }

      // If bbox exists. use this value as the argument for `queryRenderedFeatures`
      if (bbox) {
        const features = this.$refs.mapbox.map.queryRenderedFeatures(bbox, {
          layers: [UnselectedLayer],
        });

        if (features.length >= maxToLoadOnMultiSelect) {
          this.$snackbar.showMessage(
            `Please select less than ${maxToLoadOnMultiSelect} scans`,
            "error",
            3000
          );
        } else {
          const newSelectedScanIds = features.map((x) => x.properties.id);
          this.$store.dispatch("viewer/selectManyScans", newSelectedScanIds);
        }
      }
      map.dragPan.enable();
    },
    highlightSelectedScans() {
      const map = this.$refs.mapbox.map;
      // called to show which scans are selected
      // if there is a label, remove it
      this.removeCameraLabel();

      // can only highlight, if we have created the layers
      if (map.getLayer(SelectedLayer)) {
        const filter = ["in", "id"].concat(this.selectedScanIdList);
        map.setFilter(SelectedLayer, filter);
        this.setZoomFocus();
      }
    },
    zoomToPath(scanId: string) {
      const map = this.$refs.mapbox.map;
      const scan: Scan = this.summaryScanList.find((x) => x.id === scanId);

      // TODO: confirm this works (the zoom) with imported point clouds
      if (scan && scan.simplePath) {
        const coordinates = [].concat(scan.simplePath.coordinates);
        const bounds = coordinates.reduce((bounds, coord) => {
          return bounds.extend(coord);
        }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

        map.fitBounds(bounds, {
          padding: this.boundsPadding,
        });
      }
    },
    setZoomFocus() {
      if (this.selectedScanIdList?.length > 0) {
        this.zoomTo(this.selectedScanIdList);
      } else {
        const x = this.scans.map((x) => x.id);
        this.zoomTo(x);
      }
    },
    setZoomFocusToAll() {
      const x = this.scans.map((x) => x.id);
      this.zoomTo(x);
    },
    zoomTo(scanIds) {
      const map = this.$refs.mapbox.map;
      let coordinates = [];
      // amagulmate all the scan coordintes into one collection
      for (const scanId of scanIds) {
        const scan = this.scans.find((x) => x.id === scanId);
        if (scan !== null && scan.simplePath !== null)
          coordinates = coordinates.concat(scan.simplePath.coordinates);
      }

      if (coordinates.length > 0) {
        // find the bounds by adding each coord to the bound
        const bounds = coordinates.reduce((bounds, coord) => {
          return bounds.extend(coord);
        }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

        // set the bounds of the map
        map.fitBounds(bounds, {
          padding: this.boundsPadding,
        });
      }
    },
    labelCamera(imageSet: ScanImageSet) {
      //console.log(imageSet);
      const map = this.$refs.mapbox.map;
      this.removeCameraLabel();

      if (this.imageViewVisible && imageSet) {
        const coordinates = [parseFloat(imageSet.lon), parseFloat(imageSet.lat)];

        this.marker = new mapboxgl.Popup()
          .setText(`Image Set #${parseInt(imageSet.id) + 1}`)
          .setLngLat(coordinates)
          .addTo(map);
      }
    },
    removeCameraLabel() {
      if (this.marker) this.marker.remove();
    },
    selectionMouseMove(e) {
      const map = this.$refs.mapbox.map;

      // here to ensure the layers exist
      if (map.getLayer(SelectedLayer) && map.getLayer(UnselectedLayer)) {
        const features = map.queryRenderedFeatures(e.point, {
          layers: [UnselectedLayer, SelectedLayer],
        });

        // Change the cursor style as a UI indicator.
        map.getCanvas().style.cursor = features.length ? "pointer" : "";

        if (!features.length) {
          map.scanId = null;
          if (map.popup) map.popup.remove();
          return;
        }

        const feature = features[0];

        // TODO: load tags from summary (which would be current)
        map.popup
          .setLngLat(e.lngLat)
          .setHTML(
            `${feature.properties.id} <br/> ${moment(feature.properties.capturedTimestamp)
              .local()
              .format("LLLL")}<br/> ${feature.properties.tags} `
          )
          .addTo(map);

        map.scanId = feature.properties.id;
      }
    },
    setForSelection() {
      const map = this.$refs.mapbox.map;
      map.off("click", this.measuringOnClick);
      map.off("mousemove", this.measuringMouseMove);

      this.measuringSource.features = [];
      this.measuringDistance = 0;
      if (map.getSource("measuringSource"))
        map.getSource("measuringSource").setData(this.measuringSource);

      // only turn on the event listeners for selection if selection is selected
      if (this.scans.length > 0) {
        this.canvas.addEventListener("mousedown", this.selectionMouseDown, true);
        map.on("mousemove", this.selectionMouseMove);
      }
    },
    setForMeasuring() {
      const map = this.$refs.mapbox.map;
      this.canvas.removeEventListener("mousedown", this.selectionMouseDown, true);
      map.off("mousemove", this.selectionMouseMove);

      map.on("click", this.measuringOnClick);
      map.on("mousemove", this.measuringMouseMove);
    },
    measuringMouseMove(e) {
      const map = this.$refs.mapbox.map;
      const features = map.queryRenderedFeatures(e.point, {
        layers: ["measure-points"],
      });
      // Change the cursor to a pointer when hovering over a point on the map.
      // Otherwise cursor is a crosshair.
      map.getCanvas().style.cursor = features.length ? "pointer" : "crosshair";
    },
    measuringOnClick(e) {
      const map = this.$refs.mapbox.map;

      const features = map.queryRenderedFeatures(e.point, {
        layers: ["measure-points"],
      });

      // Remove the linestring from the group
      // so we can redraw it based on the points collection.
      if (this.measuringSource.features.length > 1) this.measuringSource.features.pop();

      // If a feature was clicked, remove it from the map.
      if (features.length) {
        const id = features[0].properties.id;
        this.measuringSource.features = this.measuringSource.features.filter(
          (point) => point.properties.id !== id
        );
      } else {
        const point = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [e.lngLat.lng, e.lngLat.lat],
          },
          properties: {
            id: String(new Date().getTime()),
          },
        };

        this.measuringSource.features.push(point);
      }

      if (this.measuringSource.features.length > 1) {
        this.measuringLineString.geometry.coordinates = this.measuringSource.features.map(
          (point) => point.geometry.coordinates
        );

        this.measuringSource.features.push(this.measuringLineString);

        // Populate the distanceContainer with total distance
        const distance = length(this.measuringLineString);
        this.measuringDistance = distance;
      } else {
        this.measuringDistance = 0;
      }

      map.getSource("measuringSource").setData(this.measuringSource);
    },
    resetCursor() {
      const map = this.$refs.mapbox.map;
      map.getCanvas().style.cursor = "";
    },
  },
  watch: {
    measuring: function () {
      this.resetCursor();
      if (this.measuring) {
        this.setForMeasuring();
      } else {
        this.setForSelection();
      }
    },
    selectedMapStyle: function () {
      // console.log('*** mapview selectedMapStyle')
      setTimeout(() => this.renderAllScans(), 300);
    },
    selectedScanIdList: function () {
      // console.log('*** mapview selectedScanIdList')
      this.highlightSelectedScans();
    },
    filteredScanIdList: function () {
      // console.log('*** mapview filterScanIdList')
      this.renderAllScans();
    },
    focusedScanId: function () {
      // console.log('*** mapview focusedScanId')
      this.zoomToPath(this.focusedScanId);
    },
    focusedImageSet: function () {
      // console.log('*** mapview FocusedImageSet')
      this.labelCamera(this.focusedImageSet);
    },
    imageViewVisible: function () {
      // console.log('*** mapview imageViewVisible')
      this.labelCamera(this.focusedImageSet);
    },
    selectedProjectLayerIdList: function () {
      // console.log('*** mapview selectedProjectLayerIdList')
      this.renderLayers();
    },
    projectId: function () {
      // console.log('*** mapview projectId')
      this.removeAllLayers();
    },
    projectLayerList: function () {
      // console.log('*** mapview projectLayerList')
      this.renderLayers();
    },
  },
};
