import gql from "graphql-tag";

export const GET_PIPELINES = gql`
query { 
  pipelines {
    id
    project { 
        id
    }
    name
    description
    pipeline
    dependencies
    created
    lastModified
    global
  }
}
`;

export const GET_PIPELINE_BY_PROJECT = gql`
query pipelineByProject($projectId: Float!) {	
  pipelines: pipelineByProject(projectId: $projectId) {
    id
    project { 
        id
    }
    name
    description
    pipeline
    dependencies
    created
    lastModified
    global
  }
}
`;

export const ADD_PIPELINE = gql`
mutation createPipeline ($projectId: String, $name: String!, $description: String!,  $pipeline: String, $dependencies: [String!], $global: Boolean!) {
    createPipeline(pipeline: {
        projectId: $projectId
        name: $name
        description: $description
        pipeline: $pipeline
        dependencies: $dependencies
        global: $global
      }
    ) {
        id name description pipeline dependencies 
        project { 
        id
        }
        created
        lastModified
        global
    }
  }
`;

export const UPDATE_PIPELINE = gql`
    mutation updatePipeline ($id: ID!, $projectId: String!, $name: String!, $description: String!, $pipeline: String!, $dependencies: [String!], $global: Boolean!) {
        updatePipeline(pipeline: {
        id: $id
        projectId: $projectId
        name: $name
        description: $description
        pipeline: $pipeline
        dependencies: $dependencies
        global: $global
      }
    ) {
        id name description pipeline dependencies 
        project { 
        id
        }
        created
        lastModified
        global
    }
}
`;

export const DELETE_PIPELINE = gql`
mutation deletePipeline ($id: Float!) {
    deletePipeline(id: $id) 
}
`;