
import {
  GET_DEVICE_ASSIGNMENTS,
  ADD_DEVICE_ASSIGNMENTS,
  DELETE_DEVICE_ASSIGNMENT,
  UPDATE_DEVICE_ASSIGNMENT,
} from "../graphql/device-assignment-queries";
import { mapGetters } from "vuex";
import { GET_SESSIONS_LIST_FOR_PROJECT_LIST } from "../graphql/trajectory-query";
import { generalUtilMixin } from "../mixins/general-util-mixin";

export default {
  name: "admin-device-assignment",
  mixins: [generalUtilMixin],
  data() {
    return {
      selectedDevices: [],
      selectedProjects: [],
      showAssigned: false,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      type: "week",
      types: ["month", "week", "day", "4day"],
      mode: "stack",
      modes: ["stack", "column"],
      colors: [
        "blue",
        "red",
        "deep-purple",
        "light-blue",
        "indigo",
        "green",
        "light-green",
        "lime",
        "amber",
        "orange",
        "deep-orange",
        "pink",
        "cyan",
        "teal",
        //"yellow",
        "purple",
        "brown",
        "blue-grey",
      ],
      categories: ["Assignment", "Session"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      focus: new Date(),
      ready: false,
      events: [],
      search: "",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      assignmentTypes: ["Primary", "Secondary"],
      loadingQueriesCount: 0,
      editingDialog: false,
      editedIndex: -1,
      editedItem: {
        project: null,
        device: null,
        from: null,
        to: null,
        assignmentType: null,
      },
      defaultItem: {
        project: null,
        device: null,
        from: null,
        to: null,
        assignmentType: null,
      },
      isFormValid: false,
      rules: {
        required: (v) => !!v || "Value is required",
      },
    };
  },
  apollo: {
    deviceAssignments: {
      query: GET_DEVICE_ASSIGNMENTS,
      //fetchPolicy: 'network-only',
      loadingKey: "loadingQueriesCount",
    },
    unassignedSessions: {
      query: GET_SESSIONS_LIST_FOR_PROJECT_LIST,
      //query: GET_SESSIONS_LIST_FOR_PROJECT,
      loadingKey: "loadingQueriesCount",
      //fetchPolicy: 'network-only',
      variables() { 
        return {
        projectIds: this.showAssigned ? this.user.organization.projects.map(x => x.id).join(',') : "0"
      }
      },
      update(data) {
        return data.sessionSummary.filter(x => x.assignmentType !== 'Secondary');
      },
    },
  },
  mounted() {
    this.ready = true;
    this.scrollToTime();
    this.updateTime();
  },
  computed: {
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
    projects() {
      if (!this.user) return [];
      return [...this.user.organization.projects.filter((x) => x.organization !== null)].sort(
          (a, b) =>
            a.organization?.name.localeCompare(b.organization?.name) ||
            a.name.localeCompare(b.name)
        );
    },
    devices() {
      if (!this.user) return [];
      return this.user.organization.devices;
    },
    ...mapGetters(["user", "isPowerUser"]),
    isFormValidWithDates() {
      if (this.isFormValid) {
        if (this.editedItem.to && this.editedItem.from) return true;
      }
      return false;
    },
    formTitle() {
      return this.editedIndex === -1
        ? "New Device Assignment"
        : "Edit Device Assignment";
    },
  },
  methods: {
    refreshData() {
      this.$apollo.queries.deviceAssignments.refresh();
      this.$apollo.queries.unassignedSessions.refresh();
    },
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);
      this.cal.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    setToday() {
      this.focus = "";
    },
    editEvent({ nativeEvent, event }) {
      if (event.category !== 'Assignment') return;

      this.editingDialog = true;
      this.editedIndex = this.deviceAssignments.findIndex(
        (x) => x.id === event.item.id
      );
      this.editedItem = this.editedItem =
        this.deviceAssignments[this.editedIndex];
    },
    createEvent(datetime) {
      // this event appears to always fire AFTER the edit event, so the dialog acts as a semaphore to determien which mode to open
      if (!this.editingDialog) {
        const from = new Date(
          datetime.year,
          datetime.month - 1,
          datetime.day,
          datetime.hour ,
          (Math.round(datetime.minute/15) * 15) % 60
        ).toISOString();
        const to = new Date(
          datetime.year,
          datetime.month - 1,
          datetime.day,
          datetime.hour + (new Date().getTimezoneOffset()) + 2,
          (Math.round(datetime.minute/15) * 15) % 60,
        ).toISOString();
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.assignmentType = this.assignmentTypes[0]
        this.editedItem.from = from;
        this.editedItem.to = to;
        this.editingDialog = true;
      }
    },
    getEvents({ start, end }) {
      if (!this.deviceAssignments || !this.unassignedSessions) {
        this.events = [];
      } else if (this.isPowerUser) {
        const assignments = this.deviceAssignments
          .filter(
            (x) =>
              (this.selectedProjects.length > 0 &&
                this.selectedDevices.length === 0) ||
              this.selectedDevices.some((y) => y.id === x.device.id)
          )
          .filter(
            (x) =>
              (this.selectedProjects.length === 0 &&
                this.selectedDevices.length > 0) ||
              this.selectedProjects.some((y) => y.id === x.project.id)
          ).filter((x) => x.project.organization.id === this.user.organization.id)
          .map((da) => {
            return {
              name: `${da.device.id} -> ${da.project.name} ${da.assignmentType === "Secondary" ? " (Secondary)" : ''}`,
              projectId: da.project.id,
              deviceId: da.device.id,
              category: "Assignment",
              secondary: da.assignmentType === "Secondary",
              item: da,
              start: new Date(da.from),
              end: new Date(da.to),
              timed: 2,
            };
          });

        const sessions = this.unassignedSessions.map((s) => {
          return {
            name: s.sessionId,
            projectId: s.projectId,
            deviceId: s.sessionId.substring(0,4),
            category: "Session",
            secondary: false,
            item: s,
            start: new Date(s.capturedTimestamp),
            end: new Date(s.endCapturedTimestamp),
            timed: 2,
          };
        });
        this.events = assignments.concat(sessions);
      }  else {
        // device assignments
        const assignments = this.deviceAssignments
          .filter(
            (x) =>
              (this.selectedProjects.length > 0 &&
                this.selectedDevices.length === 0) ||
              this.selectedDevices.some((y) => y.id === x.device.id)
          )
          .filter(
            (x) =>
              (this.selectedProjects.length === 0 &&
                this.selectedDevices.length > 0) ||
              this.selectedProjects.some((y) => y.id === x.project.id)
          )
          .map((da) => {
            return {
              name: `${da.device.id} -> ${da.project.name} ${da.assignmentType === "Secondary" ? " (Secondary)" : ''}`,
              projectId: da.project.id,
              deviceId: da.device.id,
              category: "Assignment",
              secondary: da.assignmentType === "Secondary",
              item: da,
              start: new Date(da.from),
              end: new Date(da.to),
              timed: 2,
            };
          });

        const sessions = this.unassignedSessions.map((s) => {
          return {
            name: s.sessionId,
            projectId: s.projectId,
            deviceId: s.sessionId.substring(0,4),
            category: "Session",
            secondary: false,
            item: s,
            start: new Date(s.capturedTimestamp),
            end: new Date(s.endCapturedTimestamp),
            timed: 2,
          };
        });
        this.events = assignments.concat(sessions);
      }
    },
    getEventColor(event) {
      if (event.category === "Assignment") {
        //const index = this.devices.findIndex((x) => x.id === event.deviceId);
        return `${this.colors[event.projectId % 17]}${event.secondary && event.category === "Assignment" ? " lighten-3" : ""}`;
      }
      else if (event.projectId > 0) {
        //const index = this.devices.findIndex((x) => x.id === event.deviceId);
        return `${this.colors[event.projectId % 17]} lighten-3`;
      }
      else {
        return "grey darken-2";
      }
    },
    getProjectName(item) {
      if (item)
        return `${item.organization ? item.organization.name + " : " : ""}${
          item.name
        }`;
      else return "";
    },
    deleteItem() {
      this.$apollo
        .mutate({
          mutation: DELETE_DEVICE_ASSIGNMENT,
          variables: {
            id: Number(this.editedItem.id),
          },
        })
        /* .then(() => {
          this.deviceAssignments.splice(this.editedIndex, 1);
        }) */
        .catch((error) => {
          console.log(error);
          this.$snackbar.showMessage({
            content: error.message,
            color: "error",
            timeout: 8000,
          });
        })
        .finally(() => {
          this.refreshData();
          this.closeDialog();
        });
    },
    closeDialog() {
      this.editedIndex = -1;
      this.editingDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    saveItem() {
      if (this.editedIndex > -1) {
        const assignment = this.editedItem;
        this.$apollo
          .mutate({
            mutation: UPDATE_DEVICE_ASSIGNMENT,
            variables: {
              id: Number(assignment.id),
              projectId: Number(assignment.project.id),
              deviceId: assignment.device.id,
              assignmentType: assignment.assignmentType,
              from: new Date(assignment.from).toUTCString(),
              to: new Date(assignment.to).toUTCString(),
            },
          })
          /* .then((data) => {
            assignment = {
              ...assignment,
              ...data.data.updateProjectDeviceAssignment,
            };
            Object.assign(this.deviceAssignments[this.editedIndex], assignment);
          }) */
          .catch((error) => {
            console.log(error);
            this.$snackbar.showMessage(error.message, "error");
          })
          .finally(() => {
            this.refreshData();
            this.closeDialog();
          });
      } else {
        const assignment = this.editedItem;
        this.$apollo
          .mutate({
            mutation: ADD_DEVICE_ASSIGNMENTS,
            variables: {
              projectId: Number(assignment.project.id),
              deviceId: assignment.device.id,
              assignmentType: assignment.assignmentType,
              from: new Date(assignment.from).toUTCString(),
              to: new Date(assignment.to).toUTCString(),
            },
          })
          /* .then((data) => {
            assignment = {
              ...assignment,
              ...data.data.createProjectDeviceAssignment,
            };
            this.deviceAssignments.push(assignment);
          }) */
          .catch((error) => {
            console.log(error);
            this.$snackbar.showMessage(error.message, "error");
          })
          .finally(() => {
            this.refreshData();
            this.closeDialog();
          });
      }
    },
  },
  watch: {
    selectedDevices() {
      this.getEvents({ start: null, end: null });
    },
    selectedProjects() {
      this.getEvents({ start: null, end: null });
    },
    deviceAssignments() {
      //console.log('deviceAssignments changed')
      this.getEvents({ start: null, end: null });
    },
    unassignedSessions() {
      this.getEvents({ start: null, end: null });
    },
    showAssigned() {
      this.refreshData();
    },
  },
};
