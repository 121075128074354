import gql from "graphql-tag";

export const GET_USER_CONTEXT = gql`
query getOrgUserContext($awsUserId: String!) {	
  user: getOrgUserContext(awsUserId: $awsUserId) {
    id
    name
    userType
    email
    phone
    enabled
    awsUserId
    organization {
        id
        name
        devices { id }
        projects { 
            id
            name
            organization { id name } 
        }
        buckets{
          id
          name
        }
    }
  }
}
`;

export const GET_USER_BY_ORG_ID = gql`
query orgUsersByOrgId($orgId: Float!) {	
  orgUsers: orgUsersByOrgId(orgId: $orgId) {
    id
    name
    userType
    email
    phone
    enabled
    awsUserId
    organization {
        id
        name
        devices { id }
        projects { 
            id
            name
            organization { id name } 
        }
    }
  }
}
`;

export const GET_USERS = gql`
query {
  orgUsers {
        id 
        awsUserId 
        name 
        userType
        enabled
        organization { id, name }
        email
        phone
        username
    }
}
`;

export const ADD_USER = gql`
    mutation createOrgUser ($name: String!, $email: String!, $phone: String!, $username: String!, $organizationId: Float!) {
    createOrgUser(orgUser: 
      {
        name: $name
        email: $email
        phone: $phone
        username: $username
        organizationId: $organizationId
      }
    ) {
        id 
        awsUserId 
        name 
        userType
        enabled
        organization { id, name }
        email
        phone
        username
    }
}
`;

export const UPDATE_USER = gql`
    mutation updateOrgUser ($id: ID!, $name: String!, $enabled: Boolean!, $organizationId: Float!, $userType: String!, $email: String!, $phone: String!) {
    updateOrgUser(orgUser: 
        {
        id: $id
        name: $name
        enabled: $enabled
        organizationId: $organizationId
        userType: $userType
        email: $email
        phone: $phone
      }
    ) {
        id 
        awsUserId 
        name 
        userType
        enabled
        organization { id, name }
        email
        phone
        username
    }
}
`;

export const DELETE_USER = gql`
mutation deleteOrgUser ($id: Float!) {
    deleteOrgUser(id: $id) 
}
`;