import gql from "graphql-tag";

export const GET_ANNOTATIONS = gql`
query { 
  annotations {
    id
    scan { 
        id
    }
    comment
    title
    type
    point{
        type
        coordinates
    }
    created
    lastModified
  }
}
`;

export const ADD_ANNOTATION = gql`
mutation createAnnotation ($scanId: String!, $comment: String, $title: String,  $type: String!, $point: PointInput!) {
    createAnnotation(annotation: {
        scanId: $scanId
        comment: $comment
        title: $title
        type: $type
        point: $point
      }
    ) {
        id comment title type 
        scan { 
        id
        }
        point { 
        type
        coordinates
        }
        created
        lastModified
    }
  }
`;

export const UPDATE_ANNOTATION = gql`
    mutation updateAnnotation ($id: ID!, $scanId: String!, $comment: String!, $title: String!, $type: String!, $point: PointInput!) {
        updateAnnotation(annotation: {
        id: $id
        scanId: $scanId
        comment: $comment
        title: $title
        type: $type
        point: $point
      }
    ) {
        id comment title type 
        scan { 
        id
        }
        point { 
        type
        coordinates
        }
        created
        lastModified
    }
}
`;

export const DELETE_ANNOTATION = gql`
mutation deleteAnnotation ($id: Float!) {
    deleteAnnotation(id: $id) 
}
`;
