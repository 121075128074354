
import { Scan, ScanSummary } from "../models/models";
import moment from "moment";
import { mapGetters } from 'vuex'

const arrayEquals = (a, b) =>
  a.length === b.length &&
  a.every((v, i) => v === b[i]);

export default {
  name: 'scanFilter',
  props: ['enableSelect'],
  data: () => ({
    componentKey: 0,
    curPageInternal: 1,
    itemsPerPage: 20,
    filterComments: '',
    filterSessionId: '',
    filterDevice: '',
    filterStatus: '',
    filterScanId: '',
    filterTags: [],
    filterDates: [],
    filterType: '',
    statusOptions: ["(ALL)", "Provisional", "Final"], 
    maxSelected: 40,
    showCalendar: false,
  }),
  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters('viewer', [
            'summaryScanList',
            'filterSelected',
            'projectLayerList',
            'selectedScanIdList',
            'loadedScanList',
            'filteredScanIdList', 
            'selectedProjectLayerIdList',
            'visibleScanIdList',
            'isScanSelected',
            'project',
            'defaultScanTags',
            'someScanSelected',
            'isScanLoaded',
            'isScanVisible',
            'filteredSummaryScanList',
            'isProjectLayerSelected',
            'projectId',
            'availableDates',
            'availableDevices',
            'availableTypes',
            'availableTags',
            'isFiltered',
            'filterAnnotations',
            'filteredAnnotationsList'
    ]),
    tagOptions() {
        // project could be null if unassigned scans is selected
        return this.project ? this.defaultScanTags.concat(this.project.tagOptions) : this.defaultScanTags;
    },
    direction() {
      return this.navigation.shown === false ? "Open" : "Closed";
    },
    filterDateString: {
        get() {
            const dates =  [...this.filterDates].sort((a, b) => moment(a, 'YYYY-MM-DD').diff(moment(b, 'YYYY-MM-DD')))
            // set the datefilterstring (for the screen)
            if(dates.length == 1)
                return `on: ${dates[0]}`;
            else if (dates.length == 2)
                return `from: ${dates[0]} to: ${dates[1]}`;
            return '';
        },
        set() {
            this.filteredDates = [];
        }
    },
    filtered() {
        const results = 
            this.filterComments !== '' ||
            this.filterDevice !== '' ||
            this.filterStatus !== '' ||
            this.filterScanId !== '' ||
            this.filterSessionId !== '' ||
            this.filterDates.length > 0 ||
            this.filterTags.length > 0 ||
            this.isFiltered;
        return results;
    },
  },
  methods: {
    pruneDates(dates) {
        console.log(dates)
        // function ensures we don't add the same date twice
        return [...new Set(dates)];
    },
    allowedDates(day) {
        return this.availableDates.includes(day)
    },
    scanStatusFinal(item: Scan) {
      if (item.artifacts) {
        for (const artifact of item.artifacts) {
          if (artifact.artifactType === "pointcloud") {
            if (artifact.status === "final") return true;
          }
        }
      }
      return false;
    },
    scanHasPointCloud(scan: Scan) {
        for (const artifact of scan.artifacts) {
            if(artifact.artifactType.includes('pointcloud')) {
                return true;
            }
        }
        return false;
    },
    applyFilter() {
      if (this.filterAnnotations) {
        this.$store.dispatch('viewer/setFilteredScanIdList', this.filteredAnnotationsList);
      }
      else if (this.filterSelected) {
        this.$store.dispatch('viewer/setFilteredScanIdList', this.selectedScanIdList);
      }
      else {
        // the result set
        let filteredScans: ScanSummary[] = this.summaryScanList;

        // prep for dates
        let earliest = null;
        let latest = null;
        const dates =  [...this.filterDates].sort((a, b) => moment(a, 'YYYY-MM-DD').diff(moment(b, 'YYYY-MM-DD')))

        // set the dates for the folter
        if (dates.length === 1) {
            earliest = moment(dates[0]);
            latest = moment(dates[0]).add(1, 'days');
        } else if (dates.length === 2) {
            earliest = moment(dates[0]);
            latest = moment(dates[1]).add(1, 'days');
        }
        // apply the filter
        if (earliest && latest) {
            filteredScans = filteredScans.filter((x) => {
            if (
                moment(x.capturedTimestamp).isBefore(latest) &&
                moment(x.capturedTimestamp).isAfter(earliest)
            ) {
                return true;
            } else {
                return false;
            }
            });
        }
        if (this.filterTags.length > 0) {
            filteredScans = filteredScans.filter(scan => 
                scan.tags.filter(tag => this.filterTags.includes(tag)).length > 0 || 
                this.filterTags.some(x => x === '(Empty)') && scan.tags.length === 0
                );
        }
        // address client side scanId filtering
        filteredScans = filteredScans.filter((x) => {
            if (this.filterSessionId === '') return true;
            else if (!x.sessionId) return false;
            else return x.sessionId?.includes(this.filterSessionId);
        });
        // address client side scanId filtering
        filteredScans = filteredScans.filter((x) => {
            if (this.filterScanId === '') return true;
            else if (!x.id) return false;
            else return x.id?.includes(this.filterScanId);
        });
        // address client side comment filtering
        filteredScans = filteredScans.filter((x) => {
            if (this.filterComments === '') return true;
            else if (!x.comment) return false;
            else return x.comment?.includes(this.filterComments);
        });
        // address client side device filtering
        filteredScans = filteredScans.filter((x) => {
            if (this.filterDevice === '') return true;
            else return x.deviceId === this.filterDevice;
        });
        // address client side type filtering
        filteredScans = filteredScans.filter((x: ScanSummary) => {
            if (this.filterType === '') return true;
            else return x.assignmentType === this.filterType;
        });
        // address client side status filtering
        filteredScans = filteredScans.filter((x) => {
            if(this.filterStatus === '') return true;
            else if (this.filterStatus === "Provisional" && !this.scanStatusFinal(x) && this.scanHasPointCloud(x))
                return true;
            else if (this.filterStatus === "Final" && this.scanStatusFinal(x) && this.scanHasPointCloud(x))
                return true;
            else if (this.filterStatus === "Exception" && !this.scanHasPointCloud(x))
                return true;
            else    
                return false;
        });

        // regardless of the filtering, put back any selected scans that have been filtered out
        for(const scanId of this.selectedScanIdList) {
            // scan in the list? and if not, put it back (selectedScanIdList is an observable, so need to ensure it is there.. in case of project changed event)
            if (!filteredScans.find(x => x.id === scanId) && this.summaryScanList.find(x => x.id === scanId)) 
                filteredScans.push(this.summaryScanList.find(x => x.id === scanId))
        }
        const newFilteredScanIds = filteredScans.map(x => x.id)

        if(!arrayEquals(this.filteredScanIdList, newFilteredScanIds)) {

            if(newFilteredScanIds.length === 0)
                this.$store.dispatch('viewer/setFilteredScanIdList', ["noresults"]);
            else if (newFilteredScanIds.length !== this.summaryScanList)
                this.$store.dispatch('viewer/setFilteredScanIdList', newFilteredScanIds);
        }
      }
    },
    removeFilter() {
      if(this.filtered) {
        this.filterComments = '';
        this.filterDevice = '';
        this.filterStatus = '';
        this.filterScanId = '';
        this.filterSessionId = '';
        this.filterDates = [];
        this.filterTags = [];
      }
    },
    changeFilterSelected() {
      if (this.loadedScanList.length > 0 && !this.filterSelected)
        this.$store.dispatch('viewer/setFilterSelected', true);
      else 
        this.$store.dispatch('viewer/setFilterSelected', false);
    },
    changeFilterAnnotations() {
      if (!this.filterAnnotations)
        this.$store.dispatch('viewer/setFilterAnnotations', true);
      else 
        this.$store.dispatch('viewer/setFilterAnnotations', false);
    },
    selectScan(scanId) {
        this.$store.dispatch('viewer/selectScan', scanId)
    },
    unselectScan(scanId) {
        this.$store.dispatch('viewer/unselectScan', scanId)
    },
    unselectAll() {
        this.$store.dispatch('viewer/unselectAllScans')
    },
    selectAll() {
        this.$store.dispatch('viewer/selectManyScans', this.filteredScanIdList)
    }
  },
  watch: { 
    // filter field watches
    filterDevice: function() {
      if (this.filterDevice === '(ALL)') this.filterDevice = '';
      this.applyFilter();  
    },
    filterStatus: function() {
      if (this.filterStatus === '(ALL)') this.filterStatus = '';
      this.applyFilter(); 
    },
    filterType: function () {
        if (this.filterType === '(ALL)') this.filterType = '';
        this.applyFilter();
    },
    filterComments: function() {
        this.applyFilter(); 
    },
    filterScanId: function() {
        this.applyFilter(); 
    },
    filterSessionId: function() {
        this.applyFilter(); 
    },
    filterSelected: function() {
        this.applyFilter(); 
    },
    filterAnnotations: function () {
        this.applyFilter();
    },
    filterTags: function() {
        this.applyFilter(); 
    },
    projectId: function() {
        this.removeFilter();
    },
    filterDates: function() {
        this.applyFilter(); 
    },
  },
};

