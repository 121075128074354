
import { GET_PROJECTS, ADD_PROJECT, UPDATE_PROJECT, DELETE_PROJECT, GET_GEOIDFILES, GET_PROJECTS_BY_ORGID } from '../graphql/project-queries'
import { GET_ORGANIZATION_SUMMARY } from '../graphql/organization-queries'
import { mapGetters } from "vuex";

export default {
  name: 'admin-projects',
  data() {
    return {
      search: '',
      headers: [
          { text: 'Id', value: 'id', groupable: false, },
          { text: 'Project', value: 'name', groupable: false, },
          { text: 'Organization', value: 'organization.name' },
          { text: 'UTM', value: 'utm', groupable: false },
          { text: 'Active', value: 'active' },
          { text: 'Proj4 Projection', value: 'projection', groupable: false },
          { text: 'From', value: 'from', groupable: false },
          { text: 'To', value: 'to', groupable: false },
          { text: 'Actions', value: 'actions', sortable: false, groupable: false,  },
          { text: 'Comments', value: 'data-table-expand', sortable: false, groupable: false,},
        ],
      loadingQueriesCount: 0,
      dialog: false,
      dialogDelete: false,
      selectedTab: 0,
      editedIndex: -1,
      secretFieldType: 'password',
      editedItem: {
        name: '',
        projection: '',
        helmertProjection: '',
        geoidGridFile: '',
        organization: null,
        comments: '', 
        active: true,
        utm: '', 
        helmertParameters: {
            "name": "",
            "Dx": 0,
            "Dy": 0,
            "Dz": 0,
            "Rx": 0,
            "Ry": 0,
            "Rz": 0,
            "M" : 0,
            "d_Dx": 0,
            "d_Dy": 0,
            "d_Dz": 0,
            "d_Rx": 0,
            "d_Ry": 0,
            "d_Rz": 0,
            "d_M": 0,
            "reference_year": 0
        },
      },
      exampleHelmertParameters: {
            "name": "NRCAN_ITRF08_NAD83CSRS",
            "Dx":1.00370,
            "Dy":-1.91111,
            "Dz": -0.54397,
            "Rx":-26.78138 * 1e-3,
            "Ry":  0.42027 * 1e-3,
            "Rz":-10.9320  * 1e-3,
            "M" :  0.38891 * 1e-3,
            "d_Dx":0.00079,
            "d_Dy":-0.00060,
            "d_Dz":-0.00134,
            "d_Rx":-0.06667,
            "d_Ry":0.75744,
            "d_Rz":0.05133,
            "d_M":-0.10201 * 1e-3,
            "reference_year":2010
        },
      defaultItem: {
        name: '',
        projection: '',
        helmertProjection: '',
        geoidGridFile: '',
        organization: null,
        comments: '', 
        active: true,
        helmertParameters: {
            "name": "",
            "Dx": 0,
            "Dy": 0,
            "Dz": 0,
            "Rx": 0,
            "Ry": 0,
            "Rz": 0,
            "M" : 0,
            "d_Dx": 0,
            "d_Dy": 0,
            "d_Dz": 0,
            "d_Rx": 0,
            "d_Ry": 0,
            "d_Rz": 0,
            "d_M": 0,
            "reference_year": 0
        },
        utm: '', 
      },
      isFormValid: false,
      rules: {
        required: v => !!v || 'Value is required',
      }
    }
  },
  apollo: {
    projects: {
      query() { return this.user.userType === 'Admin' ? GET_PROJECTS : GET_PROJECTS_BY_ORGID; },
      loadingKey: "loadingQueriesCount",
      variables(){
        return this.user.userType === 'Admin' ? null : {orgId: Number(this.user.organization.id)}; 
      },
    },
    organizations: {
      query: GET_ORGANIZATION_SUMMARY,
      loadingKey: "loadingQueriesCount",
    },
    geoidGridFiles: {
      query: GET_GEOIDFILES,
      loadingKey: "loadingQueriesCount",
    }
  },
  computed: {
      ...mapGetters(["isPowerUser"]),
      ...mapGetters(["user"]),
      loading() {
          return (this.loadingQueriesCount > 0);
      },
      formTitle () {
        return this.editedIndex === -1 ? 'New Project' : 'Edit Project';
      },
      isEditting() {
          return this.editedIndex !== -1; 
      },
    },
  methods: {
      
      prefillHelmertParameters() {
          this.editedItem.helmertParameters = Object.assign({}, this.exampleHelmertParameters);
      },
      resetHelmertParameters() {
          this.editedItem.helmertParameters = Object.assign({}, this.defaultItem.helmertParameters);
      },
      showOrHideSecret() {
          this.secretFieldType = (this.secretFieldType === 'password') ? '' : 'password';
      },
      onChangeData: function(data) {
        console.log(JSON.stringify(data))
      },
      editItem (item) {
        this.editedIndex = this.projects.indexOf(item)
        this.editedItem = Object.assign({}, item)

        if(!this.editedItem.helmertParameters) 
            this.editedItem.helmertParameters = this.defaultItem.helmertParameters;
        else
            this.editedItem.helmertParameters = JSON.parse(item.helmertParameters);

        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = this.projects.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.$apollo.mutate({
            mutation: DELETE_PROJECT,
            variables: {
                id: Number(this.editedItem.id),
            },
        }).then(() => {
            this.projects.splice(this.editedIndex, 1)
        }).catch((error) => {
            console.log(error);
            this.$snackbar.showMessage(error.message, 'error');
        }).finally(() => {
            this.closeDelete();
        });
      },

      close() {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        })
      },
      closeDelete () {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        })
      },
      save() {
        const utm = String(this.editedItem.utm)
        const check = utm.charAt(utm.length - 1)
        if (check !== 'N' && check !== 'S') {
          return null
        }
        if (this.editedIndex > -1) {
            console.log('editting project');
            let project = this.editedItem;

            project.helmertParameters = JSON.stringify(this.editedItem.helmertParameters);

            this.$apollo.mutate({
                mutation: UPDATE_PROJECT,
                variables: {
                    id: Number(project.id),
                    name: project.name,
                    comments: project.comments,
                    active: project.active,
                    organizationId: Number(project.organization.id),
                    tagOptions: project.tagOptions,
                    projection: project.projection,
                    helmertParameters: project.helmertParameters,
                    geoidGridFile: project.geoidGridFile,
                    utm: JSON.stringify({ utm: project.utm })
                },
            }).then((data) => {
                project = {...project, ...data.data.updateProject}
                Object.assign(this.projects[this.editedIndex], project)
            }).catch((error) => {
                this.$snackbar.showMessage(error.message, 'error');
            }).finally(() => {
                this.close();
            })
        } else {
            console.log('creating new project');
            let project = this.editedItem;
            project.organizationId = this.isPowerUser ? Number(this.user.organization.id) : Number(project.organization.id)
            project.helmertParameters = JSON.stringify(this.editedItem.helmertParameters);

            this.$apollo.mutate({
                mutation: ADD_PROJECT,
                variables: {
                    name: project.name,
                    comments: project.comments,
                    active: project.active,
                    organizationId: project.organizationId,
                    tagOptions: project.tagOptions,
                    projection: project.projection,
                    helmertParameters: project.helmertParameters,
                    geoidGridFile: project.geoidGridFile,
                    utm: project.utm
                },
            }).then((data) => {
                project = {...project, ...data.data.createProject};
                this.projects.push(project);
            }).catch((error) => {
                console.log(error)
                this.$snackbar.showMessage(error.message, 'error');
            }).finally(() => {
                    console.log(this.editedItem)
                    this.close();
            });
        }
      },
    },
  watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
}
