export function formatBytes(bytes: number, precision = 1): string {
  if (Number.isNaN(bytes)) {
    return '?';
  }

  const kb = 1024;
  const mb = 1024*1024;
  const gb = 1024*1024*1024;
  if (bytes < kb) {
      return `${bytes}B`;
  }
  if (bytes < mb) {
      return `${(bytes / kb).toFixed(precision)}KB`;
  }
  if (bytes < gb) {
      return `${(bytes / mb).toFixed(precision)}MB`;
  }
  return `${(bytes / gb).toFixed(precision)}GB`;
}

export function formatDuration(milliseconds: number): string {
  if (Number.isNaN(milliseconds)) {
    return '?';
  }

  const s = Math.floor(milliseconds / 1000);
  if (s < 60) {
    return `${s} second${s === 1 ? '' : 's'}`;
  }
  const min = Math.floor(s / 60);
  if (min < 60) {
    return `${min} minute${min === 1 ? '' : 's'}`;
  }
  const hours = Math.floor(min / 60);
  if (hours < 24) {
    return `${hours} hour${hours === 1 ? '' : 's'}`;
  }
  const days = Math.floor(hours / 24);
  return `${days} day${days === 1 ? '' : 's'}`;
}

export function calculateRate(oldBytes: number, newBytes: number, oldTime: Date, newTime: Date): number {
  const elapsedMs = newTime.getTime() - oldTime.getTime();
  const uploadedBytes = newBytes - oldBytes;
  return elapsedMs > 0 ? uploadedBytes / elapsedMs : 0;
}

export function getEstimatedRemainingTime(startTime: Date, percentComplete: number): number {
  if (percentComplete === 0) {
    return Number.NaN;
  }
  const msSoFar = new Date().getTime() - startTime.getTime();
  const msRemaining = Math.max(0, msSoFar / percentComplete * (100 - percentComplete));

  return msRemaining;
}

export function getEstimatedRemainingTimeDisplay(startTime: Date, percentComplete: number): string {
  const msRemaining = getEstimatedRemainingTime(startTime, percentComplete);
  if (Number.isNaN(msRemaining)) {
    return '?';
  }

  return `${formatDuration(msRemaining)} remaining`;
}

export function getEstimatedRemainingByRate(bytesRemaining: number, bytesPerMs: number): string {
  const msRemaining = bytesPerMs > 0 ? bytesRemaining / bytesPerMs : Number.NaN;
  if (Number.isNaN(msRemaining)) {
    return '?';
  }

  return `${formatDuration(msRemaining)} remaining`;
}
