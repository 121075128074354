import { getSignedUrl } from '../services/utilities';

export const generalUtilMixin = {
    data() {
        return {
          loadingQueriesCount: 0,
        }
    },
    computed: {
        loading() {
          return this.loadingQueriesCount > 0;
        },
    },
    methods: {
        getScanName(item) {
            if (item.friendlyName) return item.friendlyName;
            return item.id;
        },
        async openInNewWindow(url) {
            const signedUrl = await getSignedUrl(url)
            window.open(signedUrl, "_blank");
        }
    }
}

