import Auth from './auth';
import store from '../store/store';

export default {
    async canActivate(to, from, next) {
        return Auth.getAuthenticatedUser()
            .then(() => {
                if(store.getters.user && to.meta && to.meta.userTypes && to.meta.userTypes.length > 0) {
                    // check basic route permissions against usertype
                    if (to.meta.userTypes.includes(store.getters.user.userType)) {
                        //console.log('allow')
                        next();
                    }
                    else if (!store.getters.user || (store.getters.user && !to.meta.userTypes.includes(store.getters.user.userType))) {
                        // requirements not met
                        console.log('User does not have permission to requested resource')
                        //console.log(store.getters.user)
                        //console.log()
                        next({ name: 'Viewer' })
                    }
                } else
                    next();
            })
            .catch((error) => {
                console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
                next({
                    path: '/login',
                    query: {redirect: to.fullPath }
                });
            });
    },
}
