/* eslint-disable */
// Adapted from https://sanaulla.info/2019/04/20/integrating-amazon-cognito-with-single-page-application-vue-js/
import { CognitoAuth, StorageHelper } from 'amazon-cognito-auth-js';
import IndexRouter from '../router/index';
import store from '../store/store';
import axios from 'axios';
import { setApolloAuthToken, removeApolloAuthToken } from './vue-apollo'; 

// configure aws auth
let authConfig = {
    ClientId : process.env.VUE_APP_COGNITO_CLIENT_ID, 
    AppWebDomain : process.env.VUE_APP_COGNITO_APP_DOMAIN,
    TokenScopesArray : ['openid', 'email'],
    RedirectUriSignIn : process.env.VUE_APP_COGNITO_REDIRECT_URI,
    RedirectUriSignOut : process.env.VUE_APP_COGNITO_REDIRECT_URI_SIGNOUT,
    UserPoolId : process.env.VUE_APP_COGNITO_USERPOOL_ID,
}

const awsAuthService = new CognitoAuth(authConfig);

awsAuthService.userhandler = {
    onSuccess() {
        console.log('successful userHandler auth... pushing / to router...');
        IndexRouter.push('/');
    },
    onFailure() {
        console.log('failure to authenticate... removing apollo auth token, setting user to logged out, and redirecting to login');
        //TODO: clean this up, forward to error? 
        removeApolloAuthToken(this.$apollo);
        store.dispatch('setLoggedOut');
        IndexRouter.push('/login');
    }
};

// helper functions
export function getJwtToken() {
    return awsAuthService.getSignInUserSession().getAccessToken().jwtToken;
}

export function getAuthHeader() {
    return {
        headers: {
            'Authorization': 'Bearer '+ getJwtToken()
        }
    } 
}

function getUserInfoStorageKey(){
    let keyPrefix = 'CognitoIdentityServiceProvider.' + awsAuthService.getClientId();
    let tokenUserName = awsAuthService.signInUserSession.getAccessToken().getUsername();
    let userInfoKey = keyPrefix + '.' + tokenUserName + '.userInfo';
    return userInfoKey;
}

async function getAuthenticatedUser() {
    const USERINFO_URL = 'https://'+awsAuthService.getAppWebDomain() + '/oauth2/userInfo';
    return axios.get(USERINFO_URL, getAuthHeader())
        .then((response) => { 
            if(!store.getters.loggedIn) {
                setApolloAuthToken(this.$apollo, getJwtToken())
                    .then(() => {
                        //console.log('user context does not exist, loading')
                        store.dispatch('setLoggedIn', response.data);
                        return store.getters.username;
                    });
            } else {
                //console.log('have user context, getAuthenticatedUser returning');
                return store.getters.username;
            }
        });
}


function parseCognitoWebResponse(url) {
    awsAuthService.parseCognitoWebResponse(url);
}

let storageHelper = new StorageHelper();
let storage = storageHelper.getStorage();

export default {
    login(){
        awsAuthService.getSession();
    },
    logout(){
        store.dispatch('setLoggedOut');
        removeApolloAuthToken(this.$apollo);
        if (awsAuthService.isUserSignedIn()) {
            awsAuthService.signOut();         
            storage.removeItem(getUserInfoStorageKey());
        }
    },
    getAuthenticatedUser: getAuthenticatedUser, 
    parseCognitoWebResponse,
}
