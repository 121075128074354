
function dummyFunction(e) {
    e.returnValue = `This won't get displayed`;
}

export function addWindowCloseBlock() {
    window.addEventListener('beforeunload', dummyFunction)
}

export function removeWindowCloseBlock() {
    window.removeEventListener('beforeunload', dummyFunction)
}

