import { UPSERT_ACTIVITY_LOG_JOB, ADD_ACTIVITY_LOG_EVENT, ACKNOWLEDGE_ACTIVITY_LOG, ACKNOWLEDGE_MULTIPLE_ACTIVITY_LOGS } from "@/graphql/activity-log-queries";
import { apolloClient } from './vue-apollo';
import { formatBytes } from './spacetime-util';

export class ActivityLogStatusEnum {
    static EVENT = 'Event';
    static ERROR = 'Error'; 
    static SUBMITTED = 'Submitted';
    static STARTED = 'Started';
    static COMPLETED = 'Completed';
    static FAILED = 'Failed';
}

export class ActivityLogTypeEnum {
    static USERLOGIN = 'UserLogin';
    static PACKAGEUPLOAD = 'PackageUpload';
}

export class ActivityLogger {

    async logEvent(activityType: string, message = '', payload = '{}', metrics = '{}') {
        apolloClient.mutate({
            mutation: ADD_ACTIVITY_LOG_EVENT,
            variables: { activityType, message, payload, metrics },
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            //pass
        });  
    }

    async upsertLogJob(activityType: string, referenceKey: string, status: string, message = '', logFileUrl = '', payload = '{}', metrics = '{}') {
        apolloClient.mutate({
            mutation: UPSERT_ACTIVITY_LOG_JOB,
            variables: { activityType, referenceKey, status, message, logFileUrl, payload, metrics },
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            //pass
        });  
    }

    async acknowledgeActivityLog(id: number, isAcknowledged: boolean) {
        apolloClient.mutate({
            mutation: ACKNOWLEDGE_ACTIVITY_LOG,
            variables: { id, isAcknowledged },
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            //pass
        });
    }

    async acknowledgeMultipleActivityLogs(activityTypeFilter: string, statusFilter: string, userIdFilter: number, isAcknowledged: boolean) {
        apolloClient.mutate({
            mutation: ACKNOWLEDGE_MULTIPLE_ACTIVITY_LOGS,
            variables: { activityTypeFilter, statusFilter, userIdFilter, isAcknowledged },
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            //pass
        });
    }
}

export class PackageUploadActivityLogger extends ActivityLogger {
    async updatePackageUploadActivityLog(referenceKey: string, status: string, fileName: string, fileSize: number) {
        const metrics = { fileSize };
        await super.upsertLogJob(ActivityLogTypeEnum.PACKAGEUPLOAD,
            referenceKey,
            status,
            `${status} upload of '${fileName}' (${formatBytes(fileSize)}).`,
            '',
            '',
            JSON.stringify(metrics));
    }

    async error(referenceKey: string, errorMessage: string) {
        await super.upsertLogJob(ActivityLogTypeEnum.PACKAGEUPLOAD,
            referenceKey,
            ActivityLogStatusEnum.ERROR,
            (errorMessage && errorMessage.toString) ? errorMessage.toString() : errorMessage,
            '',
            '',
            '');
    }
}
