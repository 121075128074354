
import { mapGetters as rootMapGetters, createNamespacedHelpers } from 'vuex';
import ActivityLogList from '../components/activity-log-list.vue';
import { GET_ACTIVITY_LOGS } from '../graphql/activity-log-queries';
import { GET_EXISTING_PACKAGE_UPLOADS } from '../graphql/package-upload-queries';
import { formatBytes, getEstimatedRemainingByRate } from '../services/spacetime-util';
import { apolloClient } from '../services/vue-apollo';
import { FileUploadState, FileUploadWorkerInfo } from '../models/file-upload-worker-info-model';
import { FileMultipartUploadWorkerInfo } from '../models/file-multipart-upload-worker-info-model';
const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('fileUpload');

export default {
    name: 'package-upload',
    components: {
      ActivityLogList,
    },
    data: () => ({
      tab: null,
      userSelectedFiles: [],
      userConfirmedFiles: [],
      fileUploadPercentage: 0,
      isFormValid: false,
      fileInputRules: [
        files => (files && !files.some(file => !(file.name?.endsWith('.luxlz') || file.name?.endsWith('.luxiz')))) || 'All files must be valid packages from the LuxGear device (.luxlz or .luxiz)',
        files => (files && !files.some(file => file.size === 0)) || 'Files cannot be empty',
        files => (files && files.length > 0) || 'Files to upload cannot be empty',
      ],
      loadingQueriesCount: 0 as number,
      showExistingFileDialog: false,
      showUploadConfigDialog: false,
      uploadErrorsCount: 0,
      maxUploadWorkers: 0,
      multiPartUpload: false,
    }),
    apollo: {
      activityLog: {
        query: GET_ACTIVITY_LOGS,
        loadingKey: 'loadingQueriesCount',
        variables() {
          return {
            pageIndex: 0,
            pageSize: 1, // as small as possible
            sort: '',
            activityTypeFilter: 'PackageUpload',
            statusFilter: 'Error',
            userIdFilter: this.user ? Number(this.user.id) : 0,
            isAcknowledgedFilter: 'false',
          };
        },
        result ({ data /*, loading, networkStatus */ }) {
          if (data && data.activityLog) {
            this.uploadErrorsCount = data.activityLog.total;
          }
        },
      },
    },
    mounted() {
        this.maxUploadWorkers = this.config.numberOfSimultaneousFileUploads;
        this.multiPartUpload = this.config.useMultipart;
    },
    computed: {
      sortedWorkerInfoList(): FileUploadWorkerInfo[] {
        return this.workerInfoList.slice().sort((a: FileUploadWorkerInfo, b: FileUploadWorkerInfo) => {
          const compareByState = FileUploadState.compareStateForDisplay(a.state, b.state);
          if (compareByState !== 0) {
            return compareByState;
          }
          return a.createdAt.getTime() - b.createdAt.getTime();
        });
      },
      countOfAlreadyExistingFiles(): number {
        return this.userConfirmedFiles.filter(f => f.alreadyExists).length;
      },
      countOfAlreadyExistingFilesToUpload(): number {
        return this.userConfirmedFiles.filter(f => f.alreadyExists && f.isConfirmedForUpload).length;
      },
      countOfConfirmedFilesToUpload(): number {
        return this.userConfirmedFiles.filter(f => f.isConfirmedForUpload).length;
      },
      ...rootMapGetters([
        'user',
      ]),
      ...mapGetters([
        'config',
        'workerInfoList',
        'hasQueuedOrActiveUploads',
        'queuedOrActiveUploadCount',
        'overallProgress',
        'overallProgressDisplay',
      ]),
      showUpload() {
        return ![0, 100].includes(this.fileUploadPercentage)
      },
      uploadErrorsColumns() {
        return ['status', 'message', 'submitted', 'started', 'isAcknowledged', 'data-table-expand'];
      },
      uploadHistoryColumns() {
        return ['status', 'message', 'submitted', 'started', 'completed', 'isAcknowledged'];
      },
      refreshKey() {
        return this.overallProgress ? this.overallProgress.toString() : '';
      },
    },
    methods: {
      ...mapActions(['uploadFile']),
      ...mapMutations([
            'setConfig',
      ]),
      updateConfig() {
        this.setConfig({
            numberOfSimultaneousFileUploads: this.maxUploadWorkers,
            useMultipart: this.multiPartUpload,
         });
         this.showUploadConfigDialog = false;
      },
      addDropFile(e) {
        this.userSelectedFiles = Array.from(e.dataTransfer.files);
      },
      confirmUploadFile() {
        //console.log(`checking if ${this.userSelectedFiles.length} selected files already exist on the server...`);
        const filenamesCsv = this.userSelectedFiles.map(f => f.name).join(',');

        apolloClient.query({
          query: GET_EXISTING_PACKAGE_UPLOADS,
          variables: { filenamesCsv },
          fetchPolicy: 'no-cache',
        })
        .then((res) => {
          const existingFiles = res.data.getExistingPackageUploads;
          this.userConfirmedFiles = this.userSelectedFiles.map((selectedFile) => {
            const nameMatchingFile = existingFiles.find(e => e.filename === selectedFile.name);
            if (nameMatchingFile && (nameMatchingFile.size !== selectedFile.size)) {
              // TODO: mike, confirm this logic is sound.. seems to be an edge case here with no logic, just a message.
              console.log(`[WARNING] '${nameMatchingFile.filename}' already exists but is a different size (${formatBytes(nameMatchingFile.size)} existing vs ${formatBytes(selectedFile.size)} new)`);
            }
            return {
              name: selectedFile.name,
              file: selectedFile,
              alreadyExists: !!nameMatchingFile,
              isConfirmedForUpload: !nameMatchingFile || (nameMatchingFile.size !== selectedFile.size),
              uploadedSize: nameMatchingFile ? nameMatchingFile.size : 0
            };
          });
          this.userSelectedFiles = [];

          if (this.userConfirmedFiles.every(f => !f.alreadyExists)) {
            this.startUploadOfConfirmedFiles();
          } else {
            this.showExistingFileDialog = true;
          }
        })
        .catch((ex) => {
          this.$snackbar.showMessage(ex.message, 'error');
        });
      },
      cancelUpload() {
        this.showExistingFileDialog = false;
        this.userConfirmedFiles = [];
      },
      replaceAllExistingFiles() {
        this.userConfirmedFiles.forEach((f) => { f.isConfirmedForUpload = true; });
      },
      skipAllExistingFiles() {
        this.userConfirmedFiles.forEach((f) => { if (f.alreadyExists && (f.file.size === f.uploadedSize)) { f.isConfirmedForUpload = false; } });
      },
      startUploadOfConfirmedFiles() {
        this.tab = 'progress-tab';
        this.$snackbar.showMessage('Do not close your browser while files are uploading. A close-block has been added to warn if you attempt to reload the page or close the tab', 'warning');

        this.showExistingFileDialog = false;
        const filesToUpload = this.userConfirmedFiles.filter(f => f.isConfirmedForUpload);
        //console.log(`Uploading ${filesToUpload.length} files...`);
        filesToUpload.forEach((f) => {
          this.uploadFile({ file: f.file, snackbar: this.$snackbar });
        });
        this.userConfirmedFiles = [];
      },
      formatBytes(bytes: number) {
        return formatBytes(bytes);
      },
      getBytesDisplay(workerInfo: FileUploadWorkerInfo) {
        return `${formatBytes(workerInfo.progressMonitor.uploadedBytes)} / ${formatBytes(workerInfo.progressMonitor.totalBytes)}`;
      },
      getEstimatedRemainingTime(workerInfo: FileUploadWorkerInfo) {
        return getEstimatedRemainingByRate(workerInfo.progressMonitor.totalBytes - workerInfo.progressMonitor.uploadedBytes, workerInfo.progressMonitor.rateCalculation);
      },
      isSinglePartUploadType(uploader) {
        return uploader instanceof FileUploadWorkerInfo;
      },
      isMultiPartUploadType(uploader) {
        return uploader instanceof FileMultipartUploadWorkerInfo;
      },
    },
    watch: {
      hasQueuedOrActiveUploads() {
        if (this.hasQueuedOrActiveUploads) {
          this.$apollo.queries.activityLog.startPolling(10000);
        } else {
          this.$apollo.queries.activityLog.stopPolling();
        }
      },
    },
}
