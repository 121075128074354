
import {
  DELETE_BASESTATION,
  GET_BASESTATIONS_FOR_PROJECT,
  GET_BASESTATION_UPLOAD_URL,
  GET_MATCHED_SESSIONS_FOR_BASESTATION,
} from "../graphql/base-station-queries";
import { generalUtilMixin } from "../mixins/general-util-mixin";
import { mapGetters } from "vuex";
import axios from "axios";
import { LUXCLOUD_REGISTERBASESTATION } from "../graphql/lux-cloud-queries";
import TrajectoryProcessingRunsList from "../components/trajectory-processing-runs-list.vue";
import TrajectoryProcessingRunsSummary from "../components/trajectory-processing-runs-summary.vue";
import { fileUploadMixin } from '../mixins/file-upload-mixin';

export default {
  name: "admin-base-station-files",
  components: {
    TrajectoryProcessingRunsList,
    TrajectoryProcessingRunsSummary,
  },
  mixins: [ generalUtilMixin, fileUploadMixin ],
  data() {
    return {
      search: "",
      basestationId: null,
      uploadDialogLocked: false,
      uploadDialog: false,
      uploadingFile: false,
      isFormValid: false,
      deleteDialog: false,
      infoDialog: false,
      defaultItem: {},
      headers: [
        { text: "Id", value: "id", sortable: false, groupable: false },
        {
          text: "From (UTC)",
          value: "startTimestamp",
          sortable: true,
          groupable: false,
        },
        {
          text: "To (UTC)",
          value: "endTimestamp",
          sortable: true,
          groupable: false,
        },
        { text: "Location", value: "point", sortable: false, groupable: false },
        {
          text: "Status",
          value: "trajectoryProcessingRuns",
          sortable: false,
          groupable: false,
          width: "150px",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          groupable: false,
        },
        {
          text: "Processing Runs",
          value: "data-table-expand",
          sortable: false,
          groupable: false,
        },
      ],
      subheaders: [
        { text: "Id", value: "sessionId", sortable: false, groupable: false },
      ],
    };
  },
  mounted() {
    if (this.$route.params && "search" in this.$route.params)
      this.search = this.$route.params.search;
  },
  apollo: {
    basestations: {
      query: GET_BASESTATIONS_FOR_PROJECT,
      variables() {
        return {
          projectId: Number(this.projectId),
        };
      },
      //skip: () => {return this === null },
      loadingKey: "loadingQueriesCount",
    },
    trajectories: {
      query: GET_MATCHED_SESSIONS_FOR_BASESTATION,
      variables() {
        return {
          basestationId: Number(this.basestationId),
        };
      },
      //skip: () => {return this === null },
      loadingKey: "loadingQueriesCount",
      skip() {
        return !this.basestationId || this.basestationId === 0;
      },
    },
  },
  computed: {
    ...mapGetters("viewer", ["projectId"]),
  },
  methods: {
    handleClick(item /*, row*/) {
      if ("id" in item) {
        this.$router.push({
          name: "TrajectoryProcessing", //use name for router push
          params: { search: item["id"] },
        });
      }
    },
    openInfoDialog(item) {
      this.trajectories = [];
      this.basestationId = item.id;
      this.$apollo.queries.trajectories.refresh();
      this.infoDialog = true;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    deleteItemConfirm(item) {
      this.editedIndex = this.basestations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.deleteDialog = true;
    },
    deleteItem() {
      this.$apollo
        .mutate({
          mutation: DELETE_BASESTATION,
          variables: {
            id: Number(this.editedItem.id),
          },
        })
        .then(() => {
          this.basestations.splice(this.editedIndex, 1);
        })
        .catch((error) => {
          console.log(error);
          this.$snackbar.showMessage(error.message, "error");
        })
        .finally(() => {
          this.closeDeleteDialog();
        });
    },
    uploadFile() {
      this.uploadingFile = true;
      // get the put url for the file to be uploaded
      this.$apollo
        .query({
          query: GET_BASESTATION_UPLOAD_URL,
          variables: {
            filename: this.file.name,
          },
        })
        .then((res) => {
          this.uploadBasestationDialogLocked = true;
          this.addWindowCloseBlock();
          const url = res.data.getBasestationUploadSignedURL.uploadUrl;
          // not used because only the file name is required for basestation uploads
          // const s3Uri = res.data.getBasestationUploadSignedURL.s3Uri;

          const options = {
            headers: {
              "Content-Type": this.file.type,
              "Access-Control-Allow-Origin": "true",
            },
            onUploadProgress: function (progressEvent) {
              this.fileUploadPercentage = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
            }.bind(this),
          };

          axios
            .put(url, this.file, options)
            .then(() => {
              // submit base station processing step to batch
              this.$apollo
                .query({
                  query: LUXCLOUD_REGISTERBASESTATION,
                  variables: {
                    projectId: Number(this.projectId),
                    filename: this.file.name,
                  },
                  fetchPolicy: "network-only",
                })
                .then(() => {
                  this.$snackbar.showMessage(
                    `Successfully submitted ${this.file.name} for processing`,
                    "green"
                  );
                })
                .catch((error) => {
                  this.$snackbar.showMessage(error.message, "error");
                })
                .finally(() => {
                  this.closeUploadDialog();
                  this.removeWindowCloseBlock();
                });
            })
            .catch((error) => {
              this.$snackbar.showMessage(error.message, "error");
            });
        })
        .catch((error) => {
          this.$snackbar.showMessage(error.message, "error");
        })
    },
    closeUploadDialog() {
      this.file = {};
      this.uploadingFile = false;
      this.uploadDialog = false;
    },
  },
};
