import gql from "graphql-tag";

export const LUXCLOUD_REGISTERBASESTATION = gql`
query registerBasestation($projectId: Float!, $filename: String!) {
    registerUploadedBasestationFile
    (
      projectId: $projectId
      filename: $filename
    )
}
`;

export const LUXCLOUD_PROCESS_IMPORTED_SCAN = gql`
query processImportedScan($importedScanId: String!, $s3Url: String!, $determineTrajectory: Boolean!) {
    processImportedScan
    (
      importedScanId: $importedScanId
      s3Url: $s3Url
      determineTrajectory: $determineTrajectory
    )
}
`;

export const LUXCLOUD_PUBLISH_SCANS = gql`
mutation Mutation($publishScan: PublishScans!) {
    publishScans(publishScan: $publishScan)
  }
`;

// export const LUXCLOUD_PDAL = gql`
// query Query($pipeline: String!, $name: String!, $artifactId: Float!) {
//   pdal(pipeline: $pipeline, name: $name, artifactId: $artifactId)
// }
// `;

export const LUXCLOUD_PDAL = gql`
query Query($submission: PdalSubmission!) {
  pdal(submission: $submission)
}
`;


