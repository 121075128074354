
export default {
  name: "commonSnackbar",
  data() {
    return {
      show: false,
      message: "",
      color: "",
      timeout: 8000,
    };
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "showMessage") {
        console.log(state.snackbar.content);
        this.message = state.snackbar.content;
        this.color = state.snackbar.color;
        this.timeout = state.snackbar.timeout;
        this.show = true;
      }
    });
  },
};
