
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('fileUpload');

export default {
    data: () => ({
        isShowingDetails: false,
    }),
    computed: {
        ...mapGetters([
            'workerInfoList',
            'queuedOrActiveUploads',
            'hasQueuedOrActiveUploads',
            'queuedOrActiveUploadCount',
            'overallSizeProgress',
            'overallProgressDisplay',
        ]),
    },
    methods: {
        showDetails() {
            this.isShowingDetails = true;
        },
        ...mapActions(['removeFinishedUploaders']),
    },
    watch: {
        hasQueuedOrActiveUploads() {
            this.removeFinishedUploaders();
        },
    },
}
