
import ScanList from '../components/scan-list.vue';
import MapView from '../components/map-view.vue';
import PointCloudView from '../components/pointcloud-view.vue';
import ImageView from '../components/image-view.vue';
import { createNamespacedHelpers } from 'vuex'
const { mapActions, mapGetters } = createNamespacedHelpers('viewer')

export enum DisplayType {
    MAP = 0,
    POINTCLOUD = 1,
}

export default {
  name: 'scan-viewer',
  components: {
    ScanList,
    MapView,
    PointCloudView,
    ImageView,
  },
  data: () => ({
    display: DisplayType.MAP as DisplayType,
    showSidebar: true,
    sidebarWidth: 700,
  }),
  beforeCreate() {
    // refreshing user context to ensure we have updated project list
    this.$store.dispatch('refreshUserContext');
  },
  methods: {
      ...mapActions([
          'setProjectId',
      ]),
  },
  computed: {
       ...mapGetters([
            'projectId',
            'summaryScanList',
            'selectedScanIdList',
            'loadedScanList',
            'filteredScanIdList', 
            'selectedprojectArtifactIdList',
            'focusedScanId',
            'focusedScan',
            'focusedPoint',
        ]),
      displayMap() {
        return this.display===DisplayType.MAP;
      },
      displayPointCloud() {
        return this.display===DisplayType.POINTCLOUD;
      },
  },
  watch: {
      projectId: function() {
        this.display = DisplayType.MAP;
      },
      showSidebar: function() {
        setTimeout(() => window.dispatchEvent(new Event('resize')), 1);
      },
      display: function() {
        setTimeout(() => window.dispatchEvent(new Event('resize')), 1);
      }
  }
}
