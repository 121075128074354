import store from "../store/store";

const snackbarPlugin = {
  showMessage: (content = "", color = "", timeout = 8000) => {
    store.commit("showMessage", { content, color, timeout }, { root: true });
  },
  install: (Vue, { store }) => {
    if (!store) {
      throw new Error("Please provide vuex store.");
    }
    Vue.prototype.$snackbar = snackbarPlugin;
  }
};

export default snackbarPlugin;