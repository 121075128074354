import gql from "graphql-tag";

export const GET_DEVICE_ASSIGNMENTS = gql`
query {
  deviceAssignments: deviceAssignments 
  {
    id
    device { id }
    project { 
        id, 
        name,
        organization { id, name }
        }
    assignmentType
    from to
  }
}
`;

export const GET_DEVICE_ASSIGNMENTS_FOR_PROJECT = gql`
query($projectId: Float!) {
  deviceAssignments: deviceAssignmentsForProject(projectId: $projectId)
  {
    id
    device { id }
    project { 
        id, 
        name,
        organization { id, name }
        }
    assignmentType
    from to
  }
}
`;

export const ADD_DEVICE_ASSIGNMENTS = gql`
    mutation createDeviceAssignment ($deviceId: String!, $projectId: Float!, $assignmentType: String!, $from: DateTime!, $to: DateTime!) {
      createDeviceAssignment(deviceAssignment: {
          deviceId: $deviceId
          projectId: $projectId
          assignmentType: $assignmentType
          from: $from
		  to: $to
      }) 
      {
        id
        device { id }
        project { 
            id, 
            name,
            organization { id, name }
        }
        assignmentType
        from
        to
    }
}
`;

export const UPDATE_DEVICE_ASSIGNMENT = gql`
    mutation updateDeviceAssignment ($id: ID!, $deviceId: String!, $projectId: Float!, $assignmentType: String!, $from: DateTime!, $to: DateTime!) {
        updateDeviceAssignment(deviceAssignment: 
        {
        id: $id
        deviceId: $deviceId
        projectId: $projectId
        assignmentType: $assignmentType
        from: $from
        to: $to
      }
    ) {
        id
        device { id }
        project { 
            id, 
            name,
            organization { id, name }
        }
        assignmentType
        from
        to
    }
}
`;

export const DELETE_DEVICE_ASSIGNMENT = gql`
mutation deleteDeviceAssignment ($id: Float!) {
    deleteDeviceAssignment(id: $id) 
}
`;