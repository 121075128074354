import gql from 'graphql-tag';

const activityLogJobParameters = `$activityType: String!,
    $referenceKey: String!,
    $status: String!,
    $message: String!,
    $logFileUrl: String!,
    $payload: String!,
    $metrics: String!`;

const activityLogJobInputTypeAssignment = `{
    activityType: $activityType
    referenceKey: $referenceKey
    status: $status
    message: $message
    logFileUrl: $logFileUrl
    payload: $payload
    metrics: $metrics
}`;

const activityLogEventParameters = `$activityType: String!,
    $message: String!,
    $payload: String!,
    $metrics: String!`;

const activityLogEventInputTypeAssignment = `{
    activityType: $activityType
    message: $message
    payload: $payload
    metrics: $metrics
}`;

const activityLogReturnType = `{
    lastModified
    created
    id
    activityType
    referenceKey
    userId
    user {
      id
      name
    }
    status
    message
    logFileUrl
    payload
    metrics
    submitted
    started
    completed
    isAcknowledged
}`;

export const GET_ACTIVITY_LOG = gql`
query activityLog ($id: String!) {	
    activityLog: activityLog(id: $id) ${activityLogReturnType}
}
`;

export const GET_ACTIVITY_LOGS = gql`
query activityLog ($pageIndex: Float!, $pageSize: Float!, $sort: String!, $activityTypeFilter: String!, $statusFilter: String!, $userIdFilter: Float!, $isAcknowledgedFilter: String!) {
    activityLog : activityLogs(pageIndex: $pageIndex, pageSize: $pageSize, sort: $sort, activityTypeFilter: $activityTypeFilter, statusFilter: $statusFilter, userIdFilter: $userIdFilter, isAcknowledgedFilter: $isAcknowledgedFilter)
        {
            items ${activityLogReturnType}
            total
            take
            skip
        }
}
`;

export const ADD_ACTIVITY_LOG_EVENT = gql`
    mutation createActivityLogEvent (${activityLogEventParameters}) {
        createActivityLogEvent(activityLogInput: ${activityLogEventInputTypeAssignment}) ${activityLogReturnType}
    }
`;

export const UPSERT_ACTIVITY_LOG_JOB = gql`
    mutation upsertActivityLogJob (${activityLogJobParameters}) {
        upsertActivityLogJob(activityLogInput: ${activityLogJobInputTypeAssignment}) ${activityLogReturnType}
    }
`;

export const ACKNOWLEDGE_ACTIVITY_LOG = gql`
    mutation acknowledgeActivityLog ($id: Float!, $isAcknowledged: Boolean!) {
        acknowledgeActivityLog(id: $id, isAcknowledged: $isAcknowledged)
        ${activityLogReturnType}
    }
`;

export const ACKNOWLEDGE_MULTIPLE_ACTIVITY_LOGS = gql`
    mutation acknowledgeMultipleActivityLogs ($activityTypeFilter: String!, $statusFilter: String!, $userIdFilter: Float!, $isAcknowledged: Boolean!) {
        acknowledgeMultipleActivityLogs(activityTypeFilter: $activityTypeFilter, statusFilter: $statusFilter, userIdFilter: $userIdFilter, isAcknowledged: $isAcknowledged)
    }
`;

export const ACTIVITY_LOG_TYPES = gql`
query Query {
    activityLogTypes
  }
  `;
  