
import { addWindowCloseBlock, removeWindowCloseBlock } from "../utils/file-upload-utils";

export const fileUploadMixin = {
  data() {
    return {
      file: {},
      fileUploadPercentage: 0,
      uploadingFile: false,
      singleLasFileInputRules: [
        (file) =>
          (file && file.name?.endsWith(".las")) ||
          "files type must be LAS with a .las extension",
        (file) => (file && file.size !== 0) || "File size cannot be empty",
      ],
      singleBasestationFileInputRules: [
        (v) => !!v || "File is required",
        (v) => (v && v.name?.endsWith(".zip")) || "File must be a .zip file",
        (v) => (v && v.size > 0) || "File is required",
        (v) =>
          (v && v.name?.indexOf(" ") === -1) || "Filename cannot have spaces",
      ],
    }
  },
  computed: {
    showUpload() {
      return ![0, 100].includes(this.fileUploadPercentage);
    },
  },
  methods: {
    addOneDropFile(e) {
      this.file = Array.from(e.dataTransfer.files)[0];
    },
    addAllDropFiles(e) {
      this.userSelectedFiles = Array.from(e.dataTransfer.files);
    },
    addWindowCloseBlock() {
      this.$snackbar.showMessage('Do not close your browser while files are uploading. A close-block has been added to warn if you attempt to reload the page or close the tab', 'warning');
      addWindowCloseBlock();
    },
    removeWindowCloseBlock() {
      removeWindowCloseBlock();
    }
  }
}




