import gql from "graphql-tag";

export const GET_ORGANIZATION_SUMMARY = gql`
query {
    organizations
    {
      id
      name
    }
  }
`;