
import { ScanImageSet, Point } from '../models/models';
import { mapGetters, mapActions } from 'vuex'
import { generalUtilMixin } from '../mixins/general-util-mixin';

export default {
  name: "imageView",
  props: ['update'],
  mixins: [ generalUtilMixin ],
  data: () => ({
      centerRotate: 0,
      leftRotate: 0,
      rightRotate: 0,
      sideCameraWidth: 280,
      sideCameraHeight: 400,
  }),
  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters('viewer', [
            'focusedScanId',
            'focusedScan',
            'focusedPoint',
            'focusedImageSet',
            'imageViewVisible',
    ]),
    imageCount() {
        if(this.focusedScan)
            return this.focusedScan.images.length
        else
            return 0
    }
  },
  methods: {
      ...mapActions('viewer', ['showImageView', 'hideImageView']),
      openInNewWindow(url) {
          window.open(url, "_blank");   
      },
      imageRotated(image) {
            return (image && image.jpegRotation === null)
      },
      setOrientationForScan(scan) {
          // on select, scan.images should fail, but a load scan will retrigger when the images are there
          if(scan && scan.metadata) {
              try {
                const metadata = JSON.parse(scan.metadata);

                // rotate images based on the orientation of the device
                if(metadata.device_orientation === 'horizontal' || metadata.vertical_orientation === 'upright') {
                    //console.log('horizontal orientation')
                    this.leftRotate = -0.25
                    this.rightRotate = 0.25
                    this.sideCameraHeight = 300;
                    this.sideCameraWidth = 280;
                } else {
                    //console.log('ground orientation')
                    this.leftRotate = 0
                    this.rightRotate = 0
                    this.sideCameraHeight = 280;
                    this.sideCameraWidth = 300;
                }   

                if(metadata.trajectory_orientation === 'rearward' || metadata.horizontal_orientation === 'rearward') {
                    //console.log('rearward')
                    this.centerRotate = 0.5
                } else {
                    //console.log('forward')
                    this.centerRotate = 0
                }
              } catch(e) {
                console.log(`image-view: error parsing metadata: ${scan.metadata} resulted in: ${e}`)
              }
          }
      },
      nextImageSet() {
          const next = this.focusedScan.images.find(x => x.id === (parseInt(this.focusedImageSet.id) + 1).toString());
          if(next) {
              this.$store.dispatch('viewer/focusImageSet', next)
          }
      },
      prevImageSet() {
          const prev = this.focusedScan.images.find(x => x.id === (parseInt(this.focusedImageSet.id) - 1).toString());
          if(prev) {
              this.$store.dispatch('viewer/focusImageSet', prev)
          }
      },
      findNearestImages(point: Point) {
          if(point) {
            // simple pythagorem's approach, not accounting for projectum or Datum
            let shortest = -1.0;
            let tmpImageSet = null;
            //console.log(`total images to check is ${this.images.length}`)
            for (const imageSet of this.focusedScan.images as ScanImageSet[]) {
                const xdiff = Math.abs(imageSet.X - point.x);
                const ydiff = Math.abs(imageSet.Y - point.y);
                const dist = Math.sqrt((xdiff ** 2) + (ydiff ** 2))
                //console.log(`${imageSet.id}: comparing to x: ${imageSet.x} y: ${imageSet.y}, dist: ${dist}, shortest: ${shortest}`)
                if (shortest === -1 || dist < shortest) {
                    //console.log(`${imageSet.id}: <<SHORTER ${dist - shortest} dist--> ${dist} shortest--> ${shortest}`);
                    shortest = dist;
                    tmpImageSet = imageSet;
                } 
            }
            if(tmpImageSet) {
                this.$store.dispatch('viewer/focusImageSet', tmpImageSet)
            }
          }
      },
  },
  watch: {
    focusedScan: function() {
        //console.log('*** image-view focusedScan')
        if(this.focusedScan === null) {
            this.hideImageView()
        } else {
            this.setOrientationForScan(this.focusedScan);
        }
    },
    focusedPoint: function() {
        //console.log('*** image-view focusedPoint')
        this.findNearestImages(this.focusedPoint);
    },
  }
}

