
import { ADD_PROJECTLAYER, DELETE_PROJECTLAYER, GET_PROJECT_LAYERS_FOR_PROJECT, UPDATE_PROJECTLAYER } from '../graphql/project-layer-queries';
import { escapeSpecialChars } from '../utils/string-utils';
import { mapGetters } from 'vuex';
import { EXAMPLE_LAYERS } from '../contants';
//import axios from 'axios'

export default {
  name: 'admin-layers',
  data() {
    return {
      search: '', 
      headers: [
          { text: 'Id', value: 'id', groupable: false, },
          { text: 'Name', value: 'name', align: 'start', groupable: false},
          { text: 'Comments', value: 'comments', sortable: false, groupable: false,},
          { text: 'Hide', value: 'ignore', groupable: false, sortable: false, },
          { text: 'ShowByDefault', value: 'showByDefault', groupable: false, sortable: false, },
          { text: 'Last Modified', value: 'lastModified', sortable: false, groupable: false,},
          { text: 'Actions', value: 'actions', sortable: false, groupable: false,  },
        ],
      loadingQueriesCount: 0,
      dialog: false,
      dialogDelete: false,
      selectedTab: 0,
      editedIndex: -1,
      editedItem: {
        name: '',
        source: {},
        layers: [],
        comments: '', 
        showByDefault: true,
        ignore: false,
      },
      defaultItem: {
        name: '',
        source: {},
        layers: [],
        comments: '', 
        showByDefault: true,
        ignore: false,
      },
      project: 0,
      isFormValid: false,
      exampleLayer: null,
      exampleLayers: EXAMPLE_LAYERS,
      rules: {
        required: v => !!v || 'Value is required',
      },
      options: {
            mode: 'code',
            onValidate: function (json) {
                const errors = [];
                if (json && json.entries) {
                    for(const [i, layer] of json.entries()) {
                        if(layer.id) {
                            errors.push({
                                path: [`[${i}].id`],
                                message: 'Do not include ID; it will be dynamically created when used'
                            });
                        }
                        if(layer.source) {
                            errors.push({
                                path: [`[${i}].source`],
                                message: 'Do not include source; it will be dynamically created when used'
                            });
                        }
                    }
                
                }
                return errors;
            },
      },
    }
  },
  apollo: {
    projectLayers: {
      query: GET_PROJECT_LAYERS_FOR_PROJECT,
      loadingKey: "loadingQueriesCount",
      variables() {
        return {
          projectId: Number(this.projectId),
        };
      },
      update (data) {
        return data.projectLayers.sort( (a, b) => a.name.localeCompare(b.name));
      },
    },
  },
  computed: {
      ...mapGetters("viewer", ["projectId"]),
      loading() {
          return (this.loadingQueriesCount > 0)
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Create New Layer' : 'Edit Layer'
      },
      isEditting() {
          return this.editedIndex !== -1
      },
      showUpload() {
          return ![0, 100].includes(this.fileUploadPercentage)
      },
  },
  methods: {
    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    },
    editItem (item) {
        this.editedIndex = this.projectLayers.indexOf(item)

        // ensure the item has validate json (could be null)
        if(!this.isJson(item.source)) item.source = "{}";
        if(!this.isJson(item.layers)) item.layers = "[]";     
        this.editedItem = Object.assign(this.defaultItem, item)

        this.editedItem.layers = JSON.parse(item.layers);
        this.editedItem.source = JSON.parse(item.source);
        this.dialog = true
    },
    deleteItem (item) {
        this.editedIndex = this.projectLayers.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
    },
    deleteItemConfirm () {
        this.$apollo.mutate({
            mutation: DELETE_PROJECTLAYER,
            variables: {
                id: Number(this.editedItem.id),
            },
        }).then(() => {
            this.projectLayers.splice(this.editedIndex, 1)
        }).catch((error) => {
            console.log(error)
            this.$snackbar.showMessage(error.message, 'error');
        }).finally(() => {
            this.closeDelete();
        });
    },
    close () {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        })
    },
    closeDelete () {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        })
    },
    save () {
        if (this.editedIndex > -1) {
            //console.log('editting project layer');
            let projectLayer = this.editedItem;

            projectLayer.layers = JSON.stringify(this.editedItem.layers);
            projectLayer.source = escapeSpecialChars(JSON.stringify(this.editedItem.source));

            this.$apollo.mutate({
                mutation: UPDATE_PROJECTLAYER,
                variables: {
                    id: Number(projectLayer.id),
                    name: projectLayer.name,
                    comments: projectLayer.comments,
                    source: projectLayer.source,
                    layers: projectLayer.layers,
                    ignore: projectLayer.ignore,
                    showByDefault: projectLayer.showByDefault,
                    projectId: Number(this.projectId),
                },
            }).then((data) => {
                projectLayer = {...projectLayer, ...data.data.updateProjectLayer}
                Object.assign(this.projectLayers[this.editedIndex], projectLayer)
            }).catch((error) => {
                this.$snackbar.showMessage(error.message, 'error');
                console.log(error)
            }).finally(() => {
                this.close();
            })
        } else {
            //console.log('creating new project layer');
            let projectLayer = this.editedItem;

            projectLayer.layers = JSON.stringify(this.editedItem.layers);
            projectLayer.source = escapeSpecialChars(JSON.stringify(this.editedItem.source));
            
            this.$apollo.mutate({
                mutation: ADD_PROJECTLAYER,
                variables: {
                    name: projectLayer.name,
                    comments: projectLayer.comments,
                    source: projectLayer.source,
                    layers: projectLayer.layers,
                    ignore: projectLayer.ignore,
                    showByDefault: projectLayer.showByDefault,
                    projectId: Number(this.projectId),
                },
            }).then((data) => {
                projectLayer = {...projectLayer, ...data.data.createProjectLayer};
                this.projectLayers.push(projectLayer);      
            }).catch((error) => {
                this.$snackbar.showMessage(error.message, 'error');
                console.log(error)
            }).finally(() => {
                this.close();
            })
        }
    },
  },
  watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      exampleLayer (val) {
        if(val && Array.isArray(val))
            for(const entry of val) this.editedItem.layers.push(entry)
        this.$nextTick(() => {this.exampleLayer = null});
      }
    },
}
