
import { generalUtilMixin } from '../mixins/general-util-mixin';
import { mapGetters } from 'vuex'

export default {
    props: {
    },
    name: "publish-scan",
    mixins: [generalUtilMixin],
    data(){
        return{
            dialog: true, 
            isFormValid: false,
            publishScans: {
                bucketId: null,
                targetFolder: '',
                includeImages: true,
                transform: true,
            },
            rules: {
                required: v => !!v || 'Value is required',
            }
        };
    },
    computed: {
        ...mapGetters(['isLoading']),
        ...mapGetters(['user']),
        ...mapGetters('viewer', [
                'summaryScanList',
                'projectLayerList',
                'selectedScanIdList',
                'loadedScanList',
                'filteredScanIdList', 
                'selectedProjectLayerIdList',
                'visibleScanIdList',
                'isScanSelected',
                'project',
                'defaultScanTags',
                'someScanSelected',
                'isScanLoaded',
                'isScanVisible',
                'filteredSummaryScanList',
                'isProjectLayerSelected',
                'projectId',
                'focusedScanId',
        ]),
        bulk() {
            return !this.focusedScanId;
        },
    },
    methods: {
        hasBuckets() {
            if (this.user.organization.buckets.length > 0) {
                return true
            } else {
                return false
            }
        },
        done() {
            this.$emit('finished', null);
        },
        publish() {
            this.$store.dispatch('viewer/publishSelected', this.publishScans);
            this.done();
        },
    },
};
