
import { generalUtilMixin } from "../mixins/general-util-mixin";
import { getSignedUrl } from "../services/utilities";
import axios from "axios";

export default {
  name: "TrajectoryProcessingRunsList",
  mixins: [ generalUtilMixin ],
  props: {
    trajectoryProcessingRuns: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      dialogLogFile: false,
      dialogLogFileTitle: "",
      logFile: null,
      dialogPlots: false,
      dialogPlotsTitle: "",
      numSatsS3Url: "",
      altitudeSeparationsS3Url: "",
      ambStatusS3Url: "",
      distanceSeparationsS3Url: "",
      header: [
        //{ text: 'Id', value: 'id', groupable: false,},
        {
          text: "Session",
          value: "sessionId",
          sortable: true,
          groupable: true,
        },
        {
          text: "Basestations",
          value: "basestationIds",
          sortable: true,
          groupable: false,
        },
        { text: "Status", value: "status", sortable: false, groupable: false },
        {
          text: "Submitted",
          value: "submitted",
          sortable: true,
          groupable: false,
        },
        {
          text: "Started",
          value: "started",
          sortable: true,
          groupable: false,
        },
        {
          text: "Completed",
          value: "completed",
          sortable: true,
          groupable: false,
        },
        { text: "Log", value: "logS3Url", sortable: false, groupable: false },
        {
          text: "Plots",
          value: "numSatsS3Url",
          sortable: false,
          groupable: false,
        },
        {
          text: "Audit",
          value: "trajectoryAuditS3Url",
          sortable: false,
          groupable: false,
        },
      ],
    };
  },
  computed: {
    splitLogFile() {
      return this.logFile.split("\n");
    },
  },
  methods: {
    async showLogFile(logFileUrl, title = "") {
      const signedUrl = await getSignedUrl(logFileUrl);
      this.logFile = (await axios.get(signedUrl)).data;
      this.dialogLogFileTitle = title;
      this.dialogLogFile = true;
    },
    async showPlots(item, title = "") {
      this.numSatsS3Url = await getSignedUrl(item.numSatsS3Url);
      this.altitudeSeparationsS3Url = await getSignedUrl(
        item.altitudeSeparationsS3Url
      );
      this.ambStatusS3Url = await getSignedUrl(item.ambStatusS3Url);
      this.distanceSeparationsS3Url = await getSignedUrl(
        item.distanceSeparationsS3Url
      );
      this.trajectoryAuditS3Url = await getSignedUrl(item.trajectoryAuditS3Url);
      this.dialogPlotsTitle = title;
      this.dialogPlots = true;
    },
  },
};
