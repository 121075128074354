
import { mapGetters, mapActions } from "vuex";
import AppSnackBar from "./components/common-snackbar.vue";
import IdleOverlayView from "./components/idle-overlay-view.vue";
import FileUploadMonitor from "./components/file-upload-monitor.vue";

export default {
  name: "App",
  components: {
    AppSnackBar,
    IdleOverlayView,
    FileUploadMonitor,
  },
  data() {
    return {
      sidebarMenu: false,
      toggleMini: true,
      selectedProject: -1 as number,
    };
  },
  methods: {
    ...mapActions("viewer", ["setProjectId"]),
    getProjectName(item) {
      if (item)
        if (item.organization) return `${item.organization.name} : ${item.name}`;
        else return `${item.name}`;
      else return "";
    },
  },
  mounted() {
    this.$store.dispatch("viewer/setAnnotations");
  },
  computed: {
    ...mapGetters(["isLoading", "loading", "loggedIn", "user"]),
    items() {
      return this.$router.options.routes.filter((x) => x.meta?.menu === true);
    },
    currentRouteName() {
      return this.$route.name;
    },
    menuItems() {
      return this.items.filter(
        (x) =>
          !("userTypes" in x.meta) ||
          x.meta.userTypes.length === 0 ||
          x.meta.userTypes.includes(this.user.userType)
      );
    },
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
    },
    availableProjects(): string[] {
      if (!this.loggedIn || !this.user.organization.projects) return [];
      return [...this.user.organization?.projects].sort(
        (a, b) =>
          a.organization?.name.localeCompare(b.organization?.name) ||
          a.name.localeCompare(b.name)
      );
    },
  },
  watch: {
    selectedProject: function () {
      this.setProjectId(this.selectedProject);
      this.$store.dispatch("viewer/resetSelectedAnnotationsList");
    },
  },
};
