import { render, staticRenderFns } from "./admin-bulk-processing.vue?vue&type=template&id=74530038&lang=pug&"
import script from "./admin-bulk-processing.vue?vue&type=script&lang=ts&"
export * from "./admin-bulk-processing.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports