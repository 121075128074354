
import { mapGetters } from "vuex";
import { GET_PIPELINE_BY_PROJECT } from "../graphql/pipeline-queries";

export default {
  name: "pdal-submission",
  components: {},
  props: {
    processOption: null,
  },
  data: () => ({
    dialog: true,
    isFormValid: false,
    advanced: false,
    pipelinejson: {},
    selectedPipeline: null,
    versionName: "",
    version: null,
    pipelines: [],
    loadingQueriesCount: 0 as number,
    rules: {
      required: (v) => !!v || "Value is required",
    },
    options: {
      mode: "code",
    },
  }),
  apollo: {
    pipelines: {
      query: GET_PIPELINE_BY_PROJECT,
      loadingKey: "loadingQueriesCount",
      variables() {
        return {
          projectId: Number(this.projectId),
        };
      },
    },
  },
  computed: {
    ...mapGetters("viewer", [
      "summaryScanList",
      "projectLayerList",
      "selectedScanIdList",
      "loadedScanList",
      "filteredScanIdList",
      "selectedProjectLayerIdList",
      "visibleScanIdList",
      "isScanSelected",
      "project",
      "defaultScanTags",
      "someScanSelected",
      "isScanLoaded",
      "isScanVisible",
      "filteredSummaryScanList",
      "isProjectLayerSelected",
      "projectId",
      "focusedScanId",
      "focusedVersion",
      "boundingBoxes",
    ]),
    pipelinesList() {
      // support 3D Bounding Box?
      let result = this.pipelines;
      if (this.boundingBoxes.maxx === null) {
        // remove the pipelines which need bounding boxes
        result = this.pipelines?.filter((x) =>
          !x.dependencies.includes("3D Bounding Box")
        );
      }
      return result;
    },
    advancedOk() {
      return this.pipeline !== null;
    },
    bulk() {
      if (this.focusedScanId) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    processPDAL() {
      this.$store.dispatch("viewer/processPDALSelected", {
        version: this.focusedVersion,
        pipeline: this.pipelinejson,
        versionName: this.versionName,
      });
      this.done();
    },
    done() {
      this.$emit("finished", null);
    },
  },
  watch: {
    selectedPipeline() {
      let pipelinejson = this.selectedPipeline.pipeline;
      if (this.selectedPipeline.dependencies.includes("3D Bounding Box")) {
        
        pipelinejson = this.selectedPipeline.pipeline
          .replaceAll(`<%xmin%>`, this.boundingBoxes.minx.toString())
          .replaceAll(`<%ymin%>`, this.boundingBoxes.miny.toString())
          .replaceAll(`<%zmin%>`, this.boundingBoxes.minz.toString())
          .replaceAll(`<%xmax%>`, this.boundingBoxes.maxx.toString())
          .replaceAll(`<%ymax%>`, this.boundingBoxes.maxy.toString())
          .replaceAll(`<%zmax%>`, this.boundingBoxes.maxz.toString());
      }
      this.pipelinejson = JSON.parse(pipelinejson);
    },
  },
};
