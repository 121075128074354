
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    inactivityCountdown: 10,
    countdownInterval: undefined,
  }),
  computed: {
    ...mapGetters({
      loggedIn: 'loggedIn',
      hasQueuedOrActiveUploads: 'fileUpload/hasQueuedOrActiveUploads',
    }),
  },
  onActive() {
    if (!this.loggedIn) { return; }

    clearInterval(this.countdownInterval);
    //console.log('Idle overlay countdown is stopped');
  },
  onIdle() {
    if (!this.loggedIn) { return; }

    this.inactivityCountdown = 10;
    this.countdownInterval = setInterval(() => {
      if (!this.hasQueuedOrActiveUploads) {
        this.inactivityCountdown -= 1;
        if (this.inactivityCountdown === 0) {
          clearInterval(this.countdownInterval);
          //console.log('Redirecting to logout due to inactivity...');
          this.$router.push({ name: 'Logout' });
        }
      }
    },
    1000);

    //console.log('Idle overlay countdown is started...');
  },
}
