import gql from "graphql-tag";

// used by admin
export const GET_PROJECTS = gql`
query {
    projects 
    {
        id 
        name 
        comments 
        tagOptions
        projection 
        helmertParameters
        geoidGridFile
        active
        utm
        from 
        to
        organization { id name }
    }
}
`;

// used by everyone else
export const GET_PROJECTS_BY_ORGID = gql`
query ($orgId: Float!){
    projects: projectsByOrgId(orgId: $orgId)
    {
        id 
        name 
        comments 
        tagOptions
        projection 
        helmertParameters
        geoidGridFile
        active
        utm
        from 
        to
        organization { id name }
    }
}
`;

export const GET_PROJECT_SUMMARY = gql`
query {
    projects 
    {
        id 
        name 
        active
        organization { id name }
    }
}
`;

export const GET_PROJECT = gql`
query ($projectId: Float!) {
  project(id: $projectId) {
    created
    lastModified
    id
    name
    comments
    active
    tagOptions
    projection
    helmertParameters
    geoidGridFile
    classifiers
    organization {
      id
      name
      buckets { id name }
    }
    from
    to
  }
}
`;


export const ADD_PROJECT = gql`
    mutation createProject (
        $name: String!, 
        $active: Boolean!, 
        $comments: String!, 
        $tagOptions: [String!],
        $projection: String, 
        $helmertParameters: String, 
        $geoidGridFile: String,
        $organizationId: Float!
        $utm: String!) {
      createProject(project: 
        {
        name: $name
        comments: $comments
        active: $active
        organizationId: $organizationId
        tagOptions: $tagOptions
        projection: $projection
        helmertParameters: $helmertParameters
        geoidGridFile: $geoidGridFile
        classifiers: "none"
        utm: $utm
      }
    ) {
        id 
        name 
        comments 
        tagOptions
        projection 
        helmertParameters
        geoidGridFile
        active
        from 
        to
        organization { id name }
        utm
    }
}
`;

export const UPDATE_PROJECT = gql`
    mutation updateProject (
        $id: ID!, 
        $name: String!, 
        $active: Boolean!, 
        $comments: String!, 
        $tagOptions: [String!],
        $projection: String, 
        $helmertParameters: String, 
        $geoidGridFile: String,
        $organizationId: Float!
        $utm: String!) {
      updateProject(project: 
        {
        id: $id
        name: $name
        comments: $comments
        active: $active
        organizationId: $organizationId
        tagOptions: $tagOptions
        projection: $projection
        helmertParameters: $helmertParameters
        geoidGridFile: $geoidGridFile
        classifiers: "none"
        utm: $utm
      }
    ) {
        id 
        name 
        comments 
        tagOptions
        projection 
        helmertParameters
        geoidGridFile
        active
        from 
        to
        organization { id name }
        utm
    }
}
`;

export const DELETE_PROJECT = gql`
mutation deleteProject ($id: Float!) {
    deleteProject(id: $id) 
}
`;

export const GET_GEOIDFILES = gql`
query {
  geoidGridFiles: getGeoidGridFiles
}
`;