import { FileUploadWorkerInfo } from '../models/file-upload-worker-info-model';
import { FileMultipartUploadWorkerInfo } from '../models/file-multipart-upload-worker-info-model';
import { PackageUploadActivityLogger } from '../services/activity-logger';
import { getEstimatedRemainingByRate, formatBytes, calculateRate } from '../services/spacetime-util';

function dummyFunction(e) {
    e.returnValue = `This won't get displayed`;
}

const fileUploadStoreModule = {
    namespaced: true,
    state: {
        workerInfoList: [],
        uploading: false,
        rateCalculationStartTime: null,
        rateCalculationLastSize: 0,
        rateCalculation: 0, // bytes / ms
        rateCalculationInterval: null,
        config: {
            numberOfSimultaneousFileUploads: 3,
            useMultipart: true,
        },
    },
    mutations: {
        addUploadWorker (state, newWorker: FileUploadWorkerInfo) {
            state.workerInfoList.push(newWorker);
        },
        removeFinishedUploaders (state) {
            state.workerInfoList = state.workerInfoList.filter(w => !w.state.hasStarted || w.state.isUploading);
        },
        startUploading(state) {
            if(!state.uploading) {
                // routing to check progress
                state.rateCalculationInterval = setInterval(() => {
                    const uploadedSize = state.workerInfoList.reduce((runningSum: number, worker: FileUploadWorkerInfo) => runningSum + worker.progressMonitor.uploadedBytes, 0);
                    const currentTime = new Date();
                    state.rateCalculation = calculateRate(state.rateCalculationLastSize, uploadedSize, state.rateCalculationStartTime, currentTime);
                    state.rateCalculationStartTime = currentTime;
                    state.rateCalculationLastSize = uploadedSize;
                }, 5000);
                state.rateCalculationStartTime = new Date();
                state.rateCalculationLastSize = 0;
                state.uploading = true;
                //console.log('adding browser close block listener')
                window.addEventListener('beforeunload', dummyFunction)
            }
        },
        completeUploading(state) {
            if(state.workerInfoList.length === 0) {
                //console.log('removing browser close block listener')
                window.removeEventListener('beforeunload', dummyFunction)
                state.uploading = false;
                clearInterval(state.rateCalculationInterval);
            }
        },
        setConfig(state, newValue) {
            state.config = {
                ...state.config,
                ...newValue,
            };
            console.log(`file upload config has been updated to: ${JSON.stringify(state.config)}`);
        },
    },
    actions: {
        uploadFile({ state, commit, dispatch }, params) {
            commit('startUploading');
            const activityLogger = new PackageUploadActivityLogger();
            const newWorker = state.config.useMultipart
                ? new FileMultipartUploadWorkerInfo(params.file)
                : new FileUploadWorkerInfo(params.file);
            newWorker.onStarted = (message) => {
                params.snackbar.showMessage(message, 'green', 2000);
                activityLogger.updatePackageUploadActivityLog(params.file.name, 'Started', params.file.name, params.file.size);
            };
            newWorker.onComplete = () => {
                params.snackbar.showMessage(`Completed upload of '${newWorker.name}'`, 'green', 2000);
                dispatch('startNextUploadWorker');
            };
            newWorker.onError = (e) => {
                console.log('ERROR: Line ', e.lineno, ' in ', e.filename, ': ', e.message);
                params.snackbar.showMessage(e.message, 'error');
                activityLogger.error(params.file.name, e.message);
                dispatch('startNextUploadWorker');
            };
            commit('addUploadWorker', newWorker);
            dispatch('startNextUploadWorker');
        },
        startNextUploadWorker({ state }) {
            const currentlyUploadingCount = state.workerInfoList.filter(w => w.state.isStarting || w.state.isUploading).length;
            const uploadCapacity = state.config.numberOfSimultaneousFileUploads - currentlyUploadingCount;
            if (uploadCapacity > 0) {
                const workersToUpload = state.workerInfoList.filter(w => !w.state.hasStarted && !w.state.isStarting).slice(0, uploadCapacity);
                workersToUpload.forEach(function(nextWorker) {
                    nextWorker.state.setStarting();
                    console.log(`Starting upload of '${nextWorker.name}'...`);
                    nextWorker.startUpload();
                });
            }
        },
        removeFinishedUploaders({ commit, dispatch }) {
            commit('removeFinishedUploaders');
            dispatch('startNextUploadWorker');
            if (!this.hasQueuedOrActiveUploads) {
                commit('completeUploading');
            }
        },
    },
    getters: {
        config: state => state.config,
        workerInfoList: state => state.workerInfoList,
        overallProgress: (state) => {
            if (state.workerInfoList.length > 0) {
                const totalProgress = state.workerInfoList.reduce((runningSum, worker) => runningSum + worker.progressMonitor.progress, 0);
                return totalProgress / state.workerInfoList.length;
            }
            return 100;
        },
        overallSizeTotal: (state) => {
            const totalSize = state.workerInfoList.reduce((runningSum, worker) => runningSum + worker.progressMonitor.totalBytes, 0);
            return totalSize;
        },
        overallSizeUploaded: (state) => {
            const uploadedSize = state.workerInfoList.reduce((runningSum, worker) => runningSum + worker.progressMonitor.uploadedBytes, 0);
            return uploadedSize;
        },
        overallSizeProgress: (state, getters) => {
            return (getters.overallSizeTotal > 0)
                ? (getters.overallSizeUploaded / getters.overallSizeTotal * 100)
                : 0;
        },
        overallProgressDisplay: (state, getters) => {
            const uploadedSize = formatBytes(getters.overallSizeUploaded);
            const totalSize = formatBytes(getters.overallSizeTotal);
            const timeRemaining = getEstimatedRemainingByRate(getters.overallSizeTotal - getters.overallSizeUploaded, state.rateCalculation);
            return `${uploadedSize} / ${totalSize} uploaded, estimated ${timeRemaining}`;
        },
        isUploading: state => state.workerInfoList.some(w => w.state.isUploading),
        queuedOrActiveUploads: state => state.workerInfoList.filter(w => !w.state.hasStarted || w.state.isUploading),
        queuedOrActiveUploadCount: (state, getters) => getters.queuedOrActiveUploads.length,
        hasQueuedOrActiveUploads: (state, getters) => getters.queuedOrActiveUploads.length > 0,
    },
};

export default fileUploadStoreModule;
