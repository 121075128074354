
import { GET_ORGS, ADD_ORG, UPDATE_ORG, DELETE_ORG } from '../graphql/org-queries'
import { GET_DEVICES } from '../graphql/device-queries';

export default {
  name: 'admin-organizations',
  data() {
    return {
      search: '',
      headers: [
          { text: 'Id', value: 'id', groupable: false,},
          { text: 'Organization', align: 'start', value: 'name', groupable: false, },
          { text: 'Owned Devices', value: 'devices', sortable: false, groupable: false, },
          { text: 'Actions', value: 'actions', sortable: false, groupable: false,  },
        ],
      loadingQueriesCount: 0,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        name: '',
        devices: null,
      },
      defaultItem: {
        name: '',
        devices: null,
      },
      isFormValid: false,
      rules: {
        required: v => !!v || 'Value is required',
      }
    }
  },
  apollo: {
    organizations: {
      query: GET_ORGS,
      loadingKey: "loadingQueriesCount",
    },
    devices: {
      query: GET_DEVICES,
      loadingKey: "loadingQueriesCount",
    }
  },
  computed: {
      loading() {
          return (this.loadingQueriesCount > 0)
      },
      formTitle () {
        return this.editedIndex === -1 ? 'New Organization' : 'Edit Organization'
      },
      isEditting() {
          return this.editedIndex !== -1
      },
    },
  methods: {
      editItem (item) {
        this.editedIndex = this.organizations.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.organizations.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.$apollo.mutate({
            mutation: DELETE_ORG,
            variables: {
                id: Number(this.editedItem.id),
            },
        }).then(() => {
            this.projects.splice(this.editedIndex, 1)
        }).catch((error) => {
            console.log(error)
            this.$snackbar.showMessage(error.message, 'error');
        }).finally(() => {
            this.closeDelete();
        });
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
            //console.log('editting org');
            let org = this.editedItem;
            this.$apollo.mutate({
                mutation: UPDATE_ORG,
                variables: {
                    id: Number(org.id),
                    name: org.name,
                    deviceIds: org.devices.map(x => x.id)
                },
            }).then((data) => {
                org = {...org, ...data.data.updateOrganization}
                Object.assign(this.organizations[this.editedIndex], org)
            }).catch((error) => {
                console.log(error)
                this.$snackbar.showMessage(error.message, 'error');
            }).finally(() => {
                this.close();
            });
        } else {
            //console.log('creating new org');
            let org = this.editedItem;

            this.$apollo.mutate({
                mutation: ADD_ORG,
                variables: {
                    name: org.name,
                    deviceIds: org.devices ? org.devices.map(x => x.id) : [],
                },
            }).then((data) => {
                org = {...org, ...data.data.createOrganization}
                this.organizations.push(org)
            }).catch((error) => {
                console.log(error)
                this.$snackbar.showMessage(error.message, 'error');
            }).finally(() => {
                this.close();
            });
        }
      },
    },
  watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
}
