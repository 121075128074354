import gql from "graphql-tag";

export const GET_PACKAGE_UPLOAD_URL = gql`
query preSignedURL ($filename: String!) {
  getPackageUploadSignedURL(filename:$filename) 
}
`;

export const GET_PACKAGE_UPLOAD_MULTIPART_INFO = gql`
query getPackageUploadMultipartInfo($filename: String!, $numberOfParts: Float!) {
  getPackageUploadMultipartInfo(filename: $filename, numberOfParts: $numberOfParts) {
    filename
    uploadId
    parts {
      eTag
      partNumber
      signedUrl
    }
  }
}
`;

export const COMPLETE_PACKAGE_UPLOAD_MULTIPART = gql`
  mutation completePackageUploadMultipart($filename: String!, $uploadId: String!, $eTags: String!) {
    completePackageUploadMultipart(filename: $filename, uploadId: $uploadId, eTags: $eTags)
  }
`;

export const GET_EXISTING_PACKAGE_UPLOADS = gql`
  query getExistingPackageUploads ($filenamesCsv: String!) {
    getExistingPackageUploads(filenamesCsv: $filenamesCsv) {
      filename
      size
    }
  }
`;
