
import { Scan } from '../models/models';
import { mapGetters } from 'vuex'
import ScanFilter from '../components/scan-filter.vue';
import { generalUtilMixin } from '../mixins/general-util-mixin';
import ProcessPDAL from '../components/process-pdal.vue';
import PublishScan from '../components/publish-scan.vue'; 

export default {
  name: "admin-bulk-processing",
  components: {
    ScanFilter,
    ProcessPDAL,
    PublishScan
  },
  mixins: [generalUtilMixin],
  data() {
    return {
      scansAllSelected:false, 
      allSelected: false, 
      dialog: false,
      processingComponent: null,
      items: ['Process', 'Publish'],
      search: "",
      sortColumn:'capturedTimestamp',
      sortDescending:true,
      headers: [
        { text: 'Select', value: "select", groupable: false},
        { text: "Scan", value: "id", groupable: false },
        { text: "Session", value: "sessionId", groupable: true },
        { text: "Captured", value: "capturedTimestamp", groupable: false}, 
        { text: "Location", value: "friendlyLocationName", groupable: false },
        { text: "Tags", value: "tags", groupable: false, width: "175px" },
        { text: "Comments", value: "comment", groupable: false },
      ],
      loadingQueriesCount: 0,
    };
  },
  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters(['user']),
    ...mapGetters('viewer', [
            'summaryScanList',
            'projectLayerList',
            'selectedScanIdList',
            'loadedScanList',
            'filteredScanIdList', 
            'selectedProjectLayerIdList',
            'visibleScanIdList',
            'isScanSelected',
            'project',
            'defaultScanTags',
            'someScanSelected',
            'isScanLoaded',
            'isScanVisible',
            'filteredSummaryScanList',
            'isProjectLayerSelected',
            'projectId',
            'focusedScanId',
            'focusScanId'
    ]),
  },
  mounted: function(){
    this.$store.dispatch('viewer/focusScanId', null); 
    this.$store.dispatch('viewer/setAutoFocus', false);
    this.isAllSelected();
  },
  methods: { 
    isAllSelected() {
      if(this.selectedScanIdList.length > 0 && this.selectedScanIdList.length === this.filteredSummaryScanList.length) {
        this.allSelected = true
      } else {
        this.allSelected = false
      }
    },
    selectAll() {
      for (const item of this.filteredSummaryScanList) {
        this.selectScan(item.id)
      }
      this.allSelected = true
    },
    unselectAll() {
      for (const item of this.filteredSummaryScanList) {
        this.unselectScan(item.id)
      }
      this.allSelected = false
    },
    openProcessOption(item){
        if(item === 'Process')
        this.processingComponent = 'ProcessPDAL';
        else
          this.processingComponent = 'PublishScan';
          if (this.user.organization.buckets.length === 0) {
            this.$snackbar.showMessage(
              `No Buckets Associated with This Organization`,
              "red")
          }
        this.dialog = true;
    },
    closeProcessOption() {
        this.dialog = false
    },
    selectPdalOrPublish(item){
      if(item === 'Process'){
        this.pdalSelector = true; 
        this.publishSelector = false;
      }else{
        this.publishSelector = true; 
        this.pdalSelector = false; 
      }
    },
    selectScan(scanId) {
      this.$store.dispatch('viewer/selectScan', scanId, 'howdy')
    },
    unselectScan(scanId) {
      this.$store.dispatch('viewer/unselectScan', scanId)
    },
    parseOrientation(item) {
      try {
        const results = JSON.parse(item);
        return results;
      } catch {
        return {
          "trajectory_orientation": "unknown",
          "device_orientation": "unknown",
          "device_position": "unknown",
        };
      }
    },
    scanStatusFinal(item: Scan) {
      if (item.artifacts) {
        for (const artifact of item.artifacts) {
          if (artifact.artifactType === "pointcloud") {
            if (artifact.status === "final") return true;
          }
        }
      }
      return false;
    },
    scanImageList(scan: Scan) {
      const images = [];
      if (scan.artifacts.find((x) => x.artifactType === "left_cam"))
        images.push("left");
      if (scan.artifacts.find((x) => x.artifactType === "center_cam"))
        images.push("center");
      if (scan.artifacts.find((x) => x.artifactType === "right_cam"))
        images.push("right");

      return images.join(", ");
    },
    scanHasPointCloud(scan: Scan) {
      for (const artifact of scan.artifacts) {
        if (artifact.artifactType.includes("pointcloud")) {
          return true;
        }
      }
      return false;
    },
    scanHasImages(scan: Scan) {
      for (const artifact of scan.artifacts) {
        if (artifact.artifactType.includes("cam")) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    selectedScanIdList() {
      this.isAllSelected()
    },
  },
};
