
import { mapGetters } from 'vuex';
import ActivityLogList from '../components/activity-log-list.vue';
import { ACTIVITY_LOG_TYPES } from '../graphql/activity-log-queries';
import { GET_USERS, GET_USER_BY_ORG_ID } from '../graphql/org-user-queries';
import { ActivityLogStatusEnum } from '../services/activity-logger';

export default {
  name: 'admin-activity-logs',
  components: {
    ActivityLogList,
  },
  data() {
    return {
      activityTypeFilter: '',
      statusFilter: '',
      statusFilterItems: [ActivityLogStatusEnum.SUBMITTED, ActivityLogStatusEnum.STARTED, ActivityLogStatusEnum.COMPLETED, ActivityLogStatusEnum.ERROR, ActivityLogStatusEnum.EVENT, ActivityLogStatusEnum.FAILED],
      userIdFilter: 0,
      loadingQueriesCount: 0 as number,
    };
  },
  apollo: {
    orgUsers: {
      query() { return this.user.userType === 'Admin' ? GET_USERS : GET_USER_BY_ORG_ID; },
      loadingKey: "loadingQueriesCount",
      variables(){
        return this.user.userType === 'Admin' ? null : {orgId: Number(this.user.organization.id)}; 
      },
    },
    activityLogTypes: {
      query: ACTIVITY_LOG_TYPES,
      loadingKey: "loadingQueriesCount",
    },
  },
  computed: {
    ...mapGetters(["user"]),
    loading() {
        return (this.loadingQueriesCount > 0)
    },
  },
}
