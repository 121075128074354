
import {
  GET_SESSION_SUMMARY_FOR_PROJECT,
  SUBMIT_TRAJECTORY_PROCESSING_RUN,
} from "../graphql/trajectory-query";
import { mapGetters } from "vuex";
import { generalUtilMixin } from "../mixins/general-util-mixin";
import TrajectoryProcessingRunsList from "../components/trajectory-processing-runs-list.vue";
import {
  GET_MATCHED_BASESTATIONS_FOR_SESSION,
  GET_POTENTIAL_BASESTATIONS_FOR_SESSION,
} from "../graphql/base-station-queries";

export default {
  name: "admin-trajectory-processing",
  mixins: [generalUtilMixin],
  components: {
    TrajectoryProcessingRunsList,
  },
  data() {
    return {
      search: "",
      selectedSession: null,
      selected: [],
      infoDialog: false,
      headers: [
        {
          text: "Session",
          value: "sessionId",
          sortable: false,
          groupable: false,
        },
        {
          text: "From (UTC)",
          value: "capturedTimestamp",
          sortable: true,
          groupable: false,
        },
        {
          text: "To (UTC)",
          value: "endCapturedTimestamp",
          sortable: true,
          groupable: false,
        },
        {
          text: "Total Scans",
          value: "scanCount",
          sortable: true,
          groupable: false,
          width: "150px",
        },
        {
          text: "Scan's Status",
          value: "status",
          sortable: false,
          groupable: false,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          groupable: false,
        },
        {
          text: "Processing Runs",
          value: "data-table-expand",
          sortable: false,
          groupable: false,
        },
      ],
      subheaders: [
        {
          text: "Basestation",
          value: "basestation.id",
          sortable: false,
          groupable: false,
        },
        {
          text: "Recommended",
          value: "selected",
          sortable: false,
          groupable: false,
        },
        {
          text: "Basestation Period (UTC)",
          value: "basestation.startTimestamp",
          sortable: false,
          groupable: false,
        },
        {
          text: "Session Period (UTC)",
          value: "session.capturedTimestamp",
          sortable: false,
          groupable: false,
        },
        {
          text: "Basestation Point",
          value: "basestation.point",
          sortable: false,
          groupable: false,
        },
        {
          text: "Session Points",
          value: "session.startPoint",
          sortable: false,
          groupable: false,
        },
        {
          text: "Longest Distance",
          value: "distance",
          sortable: false,
          groupable: false,
        },
      ],
    };
  },
  mounted() {
    if (this.$route.params && "search" in this.$route.params)
      this.search = this.$route.params.search;
  },
  apollo: {
    sessionSummary: {
      query: GET_SESSION_SUMMARY_FOR_PROJECT,
      variables() {
        return {
          projectId: Number(this.projectId),
        };
      },
      //skip: () => {return this === null },
      loadingKey: "loadingQueriesCount",
    },
    matchedBasestations: {
      query: GET_MATCHED_BASESTATIONS_FOR_SESSION,
      variables() {
        return {
          sessionId: this.selectedSession,
        };
      },
      skip() {
        return !this.selectedSession;
      },
    },
    potentialBasestations: {
      query: GET_POTENTIAL_BASESTATIONS_FOR_SESSION,
      variables() {
        return {
          sessionId: this.selectedSession,
        };
      },
      skip() {
        return !this.selectedSession;
      },
    },
  },
  computed: {
    ...mapGetters("viewer", ["projectId", "summaryScanList"]),
  },
  methods: {
    closeInfoDialog() {
      this.selectedSession = null;
      this.selected = [];
      this.infoDialog = false;
    },
    submit() {
      const basestationIds = this.selected
        .map((x) => Number(x.basestation.id))
      this.$apollo
        .mutate({
          mutation: SUBMIT_TRAJECTORY_PROCESSING_RUN,
          variables: {
            trajectoryProcessingRun: {
              sessionId: this.selectedSession,
              basestationIds: basestationIds,
            },
          },
        })
        .then(() => {
          this.$snackbar.showMessage(`Submitted ${this.selectedSession} for processing with basestations: ${basestationIds}`, `green`);
        })
        .catch((error) => {
          console.log(error);
          this.$snackbar.showMessage(error.message, "error");
        })
        .finally(() => {
          this.closeInfoDialog();
        });
    },
    basestationSelected(id) {
      if (this.matchedBasestations)
        return this.matchedBasestations.map((x) => x.id).includes(id);
      else return [];
    },
    openInfoDialog(item) {
      this.selectedSession = item.sessionId;
      this.infoDialog = true;
    },
    getStatusCount(runs, status) {
      return runs.filter((x) => x.status === status).length;
    },
  },
  watch: {},
};
