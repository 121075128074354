import gql from "graphql-tag";

export const GET_TRAJECTORYPROCESSINGRUNFORSESSION = gql`
query TrajectoryProcessingRunsForSession($sessionId: String!) {
    trajectoryProcessingRunsForSession(sessionId: $sessionId) {
      sessionId
      basestationIds
      status
      statusMessge
      logS3Url
      ambStatusS3Url
      altitudeSeparationsS3Url
      distanceSeparationsS3Url
      numSatsS3Url
      trajectoryAuditS3Url
      submitted
      started
      completed
      id
    }
  }
  `;

export const GET_SESSION_SUMMARY_FOR_PROJECT = gql`
  query SessionsForProject($projectId: Float!) {
    sessionSummary: sessionsForProject(projectId: $projectId) {
      projectId
      sessionId
      capturedTimestamp
      endCapturedTimestamp
      status
      scanCount
      trajectoryProcessingRuns {
        sessionId
        id
        status
        basestationIds
        logS3Url
        ambStatusS3Url
        altitudeSeparationsS3Url
        distanceSeparationsS3Url
        numSatsS3Url
        submitted
        trajectoryAuditS3Url
        started
        completed
      }
    }
  }
  `;


export const GET_SESSIONS_LIST_FOR_PROJECT = gql`
query SessionsForProject($projectId: Float!) {
  sessionSummary: sessionsForProject(projectId: $projectId) {
    projectId
    sessionId
    assignmentType
    capturedTimestamp
    endCapturedTimestamp
  }
}
`;

export const GET_SESSIONS_LIST_FOR_PROJECT_LIST = gql`
query SessionsForProjects($projectIds: String!) {

  sessionSummary: sessionsForProjects(projectIds: $projectIds) {
    projectId
    sessionId
    assignmentType
    capturedTimestamp
    endCapturedTimestamp
  }
}
`;

  export const SUBMIT_TRAJECTORY_PROCESSING_RUN = gql`
  mutation CreateSessionTrajectoryProcessingRun($trajectoryProcessingRun: CreateTrajectoryProcessingRunInput!) {
    createSessionTrajectoryProcessingRun(trajectoryProcessingRun: $trajectoryProcessingRun) {
      id
    }
  }
  `;