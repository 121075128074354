
import ScanFilter from "./scan-filter.vue";
import {
  GET_SCAN_ARTIFACT_LAS_DOWNLOADS,
  UPDATE_SCAN_HEADER,
} from "../graphql/scan-queries"; // TODO: move to store?? consider.
import { mapGetters } from "vuex";
import { ScanSummary } from "../models/models";
import { generalUtilMixin } from "../mixins/general-util-mixin";
import { UPDATE_ANNOTATION, DELETE_ANNOTATION } from "../graphql/annotation-queries";

export default {
  name: "scanList",
  components: {
    ScanFilter,
  },
  mixins: [generalUtilMixin],
  data: () => ({
    maxSelected: 40,
    selectedTab: 0,
    width: 650,
    dialog: false,
    focusedItem: {},
    showAll: false,
  }),
  apollo: {
    getMostRecentArtifactForDownload: {
      query: GET_SCAN_ARTIFACT_LAS_DOWNLOADS,
      loadingKey: "loadingQueriesCount",
      skip() {
        return !this.focusedItem?.id || !this.canProcess
      },
      variables() {
        return {
          getMostRecentArtifactForDownloadId: this?.focusedItem?.id,
        };
      },
    },
  },
  computed: {
    ...mapGetters(["canProcess"]),
    ...mapGetters(["isLoading"]),
    ...mapGetters("viewer", [
      "isAnnotationSelected",
      "selectedAnnotationsList",
      "isFocused",
      "summaryScanList",
      "filterSelected",
      "projectLayerList",
      "selectedScanIdList",
      "loadedScanList",
      "filteredScanIdList",
      "selectedProjectLayerIdList",
      "visibleScanIdList",
      "isScanSelected",
      "project",
      "defaultScanTags",
      "someScanSelected",
      "isScanLoaded",
      "isScanVisible",
      "filteredSummaryScanList",
      "isProjectLayerSelected",
      "projectId",
      "focusedScanId",
      "filteredAnnotationsList",
      "annotations",
      "annotationsByProjectList",
      "focusToAnnotation",
      "focusedAnnotation",
    ]),
    tagOptions() {
      // project could be null if unassigned scans is selected
      return this.project
        ? this.defaultScanTags.concat(this.project.tagOptions)
        : this.defaultScanTags;
    },
    direction() {
      return this.navigation.shown === false ? "Open" : "Closed";
    },
    annotationsByScanList() {
      const annotationsByScanList = [];
      for (const scans of this.visibleScanIdList) {
        for (const annotation of this.annotations) {
          if (annotation.scan.id === scans) {
            annotationsByScanList.push(annotation);
          }
        }
      }
      return annotationsByScanList;
    },
  },
  mounted() {
    this.$store.dispatch("viewer/resetSelectedAnnotationsList");
  },
  methods: {
    selectAllAnnotations() {
      for (const annotation of this.annotationsByScanList) {
        this.$store.dispatch("viewer/selectAnnotation", annotation);
      }
      this.showAll = true;
    },
    unselectAllAnnotations() {
      this.$store.dispatch("viewer/resetSelectedAnnotationsList");
      this.showAll = false;
    },
    zoomToAnnotation(annotation) {
      this.$store.dispatch("viewer/setFocusedAnnotation", annotation);
      this.$store.dispatch("viewer/setFocusToAnnotation", true);
      this.$store.dispatch("viewer/selectAnnotation", annotation);
    },
    isScanFocused(id) {
      return id === this.focusedScanId;
    },
    selectAnnotation(annotation) {
      this.$store.dispatch("viewer/selectAnnotation", annotation);
    },
    unselectAnnotation(annotation) {
      this.$store.dispatch("viewer/unselectAnnotation", annotation);
    },
    editAnnotation(annotation) {
      if (annotation.comment === null || annotation.title === null) {
        return null;
      }
      this.$apollo.mutate({
        mutation: UPDATE_ANNOTATION,
        variables: {
          id: annotation.id,
          scanId: annotation.scan.id,
          comment: annotation.comment,
          title: annotation.title,
          type: annotation.type,
          point: {
            type: annotation.point.type,
            coordinates: annotation.point.coordinates,
          },
        },
      });
    },
    deleteAnnotation(annotation) {
      this.$apollo
        .mutate({
          mutation: DELETE_ANNOTATION,
          variables: {
            id: Number(annotation.id),
          },
        })
        .then(() => {
          const chosen = this.annotations.find((x) => x.id === annotation.id);
          const index = this.annotations.indexOf(annotation);
          this.annotations.splice(index, 1);
          this.$store.dispatch("viewer/unselectAnnotation", chosen);
        });
    },
    /*setBorderWidth() {
      const i = this.$refs.drawer.$el.querySelector(
        ".v-navigation-drawer__border"
      );
      i.style.width = this.navigation.borderSize + "px";
      i.style.cursor = "ew-resize";
    },
    setEvents() {
      const minSize = this.navigation.borderSize;
      const el = this.$refs.drawer.$el;
      const drawerBorder = el.querySelector(".v-navigation-drawer__border");
      const direction = el.classList.contains("v-navigation-drawer--right")
        ? "right"
        : "left";

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        const f =
          direction === "right"
            ? document.body.scrollWidth - e.clientX
            : e.clientX;
        if (580 < f && f < 800) {
            el.style.width = f + "px";
            setTimeout(() => window.dispatchEvent(new Event('resize')), 1);
        }
      }

      drawerBorder.addEventListener(
        "mousedown",
        (e) => {
          if (e.offsetX < minSize) {
            el.style.transition = "initial";
            document.addEventListener("mousemove", resize, false);
          }
        },
        false
      );

      document.addEventListener(
        "mouseup",
        () => {
          el.style.transition = "";
          this.navigation.width = el.style.width;
          document.body.style.cursor = "";
          document.removeEventListener("mousemove", resize, false);
        },
        false
      );
    }, */
    openDialog(item) {
      this.focusedItem = item;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    getFriendlyLocationName(item) {
      if (item.friendlyLocationName) return `near ${item.friendlyLocationName}`;
      return "";
    },
    parseOrientation(item) {
      try {
        const results = JSON.parse(item);
        return results;
      } catch {
        return {
          trajectory_orientation: "unknown",
          device_orientation: "unknown",
          device_position: "unknown",
        };
      }
    },
    importedScan(item: ScanSummary) {
      return item.assignmentType === "Imported";
    },
    scanStatusFinal(item: ScanSummary) {
      if (item.artifacts) {
        for (const artifact of item.artifacts) {
          if (artifact.artifactType === "pointcloud") {
            if (artifact.status === "final") return true;
          }
        }
      }
      return false;
    },
    scanImageList(scan: ScanSummary) {
      const images = [];
      if (scan.artifacts.find((x) => x.artifactType === "left_cam")) images.push("left");
      if (scan.artifacts.find((x) => x.artifactType === "center_cam"))
        images.push("center");
      if (scan.artifacts.find((x) => x.artifactType === "right_cam"))
        images.push("right");

      return images.join(", ");
    },
    scanHasPointCloud(scan: ScanSummary) {
      for (const artifact of scan.artifacts) {
        if (artifact.artifactType.includes("pointcloud")) {
          return true;
        }
      }
      return false;
    },
    scanHasAnnotations(scan: ScanSummary) {
      const annotations = this.filteredAnnotationsList.filter((x) => x === scan.id);
      if (annotations.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    scanHasImages(scan: ScanSummary) {
      for (const artifact of scan.artifacts) {
        if (artifact.artifactType.includes("cam")) {
          return true;
        }
      }
      return false;
    },
    // TODO: fix this, move to view store and ensure the dataset is updated
    updateScan(event, scan: ScanSummary) {
      this.$apollo.mutate({
        mutation: UPDATE_SCAN_HEADER,
        variables: {
          id: scan.id,
          comment: scan.comment,
          tags: scan.tags,
          metadata: scan.metadata,
        },
      });
    },
    selectScan(scanId) {
      this.$store.dispatch("viewer/selectScan", scanId);
    },
    unselectScan(scanId) {
      this.$store.dispatch("viewer/unselectScan", scanId);
    },
    selectProjectLayer(projectLayerId) {
      this.$store.dispatch("viewer/selectProjectLayer", projectLayerId);
    },
    unselectProjectLayer(projectLayerId) {
      this.$store.dispatch("viewer/unselectProjectLayer", projectLayerId);
    },
    showScan(scanId) {
      this.$store.dispatch("viewer/showScan", scanId);
    },
    hideScan(scanId) {
      this.$store.dispatch("viewer/hideScan", scanId);
    },
    focusScan(scanId) {
      this.$store.dispatch("viewer/focusScanId", scanId);
    },
    unselectAll() {
      this.$store.dispatch("viewer/unselectAllScans");
    },
    selectAll() {
      this.$store.dispatch("viewer/selectManyScans", this.filteredScanIdList);
    },
  },
  watch: {
    projectId: function () {
      this.selectedTab = 0;
    },
    selectedTab() {
      this.showAll = false;
    },
    focusedItem() {
      this.$apollo.queries.getMostRecentArtifactForDownload.refresh();
    },
  },
};
