
import { mapGetters } from 'vuex'
import { GET_USERS, ADD_USER, UPDATE_USER, DELETE_USER, GET_USER_BY_ORG_ID } from '../graphql/org-user-queries'
import { GET_ORGANIZATION_SUMMARY } from '../graphql/organization-queries'

export default {
  name: 'admin-users',
  data() {
    return {
      search: '',
      headers: [
          { text: 'Id', value: 'id', groupable: false,},
          { text: 'Name', value: 'name', groupable: false, },
          { text: 'Organization', value: 'organization.name' },
          { text: 'User Type', value: 'userType', },
          { text: 'Username', value: 'username', groupable: false, },
          { text: 'Email', value: 'email', groupable: false, },
          { text: 'Phone', value: 'phone', groupable: false, sortable: false,},
          { text: 'Enabled', value: 'enabled', },
          { text: 'Actions', value: 'actions', sortable: false, groupable: false,  },
        ],
      loadingQueriesCount: 0,

      dialogCreate: false,
      dialogEdit: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        name: '',
        awsUserId: '',
        email: '',
        phone: '',
        organization: null,
        userType: '', 
        enabled: true
      },
      defaultItem: {
        name: '',
        awsUserId: '',
        email: '',
        phone: '',
        organization: null,
        userType: '', 
        enabled: true
      },
      isCreateValid: false,
      rules: {
        required: v => !!v || 'Value is required',
        emailValid: v => {
          // regex from emailregex.com on Jul 9, 2021
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !v || pattern.test(v) || 'E-mail is not valid';
        },
        phoneValid: v => {
          const pattern = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
          return !v || pattern.test(v) || 'Phone number is not valid';
        },
      },
    }
  },
  apollo: {
    orgUsers: {
      query() { return this.user.userType === 'Admin' ? GET_USERS : GET_USER_BY_ORG_ID; },
      loadingKey: "loadingQueriesCount",
      variables(){
        return this.user.userType === 'Admin' ? null : {orgId: Number(this.user.organization.id)}; 
      },
    },
    organizations: {
      query: GET_ORGANIZATION_SUMMARY,
      loadingKey: "loadingQueriesCount",
    }
  },
  computed: {
      ...mapGetters(["user"]),
      loading() {
          return (this.loadingQueriesCount > 0)
      },
      formTitle () {
        return this.editedIndex === -1 ? 'New User' : 'Edit User'
      },
      isEditing() {
          return this.editedIndex !== -1
      },
      adminOk() {
          return this.editedItem?.organization?.name?.startsWith('Lux');
      },
      userTypes() {
          if(this.adminOk) return ['Admin', 'Standard'];
          return ['Standard', 'Power User'];
      }
    },
  methods: {
      setUsername (edittedItem) {
          if(edittedItem.name) {
            const names = edittedItem.name.split(' ');
            if (names.length >= 2 && names[1] !== '')
                edittedItem.username = `${names[0].substring(0,1)}${names[1]}`.toLowerCase();
            else
                edittedItem.username = names[0].toLowerCase();
          }
          edittedItem.username = edittedItem.username.replace(/[^a-zA-Z0-9]/g, '')
      },
      editItem (item) {
        this.editedIndex = this.orgUsers.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogEdit = true
      },
      deleteItem (item) {
        this.editedIndex = this.orgUsers.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.$apollo.mutate({
          mutation: DELETE_USER,
          variables: {
            id: Number(this.editedItem.id),
          },
        }).then(() => {
          this.orgUsers.splice(this.editedIndex, 1)
          this.closeDelete();
        }).catch((error) => {
          console.log(error)
          this.$snackbar.showMessage(error.message, 'error');
        }).finally(() => {
          //pass
        });
      },
      closeCreate () {
        this.$refs.createForm.reset(); 
        this.dialogCreate = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        });
      },
      closeEdit () {
        this.dialogEdit = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        });
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        });
      },
      commitCreate () {
        //console.log(`creating new user: ${JSON.stringify(this.editedItem)}...`)
        let user = this.editedItem;

        this.$apollo.mutate({
          mutation: ADD_USER,
          variables: {
            name: user.name,
            email: user.email,
            phone: user.phone,
            username: user.username,
            organizationId: Number(user.organization.id),
          },
        }).then((data) => {
          user = {...user, ...data.data.createOrgUser}
          this.orgUsers.push(user)
          this.closeCreate();
        }).catch((error) => {
          console.log(error)
          this.$snackbar.showMessage(error.message, 'error');
        }).finally(() => {
          //pass
        });
      },
      commitEdit () {
        //console.log('editting user');
        let user = this.editedItem;

        this.$apollo.mutate({
          mutation: UPDATE_USER,
          variables: {
            id: Number(user.id),
            name: user.name,
            enabled: user.enabled,
            organizationId: Number(user.organization.id),
            userType: user.userType,
            email: user.email,
            phone: user.phone,
          },
        }).then((data) => {
          user = {...user, ...data.data.updateOrgUser}
          Object.assign(this.orgUsers[this.editedIndex], user)
          this.closeEdit();
        }).catch((error) => {
          console.log(error);
          this.$snackbar.showMessage(error.message, 'error');
        }).finally(() => {
          //pass
        });
      },
    },
  watch: {
      dialogCreate (val) {
        val || this.closeCreate()
      },
      dialogEdit (val) {
        val || this.closeEdit()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
}
