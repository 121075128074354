import { FileUploadWorkerInfo } from "./file-upload-worker-info-model";
import { apolloClient } from '../services/vue-apollo';
import { GET_PACKAGE_UPLOAD_MULTIPART_INFO, COMPLETE_PACKAGE_UPLOAD_MULTIPART } from '../graphql/package-upload-queries';

const WORKER_POOL_SIZE = 3;
const TARGET_PART_SIZE = 10 * 1024 * 1024; // 10MB
const MAX_RETRIES = 2 * WORKER_POOL_SIZE + 1; // multiple of pool size because network issues will happen for all parts simultaneously
const RETRY_INTERVAL = 5000; // 5s

class PartWorkerInfo {
    worker: Worker;
    isFree: boolean;
    currentPartNumber: number;
    progress: number;
    onPartProgress: (message: string) => void;
    onPartComplete: (eTag: string) => void;
    onPartError: (error) => void;

    constructor() {
        this.worker = new Worker('worker-file-multipart-upload.js');
        this.isFree = true;
        this.currentPartNumber = 0;
        this.progress = 0;

        this.worker.onmessage = function(e) {
            switch (e.data.type) {
                case 'progress':
                    this.progress = e.data.progress;
                    if (this.onPartProgress) { this.onPartProgress(e.data.progress); }
                    break;
                case 'complete':
                    this.progress = 100;
                    if (this.onPartComplete) { this.onPartComplete(e.data.etag); }
                    break;
                case 'error':
                    if (this.onPartError) { this.onPartError(e.data); }
                    break;
            }
        }.bind(this);
        this.worker.onerror = function(e) {
            if (this.onPartError) { this.onPartError(e); }
        }.bind(this);
    }
    assignToPart(partNumber: number) {
        this.isFree = false;
        this.currentPartNumber = partNumber;
        this.progress = 0;
    }
    freeWorker() {
        this.isFree = true;
        this.currentPartNumber = 0;
        this.progress = 0;
    }
}

export class FileMultipartUploadWorkerInfo extends FileUploadWorkerInfo {
    errorCount: number;
    retryTimeouts: NodeJS.Timeout[];

    uploadId: string;
    numberOfParts: number;
    nextPartNumber: number;
    partInfoList: { eTag: string; partNumber: number; signedUrl: string }[];
    partWorkerPool: PartWorkerInfo[];

    constructor(file: any, url?: string) {
        super(file, url);

        this.errorCount = 0;
        this.retryTimeouts = [];
        this.numberOfParts = Math.ceil(this.file.size / TARGET_PART_SIZE);
        this.nextPartNumber = 1;
        this.partWorkerPool = new Array<PartWorkerInfo>(WORKER_POOL_SIZE);
        for (let i = 0; i < WORKER_POOL_SIZE; i++) {
            const workerInfo = new PartWorkerInfo();
            this.partWorkerPool[i] = workerInfo;
            workerInfo.onPartProgress = function() { this.calculateOverallProgress(); }.bind(this);
            workerInfo.onPartComplete = function(eTag) {
                const partNumber = workerInfo.currentPartNumber;
                console.log(`Completed part ${partNumber} / ${this.numberOfParts} of '${this.name}'.`);
                this.partInfoList[partNumber - 1].eTag = eTag;
                workerInfo.freeWorker();
                this.calculateOverallProgress();
                this.startNextPartUpload();
            }.bind(this);
            workerInfo.onPartError = function(e) {
                console.log(`Error uploading part ${workerInfo.currentPartNumber} of '${this.name}'`);
                this.handleError(e, () => {
                    console.log(`Retrying part upload ${workerInfo.currentPartNumber} of file '${this.name}'...`);
                    this.startPartUploadOnWorker(workerInfo.currentPartNumber, workerInfo);
                });
            }.bind(this);
        }
    }

    completeMultipartUpload(eTags) {
        this.progressMonitor.setComplete();
        this.state.setComplete();

        apolloClient.mutate({
            mutation: COMPLETE_PACKAGE_UPLOAD_MULTIPART,
            variables: { filename: this.name, uploadId: this.uploadId, eTags: eTags.join(',') },
        })
        .then((res) => { if (this.onComplete) { this.onComplete(); } })
        .catch((ex) => { if (this.onError) { this.onError(ex); } });
    }

    calculateOverallProgress() {
        if (this.state.isComplete) {
            return;
        }

        const fullyCompleteParts = this.partInfoList.filter(partInfo => partInfo.eTag).length;
        if (fullyCompleteParts === this.numberOfParts) {
            console.log(`Finished uploading all ${this.numberOfParts} parts for '${this.name}'.`);
            this.completeMultipartUpload(this.partInfoList.map(p => p.eTag));
            return;
        }

        const maxProgressPerPart = 100 / this.numberOfParts;
        let overallProgress = fullyCompleteParts * maxProgressPerPart; 
        for (let i = 0; i < WORKER_POOL_SIZE; i++) {
            if (!this.partWorkerPool[i].isFree) {
                overallProgress += (this.partWorkerPool[i].progress / 100) * maxProgressPerPart;
            }
        }
        this.progressMonitor.setProgress(overallProgress);
        if (this.progressMonitor.progress < 100 && this.onProgress) {
            this.onProgress(this.progressMonitor.progress.toFixed(2));
        }
    }
    getRetryTimeoutTime() {
        // e.g. could evaluate the number of previous timeouts and make it progressively longer
        return RETRY_INTERVAL;
    }
    handleError(error, retryFunction: () => any | undefined) {
        this.errorCount += 1;
        if (retryFunction && (this.errorCount <= MAX_RETRIES)) {
            const retryTimeoutTime = this.getRetryTimeoutTime();
            console.log(`[WARNING] '${this.name}' ${MAX_RETRIES - this.errorCount + 1} retries remaining`);
            const retryTimeout = setTimeout(() => retryFunction(), retryTimeoutTime);
            this.retryTimeouts.push(retryTimeout);
            return;
        }

        if (this.errorCount > MAX_RETRIES) {
            console.log(`[ERROR] '${this.name}' has no more retries. Stopping upload.`);
        }
        this.retryTimeouts.forEach(t => clearTimeout(t));
        this.progressMonitor.stopProgressMonitoring();
        this.state.setError(error);
        if (this.onError) { this.onError(error); }
    }
    getNextFreePartWorker(partNumber): PartWorkerInfo | undefined {
        for (let i = 0; i < WORKER_POOL_SIZE; i++) {
            if (this.partWorkerPool[i].isFree) {
                this.partWorkerPool[i].assignToPart(partNumber);
                return this.partWorkerPool[i];
            }
        }
        return undefined;
    }

    startPartUploadOnWorker(partNumber: number, workerInfo: PartWorkerInfo) {
        const offset = (partNumber - 1) * TARGET_PART_SIZE;
        const part = this.file.slice(offset, offset + TARGET_PART_SIZE);
        workerInfo.worker.postMessage([part, this.file.type, this.file.name, this.partInfoList[partNumber - 1].signedUrl]);
    }
    startNextPartUpload() {
        // don't start another part if we're in an error state
        if (this.state.hasError) {
            return;
        }

        // find the first part that doesn't yet have an eTag
        while((this.nextPartNumber <= this.numberOfParts) && this.partInfoList[this.nextPartNumber - 1].eTag) {
            this.nextPartNumber += 1;
        }
        // if we can't find a part without an etag, we don't need to start uploading any part 
        if (this.nextPartNumber > this.numberOfParts) {
            return;
        }

        const workerInfo = this.getNextFreePartWorker(this.nextPartNumber);
        if (workerInfo) {
            console.log(`Starting part upload ${this.nextPartNumber} / ${this.numberOfParts} of file '${this.name}'...`);
            this.startPartUploadOnWorker(this.nextPartNumber, workerInfo);
            this.nextPartNumber += 1;
        }
    }

    startMultipartUpload(uploadInfo: { filename: string; uploadId: string; parts: { eTag: string; partNumber: number; signedUrl: string }[] }) {
        this.url = uploadInfo.filename;
        this.uploadId = uploadInfo.uploadId;
        this.partInfoList = uploadInfo.parts;

        console.log(`Breaking '${this.name}' into ${this.numberOfParts} parts...`);
        for (let i = 0; i < Math.min(this.numberOfParts, WORKER_POOL_SIZE); i++) {
            this.startNextPartUpload();
        }

        this.state.setInProgress();
        this.progressMonitor.startProgressMonitoring();
        this.startTime = new Date();
        this.onStarted(`Started uploading ${this.file.name}...`);
        this.calculateOverallProgress();
    }

    startUpload() {
        apolloClient.query({
            query: GET_PACKAGE_UPLOAD_MULTIPART_INFO,
            variables: { filename: this.name, numberOfParts: this.numberOfParts },
            fetchPolicy: 'no-cache',
        })
        .then(res => this.startMultipartUpload(res.data.getPackageUploadMultipartInfo))
        .catch(ex => this.handleError(ex, () => this.startUpload()));
    }
}
